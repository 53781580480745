import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { CommonTokenExpireService } from "../views/shared/confirmation-tokenexpire/common-token-expire.service";
import { CommonService } from "../views/shared/common.service";
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthIntercepter implements HttpInterceptor {
  constructor(private router: Router, private modalService: CommonTokenExpireService, private commonservice: CommonService) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let baseUri = `${environment.WebSiteUrl}`;

    //console.log(localStorage.getItem("access_token"));
    //console.log("baseUri ",baseUri);
    //console.log("base index of ",req.url.indexOf(baseUri));
    if (localStorage.getItem("access_token") != null) {
      ///if (req.url.indexOf("202.164.57.203") > 0 || req.url.indexOf("localhost") > 0 || req.url.indexOf(baseUri) > 0) {
        
        //apply headers
        const clonedReq = req.clone({
          headers: new HttpHeaders({
            'Cache-Control': 'no-cache',//, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '-1',
            'If-Modified-Since': '0',
            'Authorization': 'Bearer ' + localStorage.getItem("access_token")
          })

        });
        return next.handle(clonedReq).pipe(
          tap(
            succ => { },
            err => {
              if (err.status === 401) {
                localStorage.removeItem("access_token");
                localStorage.setItem("sadadss", "asdsadsa");
                this.router.navigateByUrl("/account");
                //if (this.router.url != "/" && this.router.url != "/account") {
                //  console.log("auth", this.router.url)
                //  this.modalService.open("Session Timeout", "Your session is about to expire.");
                //}
                //else { this.router.navigateByUrl("/account"); }
              }
            }
          )
        )
      //}
      //else {
       // this.modalService.close();
       // return next.handle(req.clone());
     // }
    } else {
      //this.modalService.close();
      /*console.log("token is null");*/
      return next.handle(req.clone());
    }

      
  }
}
