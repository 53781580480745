import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import { RideFeedBack, ManageridesService } from '../managerides.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  pageTitle: string = "Ride FeedBack Detail"
  heading: string = 'Manage Sessions';
  @Output() nameEvent = new EventEmitter<string>();
  IsSubmiteOrADD: string = "Save";
  feedbackForm: FormGroup;
  RiderId: any;
  readOnly: boolean = true;
  RiderMasterId: any;
  feedbackSubmittedBy: any;
  CreatedOn: string
  CraetedBy: any;
  isDisabled = false;
  firstName: any;
  lastName: any;
  isRider = false;
  isRiderViewProfile: boolean = false;
  FromWeb: boolean = true;
    Painscaleoptions: Options = {
    floor: 0,
      ceil: 10,
      step: 1,
      showTicks: true,
      showTicksValues: true
  };
  BalanceOptins: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    showTicks: true,
    showTicksValues: true
  };
  FlexibilityOptins: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    showTicks: true,
    showTicksValues: true
  };
  RangeOfMotionOptins: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    showTicks: true,
    showTicksValues: true
  };
  StrenghtOptins: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    showTicks: true,
    showTicksValues: true
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private manageridesService: ManageridesService,
    private toaster: ToastrService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.isDisabled = false;
    
    this.initForm();
    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      if (userdetail.userType == "usertyperider") {
        this.heading = "Sessions History";
        this.isRider = true;
      }
    });
    this.route.paramMap.subscribe(
      params => {
        this.RiderId = params.get('RiderId');
        this.RiderMasterId = params.get('RiderMasterId');
        //this.IsSubmiteOrADD = "update";

      });
    if (this.RiderMasterId != null) {
      debugger;
      this.getRiderFeedBackById();
    }
  } 
 
   initForm() {
    this.feedbackForm = this.fb.group({
      Painscale: [0,Validators.pattern(/^[1-9]\d*$/)],
      Balance: [0, Validators.pattern(/^[1-9]\d*$/)],
      Flexibility: [0, Validators.pattern(/^[1-9]\d*$/)],
      RangeOfMotion: [0, Validators.pattern(/^[1-9]\d*$/)],
      Strenght: [0, Validators.pattern(/^[1-9]\d*$/)],
      status: false, 
      CreatedOn: [''],
      CraetedBy: ['']
    });
  }
 
  resetForm(): void {
    this.initForm();
  }

  getRiderFeedBackById() {
    this.manageridesService.getRiderFeedBackDetailById(this.RiderMasterId).subscribe((data: any) => {
      console.log('this.fieldArray', data);
      if (data != null && data != '') {
        this.IsSubmiteOrADD = "Update";
        this.isDisabled = true;
        this.pageTitle = "Ride FeedBack Detail"
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.feedbackForm.patchValue({
          Painscale: data.painscale,
          Balance: data.balance,
          Flexibility: data.flexibility,
          RangeOfMotion: data.rangeOfMotion,
          Strenght: data.strenght,
          status: data.status
          //Painscale: data[0].painscale,
          //Balance: data[0].flexibility,
          //Flexibility: data[0].rangeOfMotion,
          //RangeOfMotion: data[0].painscale,
          //Strenght: data[0].strenght,
          //status: data[0].status         
        })
        console.log("this.feedbackForm", this.feedbackForm.value);
        // onChangeReadOnly(): void {
        this.Painscaleoptions = Object.assign({}, this.Painscaleoptions, { readOnly: this.readOnly });
        this.BalanceOptins = Object.assign({}, this.BalanceOptins, { readOnly: this.readOnly });
        this.FlexibilityOptins = Object.assign({}, this.FlexibilityOptins, { readOnly: this.readOnly });
        this.RangeOfMotionOptins = Object.assign({}, this.RangeOfMotionOptins, { readOnly: this.readOnly });
        this.StrenghtOptins = Object.assign({}, this.StrenghtOptins, { readOnly: this.readOnly });

      }
      else {
        this.IsSubmiteOrADD = "Save";
      }
    })
  }

  save() {
    debugger
    if (this.feedbackForm.valid) {
    this.feedbackForm.value.RiderId = this.RiderId;
    this.feedbackForm.value.RiderMasterId = this.RiderMasterId;
    this.feedbackForm.value.Painscale = this.Painscale.value;
    this.feedbackForm.value.Balance = this.Balance.value;
    this.feedbackForm.value.Flexibility = this.Flexibility.value;
    this.feedbackForm.value.RangeOfMotion = this.RangeOfMotion.value;
    this.feedbackForm.value.Strenght = this.Strenght.value;
    this.feedbackForm.value.status = this.status.value;
    this.feedbackForm.value.CreatedOn =Date.now;
    this.feedbackForm.value.CraetedBy = this.RiderId;    
      this.manageridesService.saveFeedBack(this.feedbackForm.value, this.FromWeb).subscribe((result: any) => {
        if (result.statusCode == 200) {      
         // alert("Feed Back Saved");
          this.toaster.success(result.responseMessage);
          if (this.isRider==true) {
            this.router.navigateByUrl("/ridehistory");
          }
          else
          {
            this.router.navigate(['/rides/ride-info', this.RiderId]);
          }
          
        }
        else {
          this.toaster.warning('Unable to save record.');
        }
      })
    }
    else {
      this.commonService.validateAllFormFields(this.feedbackForm);
    }
  }
  close() {
    this.router.navigate(['/ridehistory']);
  }  
 
  get Painscale() { return this.feedbackForm.get("Painscale"); }

  get Balance() {
    return this.feedbackForm.get("Balance");
  }

  get Flexibility() {
    return this.feedbackForm.get("Flexibility");
  }

  get RangeOfMotion() {
    return this.feedbackForm.get("RangeOfMotion");
  }
  get Strenght() {
    return this.feedbackForm.get("Strenght");
  }

  get status() {
    return this.feedbackForm.get("status");
  }

  PainscaleSetData(event) {
    this.Painscale.setValue(event.target.value);
  }

  BalanceSetData(event) {
    this.Balance.setValue(event.target.value);
  }
  FlexibilitySetData(event) {
    this.Flexibility.setValue(event.target.value);
  }

  RangeOfMotionSetData(event) {
    this.RangeOfMotion.setValue(event.target.value);
  }
  StrenghtSetData(event) {
    this.Strenght.setValue(event.target.value);
  }

  cancel() {
    this.router.navigate(['/rides/ride-info', this.RiderId]);
  }
  cancelRider() {
    this.router.navigate(['/ridehistory']);
  }
}
