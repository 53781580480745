import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ModalModule} from 'ngx-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';

import { AutoCompleteModule } from 'primeng/autocomplete';

import { TruncateWord, SumPipe, CustomNumberPipe } from '../../pipes/truncate.pipe';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NotificationdetailComponent } from './notificationdetail/notificationdetail.component';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { LoaderBlackComponent } from 'src/app/components/loader/loader-black.component';
import { LoaderWhiteComponent } from 'src/app/components/loader/loader-white.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { InputMaskModule } from 'primeng/inputmask';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CommonTokenExpireComponent } from './confirmation-tokenexpire/common-token-expire.component';

import { CKEditorModule } from 'ng2-ckeditor';
import { SafeHtmlPipe } from 'src/app/pipes/pipe.safeHtml';
import { NgxPasswordToggleModule } from 'ngx-password-toggle';
import { UtcToLocal } from '../../pipes/utctolocal.pipe';
import { FooterComponent } from 'src/app/components/footer/footer.component';

@NgModule({
  declarations: [
    UnauthorizedComponent
    , ConfirmationDialogComponent
   
    , CommonTokenExpireComponent
    , TruncateWord,
    NotificationdetailComponent,
    SafeHtmlPipe, SumPipe, CustomNumberPipe
  ,
      LoaderComponent,
      LoaderBlackComponent,
   
    LoaderWhiteComponent,
    UtcToLocal, FooterComponent
   
    ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    NgxDatatableModule,
    FormsModule,
    TooltipModule,
    InputMaskModule,
    NgSelectModule,
    CalendarModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    CKEditorModule,
    NgxPasswordToggleModule,

    DeviceDetectorModule.forRoot()


  ], providers: [
    ConfirmationDialogService,
    DatePipe, SumPipe, CustomNumberPipe
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule, SumPipe, CustomNumberPipe,
    NgxDatatableModule,
    CalendarModule,
    UnauthorizedComponent,

    AutoCompleteModule,

    TruncateWord,
    SafeHtmlPipe,
    TooltipModule,
    InputMaskModule,
    NotificationdetailComponent,
   LoaderComponent,
    LoaderBlackComponent,
    LoaderWhiteComponent,
    ProgressSpinnerModule,
    NgxPasswordToggleModule,
    UtcToLocal, FooterComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    CommonTokenExpireComponent
  ]
})
export class SharedModule { }
