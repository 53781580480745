import { Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import { EmailnotificationService } from './emailnotification.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CommonService, ModuleList } from '../shared/common.service';
import { ConfirmationDialogService } from '../shared/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-viewnotification',
  templateUrl: './viewnotification.component.html',
  styleUrls: ['./viewnotification.component.scss']
})
export class ViewnotificationComponent implements OnInit {
  empDetails: any;
  lstUser: any = {
    pager: {},
    data: []
  };  
  @ViewChild('clearDrop') clearDrop: NgSelectComponent;
  @ViewChild('clearDropRider') clearDropRider: NgSelectComponent;
  @ViewChild('notificationModal') DetailPopup: ModalDirective;
  @ViewChild('Description') notificationModel: ModalDirective;
  modalRef: BsModalRef;
/*  @ViewChild('Popup') Popup: PopupComponent;*/
  loading = false;
  sortDir = 'desc';
  sortColumn = 'CreatedOn';
  modulePermission: ModuleList;
  pagedData: any = {
    pager: {},
    data: []
  };
  listFilter = '';
  idFilter = '';
  emailFilter = '';
  searchTxt = '';
  active: number;
  isEmailTo: any;
  appversion = ''; frmversion = '';
  lstActiveStatus: any;
  EmailFrom: any;
  
  selected = [];
  lstPageSize: any;
  NotificationDetails: any;
  subject: any;
  FromEmail: any = '';
  pageNumber = 0;
  pageSizeValue = 10;
  loginuserid: any;
  isNoData = false;
  matter: any = '';
  activeStatus: any = null;
  conId: any;
  rowdata: any;
  exportExcelTotalRecoards: any;
  usertype: any;
  lstRider: any;
  isdoctor: any;
  Contentdata: any;
  pagedDataForImport: any = {
    pager: {},
    data: []
  };
  totalRecords: any;
  isRead: any;
  isPushNotication: boolean = false;
  isRider: boolean = false;
  hubConnection: signalR.HubConnection;
  ConnectionID: string = null;
  constructor(private Emailservices: EmailnotificationService,
    private dialogService: ConfirmationDialogService,
    private commonService: CommonService,
    private toaster: ToastrService,
    private activeRoute: ActivatedRoute,
    private modalService: BsModalService) {

    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
       if (userdetail.userType == 'usertyperider') {
         this.isRider = true;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  ngOnInit() {
    this.pageSizeValue = 10;
    this.getPageSizes();
    this.GetEmailNotificationList();

    this.prepareSignalR();
  }


  onSort($event) {
    const sort = $event.sorts[0]
    console.log(1);
    this.sortDir = sort.dir;
    this.sortColumn = sort.prop;
    this.loading = true;
    this.pageNumber = $event.page - 1;
    this.refresh();
  }
  updateFilter(event) {
    this.searchTxt = event.target.value;
    let keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode === 13 || keycode === '13') {
      this.refresh();
    }
  }
  refresh() {
    this.GetEmailNotificationList();
  }
  GetEmailNotificationList() {
    this.loading = true;
    this.selected = [];
    this.Emailservices.viewEmailnotificationList(this.sortColumn, this.sortDir, this.pageNumber, this.pageSizeValue, this.emailFilter).subscribe((data: any) => {
      this.lstUser = data;
      this.loading = false;
      this.totalRecords = this.lstUser.data[0].totalRecord;
      console.log("Datalist ", this.lstUser);
    })

  }
  

  reset() {
    this.emailFilter = "";
    this.selected = [];
    this.GetEmailNotificationList();
  }
  setPage($event) {
    this.pageNumber = $event.page - 1;
    this.refresh();
  }
  //CancelButton() {
  //  this.router.navigateByUrl("/emailnotify");
  //}
  getPageSizes() {
    this.commonService.getMasterItemsList("PageSize").subscribe((res: any) => {
      this.lstPageSize = this.commonService.masters;
    })
  }
  isread(row: any) {
    debugger;
    this.loading = true;
    this.Emailservices.setRead(row.id).subscribe((data: any) => {
      debugger;

      if (row.isPushNotication == 0) {
        this.isRead = data;
        this.Contentdata = this.isRead.matter;
        this.matter = row.matter;
        this.isPushNotication = row.isPushNotication;
      }
      else {
        this.isRead = data;
        this.Contentdata = row.matter;
        this.matter = row.matter;
        this.isPushNotication = row.isPushNotication;
      }
      //this.DetailPopup.hide();
      
      this.Emailservices.GetEmailnotificationCount().subscribe((data: any) => {
        this.empDetails = data;
        this.Emailservices.getLatestValue(this.empDetails);
        console.log("coun", data)
      });
    })
    this.loading = false;
  }
  prepareSignalR() {
    this.commonService.hubConnection.on("sendNotificationToClient", (message: string, isShowToaster: boolean) => {

      this.getHeaderNotification(isShowToaster, message);

    });
  }

  connectionId: string;
  totalNotification: number = 0;
  getHeaderNotification(isNew = false, message = null) {
    //this.commonService.getHeaderNotification().subscribe((m: any[]) => {
    //  this.totalNotification = m.length;
    //  this.count = m.length;
    //  this.notificationList = m.slice(0, 5);

    //  if (isNew) {
    //    this.toaster.info(message);
    //  }
    //});
    this.Emailservices.GetEmailnotificationCount().subscribe((data: any) => {
      this.empDetails = data;
      this.Emailservices.getLatestValue(this.empDetails);
      this.GetEmailNotificationList();
    });
  }

  close() {
    this.DetailPopup.hide();
  }
  //onNotification(notification: any) {

  //  this.commonService.setNotificationToRead(notification.UserNotificationId).subscribe(m => {
  //    this.getHeaderNotification();
  //    this.routerService.navigateByUrl('/viewnotification')

  //  });
  //}

  displayDetail(detail, displayTo) {
    debugger
    if (displayTo != 'emailTo') {
      try {
        this.isEmailTo = false;
        this.NotificationDetails = detail;
        this.notificationModel.show();
      }
      catch (err) {
        console.log("error modal popup window", err);
      }
    }
    else {
      this.isread(detail);
      this.isEmailTo = true;
      this.FromEmail = '';
      this.subject = '';
      this.NotificationDetails = '';
      this.EmailFrom = detail.emailfrom + ' ' + detail.emailfromLastName;
      this.subject = detail.subject;
      this.Contentdata = detail.content;
      this.notificationModel.show();

    }

  }
  closeDetails() {
    this.notificationModel.hide();
  }
}
