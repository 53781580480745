import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService, ModuleList } from '../views/shared/common.service';
import { CommonTokenExpireService } from '../views/shared/confirmation-tokenexpire/common-token-expire.service';
import * as signalR from '@aspnet/signalr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  modulePermission: ModuleList;
  modulePermission1: ModuleList;
  constructor(private modalService: CommonTokenExpireService,private router: Router, private commonService: CommonService) {
  
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem("access_token") != null) {
      let moduleCode = next.data.moduleCode;
      // there is check the signal R connection is connected or not, if disconected then recoonect through the app.
      
      if (this.commonService.hubConnection.state!='Connected') {
        if (this.commonService.hubConnection.state == signalR.HubConnectionState.Disconnected) {
          this.commonService.hubConnection.start().catch(m => {
            setTimeout(() => {
              this.commonService.hubConnection.start();
            }, 5000)
          }).then(m => {
            this.commonService.getConnectionID().subscribe((a: any) => {
              if (typeof a != 'undefined' && a != null && a != "") {
                this.commonService.ConnectionID = JSON.parse(a).connnectionId;
                localStorage.setItem('connectionID', JSON.parse(a).connnectionId);
              }
            });
          });
        }
      }
     
     
      if (moduleCode != '' && typeof moduleCode === 'undefined' && moduleCode != null) {
        this.modulePermission = this.commonService.getPermission(moduleCode);
        
        if (typeof this.modulePermission === 'undefined' || this.modulePermission === null) {
          
          this.router.navigate(['/dashboard']);
          return false;
        }
        if (this.modulePermission.roleModuleReadFlag == false) {
          this.router.navigate(['/dashboard']);
          return false;
        }
      }
      else {
        this.modulePermission1 = this.commonService.getPermission(moduleCode);
        let nullval = null;
        //alert(moduleCode);////
      //  alert(this.modulePermission1 == null);
        
        let userType: any;

        this.commonService.getLoggedInName.subscribe((userdetail: any) => {
          userType = userdetail.userType;
        });
        
        if (moduleCode == "0000") { //dashbaord
        }
        else if (moduleCode == "-1") { //change password
        }
        else {
          if (userType == "usertyperider" && moduleCode == 1600) {
          }
          else if ((userType != "usertype01" && userType != "usertypesubadmin" && userType != "usertyperider" && userType != "usertypeclinicadmin" && userType != "usertypeclinicsubadmin") && moduleCode == 2002) {//clinicuser - doctor/nurse/physiotherepist 
          }
          else {
            if (typeof this.modulePermission1 === 'undefined' || this.modulePermission1 == null) {
              this.router.navigateByUrl('/unauthorized');
            }
            else {
            }
          }
        }
      }
      return true;
    }
    else {
      this.router.navigate(['/account']);
      return false;
    }
  }
}
