import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageridesService } from '../managerides.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ride-recipe-listing',
  templateUrl: './ride-recipe-listing.component.html',
  styleUrls: ['./ride-recipe-listing.component.scss']
})
export class RideRecipeListingComponent implements OnInit {
  RideRecipeList: any;
  riderMasterId: any;
  RiderId: any;
  name: any;
  CurrentRiderId: any;
  Dob: any;
  FirstName: string;
  MachineAssignedName: any;
  LastName: string;
  Gender: any;
  RiderAdminId: any;
  isuplloadAndRunReceipe = false;
  clearinter: any;
  isLoadData = false;
  ipAddress: any;
  isComplteRunRide = false;
  Id: any;
  url: SafeResourceUrl;
  sessionURL: any;
  constructor(private route: ActivatedRoute, public sanitizer: DomSanitizer,
    private manageridesService: ManageridesService,
    private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.RiderId = params.get('id');
        this.isLoadData = true;
      }
    );
    this.getRiderRecipeDetail();
    this.getCurrentRiderRecipeDetail();
    this.MachineAssignedName = localStorage.getItem("MachineAssignedName");
    //let current = this;
    //if (this.isLoadData==true) {
    // this.clearinter= setInterval(function () {
    //    current.loadMachinDataWithIn();
    //  }, 5000)  
    //}
    //this.sessionURL = "http://session.allcore360.com/rides/ride-list/" + this.RiderId;
    console.log("session url environment: ", this.manageridesService.getSessionURL());
    this.sessionURL = this.manageridesService.getSessionURL() + "/rides/ride-list/" + this.RiderId;
  }
  getRiderRecipeDetail() {
    this.manageridesService.getRiderRecipeDetail(this.RiderId).subscribe((data: any) => {
      this.RideRecipeList = data;
      data.forEach(childObj => {
        this.riderMasterId = childObj.riderMasterId;
      });

    })
  }
  loadMachinDataWithIn() {
    this.getRiderRecipeDetail();
    this.getCurrentRiderRecipeDetail();
    this.router.navigate(['/rides/save-ride', this.riderMasterId]);
   // this.GetCompleteRideByRideMasterId(this.riderMasterId);
  }

  //GetCompleteRideByRideMasterId(riderMasterId) {
  //  this.manageridesService.GetCompleteRideByRideMasterId(riderMasterId).subscribe((data: any) => {
  //    if (data == true) {
  //      this.isLoadData = false;
  //      clearInterval(this.clearinter);
  //      this.router.navigate(['/rides/save-ride', this.riderMasterId]);
  //    }
  //  });
  //}
  getCurrentRiderRecipeDetail() {
    this.RiderAdminId = localStorage.getItem("RiderAdminid");
    this.manageridesService.getCurrentRiderRecipeDetail(this.RiderAdminId,'1').subscribe((data: any) => {
      //if (data[0].isRunRideStatus == true && data[0].isRunRideStatus != 'undefined') {
      //  this.router.navigate(['/rides/ride-info', this.RiderAdminId]);
      //}
      console.log("App", data);
      data.forEach(childObj => {
        this.name = childObj.name;
        this.FirstName = childObj.firstName;
        this.LastName = childObj.lastName;
        this.CurrentRiderId = childObj.riderId;
        this.Gender = childObj.gender;
        this.Dob = childObj.dob;
        this.Id = childObj.id;
        this.isComplteRunRide = childObj.isRunRideStatus;
        this.manageridesService.GetRiderById(this.Id).subscribe((data1: any) => {
          this.ipAddress = data1.machineIPAddress;

          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`http://${this.ipAddress}/readregisters.htm`);;
          console.log("url", this.url);
        });
      });
      //this.CurrentRideUserDetail = data;
    })
  }
  runReceipeOnMachine() {
   // this.isuplloadAndRunReceipe = true;
   this.loadMachinDataWithIn();
  }
  Finish() {
    this.router.navigate(['/rides/save-ride', this.riderMasterId]);
  }
  cancelForm() {
    this.router.navigate(['/rides/ride-info', this.RiderAdminId]);
  }
}
