import { PipeTransform, Pipe } from "@angular/core";


@Pipe({
  name: 'utctolocal'
})
export class UtcToLocal implements PipeTransform {


  transform(date: any, toUTC:any) {
    date = new Date(date);
    var localOffset = date.getTimezoneOffset() * 60000;
    var localTime = date.getTime();
    if (toUTC) {
      date = localTime + localOffset;
    } else {
      date = localTime - localOffset;
    }
    date = new Date(date);
    return date;
} 


}
