import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserService } from './views/shared/user.service';
import { AuthIntercepter } from './auth/auth.intercepter';
import { LayoutModule } from './layout/layout.module';
import { ToastrModule } from 'ngx-toastr';
import { CommonService } from './views/shared/common.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule, Routes } from '@angular/router';
import { ManagemodulesComponent } from './views/managemodules/managemodules.component';
import { AccordionModule } from 'primeng/accordion';
import { ManageridesModule } from './views/managerides/managerides.module';
import { EmailnotificationModule } from './views/emailnotification/emailnotification.module';
import { BillingreportComponent } from './views/report/billingreport/billingreport.component';
import { RidesessionComponent } from './views/report/ridesession/ridesession.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { InvoicereportComponent } from './views/report/invoicereport/invoicereport.component';



@NgModule({
  declarations: [
    AppComponent,
    ManagemodulesComponent,
   

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    
    LayoutModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxDatatableModule,
    RouterModule,
    FormsModule,
    AccordionModule,
    ManageridesModule,
    NgSelectModule,
    EmailnotificationModule,
  ],
  providers: [UserService,
              CommonService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthIntercepter,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
