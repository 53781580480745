import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoadManageridesComponent } from './views/managerides/load-managerides.component';
import { UnauthorizedComponent } from './views/shared/unauthorized/unauthorized.component';
import { RiderunauthorizedComponent } from './views/rider/riderunauthorized.component';
import { AssociaterideComponent } from './views/pushnotification/associateride.component';




const routes: Routes = [
  {
    path: '',
    redirectTo: '/account',
    pathMatch: 'full'
  },
  { path: 'dashboard', loadChildren: './views/dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard], data: { moduleCode: '0000' } },
 
  
  {
    path: 'user', loadChildren: './views/manageuser/user.module#UserModule', canActivate: [AuthGuard], data: { moduleCode: '2002' },
    pathMatch: "prefix"
  },
  { path: 'emailsettings', loadChildren: './views/emailsettings/emailsettings.module#EmailsettingsModule', canActivate: [AuthGuard], data: { moduleCode: '5210' } },
  
  { path: 'myprofile', loadChildren: './views/userprofile/userprofile.module#UserModule', canActivate: [AuthGuard] },
  { path: 'changepassword', loadChildren: './views/changepassword/changepassword.module#ChangepasswordModule', canActivate: [AuthGuard], data: { moduleCode: '-1' } },
  { path: 'location', loadChildren: './views/location/location.module#LocationModule', canActivate: [AuthGuard], data: { moduleCode: '1001' } },
  {
    path: 'machine',
    loadChildren: './views/machine/machine.module#MachineModule',
    canActivate: [AuthGuard], data: { moduleCode: '1132'}
  },
 
  {
    path: 'account',
    loadChildren: './views/account/account.module#AccountModule',
    data: { preload: true, showHeader: false, showSidebar: false },
    pathMatch:"prefix"
  },
  {
    path: 'common',
    loadChildren: './views/shared/shared.module#SharedModule',
    data: { preload: true, showHeader: false, showSidebar: false },
    pathMatch: "prefix"
  },
  {
    path: 'emailtemplates',
    loadChildren: './views/emailtemplate/emailtemplate.module#EmailtemplateModule',
    canActivate: [AuthGuard], data: { moduleCode: '4021' }
  },

  {
    path: 'announcement',
    loadChildren: './views/announcement/announcement.module#AnnouncementModule',
    canActivate: [AuthGuard], data: { moduleCode: '1021' }
  },
  {
    path: 'leaderboard',
    loadChildren: './views/leaderboard/leaderboard.module#LeaderboardModule',
    canActivate: [AuthGuard], data: { moduleCode: '5310' }
  },
  {
    path: 'master',
    loadChildren: './views/master/master.module#MasterModule'
  },
  {
    path: 'riderreport',
    loadChildren: './views/report/riderreport/riderreport.module#RiderreportModule', data: { moduleCode: '1710' }
  },
  {
    path: 'sessionreportnew',
    loadChildren: './views/report/sessionreportnew/sessionreport.module#SessionreportModule', data: { moduleCode: '1910' }
  },
  {
    path: 'billingreport',
    loadChildren: './views/report/billingreport/billingreport.module#BillingreportModule', data: { moduleCode: '1712' }
  },
  {
    path: 'ridesession',
    loadChildren: './views/report/ridesession/ridesession.module#RidesessionModule', data: { moduleCode: '1713' }
  },
  //rideofflinemode
  {
    path: 'rideofflinemode',
    loadChildren: './views/report/rideofflinemode/sessionreport.module#SessionreportModule', data: { moduleCode: '171345' }
  },
  {
    path: 'invoicereport',
    loadChildren: './views/report/invoicereport/invoicereport.module#InvoicereportModule', data: { moduleCode: '17130' }
  },
  {
    path: 'revenuereport',
    loadChildren: './views/report/revenuereport/revenuereport.module#RevenuereportModule', data: { moduleCode: '1714' }
  },
  {
    path: 'modules',
    loadChildren: './views/managemodules/modules.module#ModulesModule'
  },
  {
    path: 'subscription',
    loadChildren: './views/managesubscription/managesubscription.module#ManagesubscriptionModule', data: { moduleCode: '2020' }
  },
  {
    path: 'social',
    loadChildren: './views/social-media-share/social-media-share.module#SocialMediaShareModule',
    //canActivate: [AuthGuard], data: { moduleCode: '1111' }
  },
  //{
  //  path: 'contact',
  //  loadChildren: './views/contact/contact.module#ContactModule',
  //  canActivate: [AuthGuard],
  //  data: { moduleCode: '1600' }
  //},
   
  {
    path: 'mastername',
    loadChildren: './views/mastername/mastername.module#MasternameModule'
  },




  {
    path: 'role',
    loadChildren: './views/role/role.module#RoleModule',
    canActivate: [AuthGuard], data: { moduleCode: '1122' }
  },
  //{
  //  path: 'machine',
  //  loadChildren: './views/machine/machine.module#MachineModule',
  //  canActivate: [AuthGuard], data: { moduleCode: '1132' }
  //},
  {
    path: 'rider',
    loadChildren: './views/rider/rider.module#RiderModule',
    canActivate: [AuthGuard], data: { moduleCode: '1600' }
  },
  {
    path: 'customer',
    loadChildren: './views/customer/customer.module#CustomerModule',
    canActivate: [AuthGuard], data: { moduleCode: '3210' }
  },

  {
    path: 'rides',
    loadChildren: './views/managerides/managerides.module#ManageridesModule',
    canActivate: [AuthGuard], data: { moduleCode: '1111' }
  },

  //{
  //  path: 'ridehistory',
  //  component: LoadManageridesComponent,
  //  canActivate: [AuthGuard], data: { moduleCode: '1234' }
  //},

 { path: 'unauthorized', component: UnauthorizedComponent },
 {
    path: 'emailnotify',
    loadChildren: './views/emailnotification/emailnotification.module#EmailnotificationModule',
    canActivate: [AuthGuard], data: { moduleCode: '9999' }
  },
  {
    path: 'sign',
    loadChildren: './views/pdfsign/pdfsign.module#PdfsignModule'
    ,canActivate: [AuthGuard],data: { moduleCode: '2002' }
  },
  //publicusersign
  {
    path: 'externalusersign/:riderId/:riderMasterId',
    loadChildren: './views/publicusersign/pdfsign.module#PdfsignModule',
      data: { preload: true, showHeader: false, showSidebar: false },
    pathMatch: "prefix"
  },
  {
    path: 'waiverLiablity',
    loadChildren: './views/liabilitywaiver/liabilitywaiver.module#LiabilityWaiverModule',
    canActivate: [AuthGuard], data: { moduleCode: '1134' }
  },
  { path: 'allClinic', loadChildren: './views/associaterider/pushnotification.module#PushNotificationModule', },
  {
    path: 'pushnotification',
    loadChildren: './views/pushnotification/pushnotification.module#PushNotificationModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'pushTracking',
    loadChildren: './views/pushnotificationtracking/pushnotificationtracking.module#PushNotificationModuleTracking',
    canActivate: [AuthGuard]
  },
  {
    path: 'hubspotticket',
    loadChildren: './views/hubspotticket/hubspotticket.module#HubSpotTicketModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'cliniclocation',
    loadChildren: './views/cliniclocation/cliniclocation.module#ClinicLocationmodule',
    canActivate: [AuthGuard]
  },
  {
    path: 'riderleaderboard',
    loadChildren: './views/riderleaderboard/riderleaderboard.module#RiderLeaderboardModules',
   /* canActivate: [AuthGuard], data: { moduleCode: '5310' }*/
  },
  {
    path: 'waiverform',
    loadChildren: './views/waiverform/waiverform.module#waiverformmodule',
  
  },
 //  { path: 'unauthorized', component: RiderunauthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
