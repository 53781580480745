import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailnotificationlistComponent } from './emailnotificationlist.component';
import { EmailnotificationComponent } from './emailnotification.component';
import { ViewnotificationComponent } from './viewnotification.component';


const routes: Routes = [
  { path: '', component: EmailnotificationlistComponent },
  { path: 'Add', component: EmailnotificationComponent },
  { path: 'Add/:Id', component: EmailnotificationComponent },
  { path: 'viewnotification', component: ViewnotificationComponent },

];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class EmailnotificationRoutingModule { }
