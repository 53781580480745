import { PipeTransform, Pipe } from "@angular/core";


@Pipe({
  name: 'truncate'
})
export class TruncateWord implements PipeTransform {
  transform(value: string, limit = 15, completeWords = false, ellipsis = '...') {
    if (value) {
      if (value.length > limit) {
        if (completeWords) {
          limit = value.substr(0, limit).lastIndexOf(' ');
        }
        return `${value.substr(0, limit)}${ellipsis}`;
      }
      else {
        return `${value}`;
      }
    }
  }
}
@Pipe({ name: 'customNumber' })
export class CustomNumberPipe implements PipeTransform {
  transform(value: number, ...args: any[]) {
    return Math.round(value);
  }
}

@Pipe({
  name: 'sum'
})
export class SumPipe implements PipeTransform {
  
  transform(ninjas: any, term: any): any {
    if (ninjas != 0) {
      return ninjas.reduce(function (a, b) { return a + b });
    }
    else {
      return 0;
    }
  }
}
