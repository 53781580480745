import { Component, OnInit } from '@angular/core';
import { getLocaleDateTimeFormat } from '@angular/common';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {
  currentYear: any;
  constructor() { }

  ngOnInit() {

    this.currentYear = new Date().getFullYear();
  }

}
