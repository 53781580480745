import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { ManageridesService, RideMaster } from '../managerides.service';
import { debug } from 'util';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../shared/common.service';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-add-recipe',
  templateUrl: './add-recipe.component.html',
  styleUrls: ['./add-recipe.component.scss']
})
export class AddRecipeComponent implements OnInit {
  RiderId: any;
  RiderMasterId: any;
  submitted = false;
  PreRideSBP: any;
  PreRideDBP: any;
  RiderRecipeDetailModel: [];
  isPreRideSBP = false;
  isPreRideDBP = false;
  runridebtn: boolean = false;
  isHide = true;
  totalRows: any;
  Spin = false;
  Angle = false;
  Rotation = false;
  RideData: RideMaster;
  CurrentRideUserDetail: any;
  name: any;
  FirstName: string;
  LastName: string;
  isRemove: boolean = false;
  hiddenddbtn: any; 
  CurrentRiderId: any;
isRedirectTosession=false;
  Dob: any;
  Gender: any;
  RideStartTime: any;
  RiderAdminId: any;
  RotIdDDL: any;
  rotationList: any;
  ridemasterId: any;
  ipAddress: any;
  isLast: boolean = false;
  deletebutton: 0;
  IEbrowser: boolean = false;
  MachineAssignedName: any;
  FromWeb: boolean = true;
  LoginId: any;
  AssignedTo: any;
  spinMessage: string = "Spin is required";
  isFlag: boolean = false;
  userType: string = "";
  isWaiverCollected: boolean=false;

  constructor(private route: ActivatedRoute, private toaster: ToastrService,
    private manageridesService: ManageridesService,
    private commonService: CommonService,
    private dialogService: ConfirmationDialogService,
    private router: Router) { }

  ngOnInit() {

    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      console.log('asd', userdetail)
      this.LoginId = userdetail.id;
      this.userType = userdetail.userType;
      
      console.log('appURL:**************************************************' + this.router.url);
      console.log(window.location.href);
    });
    this.runridebtn = false;
    this.route.paramMap.subscribe(
      params => {
        this.RiderId = params.get('RiderId');
        this.ridemasterId = params.get('RiderMasterId');
      }


    );
    this.getCurrentRiderRecipeDetail();
    this.getRiderRecipeDetailById();
    this.deletebutton = 0;
    this.getBrowserName();
  //  this.MachineAssignedName = localStorage.getItem("MachineAssignedName");

  }
  RideMaster = {
    PreRideSBP: '',
    PreRideDBP: '',
  };
  numberOnly(event): boolean {
    this.Angle = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberOnlySpin(event): boolean {
    console.log('evnt', event);
    this.Spin = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  //For Spin Validation
  //isNumberKey(event): boolean {
  //  this.spinMessage = "Spin is required";
  //  this.Spin = false;
  //  var charCode = (event.which) ? event.which : event.keyCode;
  //  var num = event.target.value;

  //  if (charCode > 47 && charCode < 58) {
  //    // check enter first zero
  //    if (num == "" && charCode == 48) {
  //      this.spinMessage = "Spin value should be between 1 - 10";
  //      this.Spin = true;
  //      return false;
  //    }
  //    else if (num.length == 1 && charCode != 48) {
  //      this.spinMessage = "Spin value should be between 1 - 10";
  //      this.Spin = true;
  //      return false;
  //    }
  //  }
  //  else
  //  {
  //    this.spinMessage = "Spin value should be between 1 - 10";
  //    this.Spin = true;
  //    return false;
  //  }
  //}
  getRiderRecipeDetailById() {
    let rotation = [{ 'id': 0, 'name': 'ClockWise' }, { 'id': 1, 'name': 'Counter ClockWise' }];
    this.manageridesService.getRiderRecipeDetailById(this.ridemasterId).subscribe((data: any) => {
      
      this.fieldArray = data;
      console.log('this.fieldArray', this.fieldArray);
      if (this.fieldArray.length > 0) {
        this.newAttribute.length = 0;
        this.isFlag = false;
        if (this.fieldArray.length > 0) {
          this.newAttribute.length = 0;
          //this.fieldArray.length = 0;
          let obj = {
            spin: '',
            angle: '',
            rotationValue: 0,
            rotation: rotation

          }
          debugger
          this.newAttribute.push(obj);

        }
      }
      console.log('this.fieldArray',this.fieldArray);
      if (data.length >= 4) {
        this.newAttribute = [];
        this.isHide = false;
        this.runridebtn = true;
      }

      this.rotationList = [{ 'id': 0, 'name': 'ClockWise' }, { 'id': 1, 'name': 'Counter ClockWise' }];

    })
  }
  getCurrentRiderRecipeDetail() {
    
    this.RiderAdminId = localStorage.getItem("RiderAdminid");
   // this.MachineAssignedName = localStorage.getItem("MachineAssignedName");
    this.manageridesService.getCurrentRiderRecipeDetail(this.RiderAdminId,'1').subscribe((data: any) => {
      console.log('as',data);
      data.forEach(childObj => {
        this.name = childObj.name;
        this.FirstName = childObj.firstName;
        this.LastName = childObj.lastName;
        this.CurrentRiderId = childObj.riderId;
        this.Gender = childObj.gender;
        this.Dob = childObj.dob;
        this.AssignedTo = childObj.assignedTo;
        this.MachineAssignedName = childObj.machineName;
        this.isWaiverCollected = childObj.collectWaiver;
      });
      console.log('as', this.AssignedTo);
      if (this.userType != "usertypeclinicadmin" && this.userType != "usertypeclinicsubadmin" && this.userType != "usertypeoperator") {
        if (this.AssignedTo != this.LoginId) {
          this.router.navigateByUrl('/unauthorized');
        }
      }
    })
    console.log('login', this.LoginId);
    console.log('AssignedTo', this.AssignedTo);

   
    
  }

  dataChangedAddBtn(event, i, type) {
   
    this.Rotation = false;      
    if (this.IEbrowser == true) {
      if (type == 'field') {
        this.fieldArray[i].rotationValue = event.srcElement.value;
        if (event.srcElement.value == 'ClockWise') {

          this.fieldArray[i].rotationId = 0;
        }
        if (event.srcElement.value == "Counter ClockWise") {
          this.fieldArray[i].rotationId = 1;
        }
      }
      else {
        this.newAttribute[0].rotationValue = event.srcElement.value;
        if (event.srcElement.value.trim() ==='ClockWise') {
          this.newAttribute[0].rotationId = 0;
        }
        if (event.srcElement.value.trim() === "Counter ClockWise") {
          this.newAttribute[0].rotationId = 1;
        }
      }
    }
    else {
      if (type == 'field') {
        this.fieldArray[i].rotationValue = event.target.value;
        if (event.target.value == 'ClockWise') {

          this.fieldArray[i].rotationId = 0;
        }
        if (event.currentTarget.value == "Counter ClockWise") {
          this.fieldArray[i].rotationId = 1;
        }
      }
      else {

        this.newAttribute[0].rotationValue = (event.target || event.srcElement).value;
        if (event.target.value == 'ClockWise') {

          this.newAttribute[0].rotationId = 0;
        }
        if ((event.currentTarget || event.srcElement).value == "Counter ClockWise") {
          this.newAttribute[0].rotationId = 1;
        }
      }
    }


  }
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        this.IEbrowser = true;
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  private fieldArray: Array<any> = [];
  private newAttribute: any = [{

    spin: "", Angle: "", rotation: [{ 'id': 0, 'name': 'ClockWise' }, { 'id': 1, 'name': 'Counter ClockWise' }], rotationId: 2, rotationValue: ''
  }];

  addFieldValue(type, trType) {
   
    //alert(this.newAttribute[0].rotationId);
    this.isLast = false;
    if (this.fieldArray.length <= 4) {


      this.isRemove = true;
      if (type == 'addmore' && trType == 'del') {

        this.newAttribute = [{ spin: "", Angle: "", rotation: [{ 'id': 0, 'name': 'ClockWise' }, { 'id': 1, 'name': 'Counter ClockWise' }], rotationId: 2, rotationValue: '' }];
        this.isHide = true;
        return;
      }
      if (this.newAttribute[0].spin == "" || this.newAttribute[0].spin == undefined || this.newAttribute[0].angle == "" || this.newAttribute[0].angle == undefined || this.newAttribute[0].rotationValue == "" || this.newAttribute[0].rotationValue == undefined) {

        this.newAttribute[0].spin == "" || this.newAttribute[0].spin == undefined ? this.Spin = true : this.Spin = false;
        this.newAttribute[0].angle == "" || this.newAttribute[0].angle == undefined ? this.Angle = true : this.Angle = false;
        this.newAttribute[0].rotationValue == "" || this.newAttribute[0].rotationValue == undefined ? this.Rotation = true : this.Rotation = false;
        return;
      }

      var obj = { spin: this.newAttribute[0].spin, angle: this.newAttribute[0].angle, rotation: this.newAttribute[0].rotation, rotationId: this.newAttribute[0].rotationId, rotationValue: this.newAttribute[0].rotationValue }
      this.fieldArray.push(obj);
      this.totalRows = this.fieldArray.length - 1;

      if (type == 'addmore' && this.fieldArray.length <= 4) {

        this.newAttribute = [{ spin: "", angle: "", rotation: [{ 'id': 0, 'name': 'ClockWise' }, { 'id': 1, 'name': 'Counter ClockWise' }], rotationId: 2, rotationValue: '' }];
        this.isHide = true;
      }
      else {

        this.newAttribute = [];
        this.isHide = false;
      }
    }
    else {
      const message = `"you can add maximum 5 rides"`;
      this.dialogService.confirm('', message).subscribe(confirmed => {
      });
      alert(this.newAttribute[0].rotation.name);
    }



  }
  onDegreeSpinChange(event, i, type) {

    if (type == 'spin') {
      this.fieldArray[i].spin = event.target.value;
      //this.Spin = false;
    }
    else {
      this.fieldArray[i].angle = event.target.value;
    }

  }

  deleteRow(d) {
    if (this.fieldArray.length == 0) {
      return;
    }
    else {

      const index = this.newAttribute.indexOf(d);
      this.newAttribute.splice(index, 1);
      this.isLast = true;
    }
  }

  deleteFieldValue(index) {
    if (this.fieldArray.length == 1) {
      
      return;
    }
    else {
      this.fieldArray.splice(index, 1);
      if (index == 4) {

        this.newAttribute = [{ spin: "", Angle: "", rotation: [{ 'id': 0, 'name': 'ClockWise' }, { 'id': 1, 'name': 'Counter ClockWise' }], rotationId: 2, rotationValue: '' }];
        this.isHide = true;
        return;

      }
    }
    
    this.totalRows = this.fieldArray.length - 1;
    
  }
  isValidation() {
    //if (this.RideMaster.PreRideSBP == '' || this.RideMaster.PreRideDBP == '') {

    //  this.RideMaster.PreRideSBP == '' ? this.isPreRideSBP = true : this.isPreRideSBP = false;
    //  this.RideMaster.PreRideDBP == '' ? this.isPreRideDBP = true : this.isPreRideDBP = false;

    //  return false;
    //}

    if (this.fieldArray.length > 0) {
      return true;
    }
   
    else {

   


        if (this.newAttribute[0].spin == "" || this.newAttribute[0].spin == undefined || this.newAttribute[0].angle == "" || this.newAttribute[0].angle == undefined || this.newAttribute[0].rotationValue == "" || this.newAttribute[0].rotationValue == undefined) {

          this.newAttribute[0].spin == "" || this.newAttribute[0].spin == undefined ? this.Spin = true : this.Spin = false;
          this.newAttribute[0].angle == "" || this.newAttribute[0].angle == undefined ? this.Angle = true : this.Angle = false;
          this.newAttribute[0].rotationValue == "" || this.newAttribute[0].rotationValue == undefined ? this.Rotation = true : this.Rotation = false;
          return false;
        }
      
    }
    if (this.fieldArray.length == 0) {

      //const message = `"please add atleast one ride recipe"`;
      //this.dialogService.confirm('', message).subscribe(confirmed => {
      //});
      //---9 july kiran--why showing error msg in confirmation box ??
      this.toaster.error("please add atleast one ride recipe");
      return;
    }
  }
  runride() {

    if (this.isFlag) {
      this.newAttribute = [];
    }
    if (!this.isValidation()) {
      return;
    }
    //Start	validation for last row , have to click "add" for recipe
      try {
        if (this.newAttribute[0] != undefined) {
         
        if ((this.newAttribute[0].spin != "") || (this.newAttribute[0].angle != "") || (this.newAttribute[0].rotationValue != "")) {
          this.toaster.error("Please Fill the recipe and click on add button");

          return false;
        }
      }
      else {
        console.log(' — Error is undefined: ');

      }
    }
    catch(error){
      console.log(' — Error is handled gracefully: ', error.message);
    }
    //End
    let dtArr = [];
    for (let i = 0; i < this.fieldArray.length; i++) {
      var obj = { spin: this.fieldArray[i].spin.toString(), angle: this.fieldArray[i].angle.toString(), rotation: this.fieldArray[i].rotationId.toString() };
      dtArr.push(obj);

    }
    debugger;
    console.log(obj);
    this.RideData = new RideMaster();
    this.RideData.PreRideSBP = this.RideMaster.PreRideSBP,
      this.RideData.PreRideDBP = this.RideMaster.PreRideDBP,
      this.RideData.RiderRecipeDetailModel = dtArr;
    this.RideData.RiderId = this.RiderId;
    this.RideData.RideStartTime = new Date().toISOString();

    const data = {
      PreRideSBP: this.RideMaster.PreRideSBP,
      PreRideDBP: this.RideMaster.PreRideDBP,
      RiderRecipeDetailModel: dtArr,//this.fieldArray

    };

    this.manageridesService.save(this.RideData, this.FromWeb)
      .subscribe(
        response => {

          this.submitted = true;
          // $('#openOnLoad').click();
          //document.getElementById("ancSession").click();
         // window.open("http://localhost:8521/rides/ride-list/D968F042-1B21-4E41-9984-7BCC274822B8",'_blank'); 
          // Start -- Load Recipe Into Machine
          //this.loadRecipeIntoMachine(this.RiderId);
          // End 
          //window.location.href ='http://localhost:8521/rides/ride-list/D968F042-1B21-4E41-9984-7BCC274822B8';
          //this.isRedirectTosession = true;
          this.router.navigate(['/rides/ride-list', this.RiderId]);

        },
        error => {
          console.log(error);
        });
  }

  downloadWaiverDetail() {
    console.log("downloadWaiverDetail", this.RiderId);
    this.manageridesService.ClickToDownload(this.RiderId).subscribe((result: any) => {
      console.log("result blob1", result);
      var filename = "Waiver_" + this.FirstName + '_' + this.LastName + new Date().getHours() + ".pdf";
      var file = new Blob([result], { type: result.type });
      saveAs(file, filename);
    },
      error => {
      });
  }

  // ----------------------------Start -------- Load Recipe Into Machine

  loadRecipeIntoMachine(riderId) {
    this.manageridesService.getRiderRecipeDetail(riderId).subscribe((data: any) => {

      //Rider Detail API 
      this.manageridesService.GetRiderById(this.RiderId).subscribe((data1: any) => {
        this.ipAddress = data1.machineIPAddress;
        //Get the machine IP from Rider Detail (machineIPAddress)

        //Set the IP in machine service

        this.manageridesService.setMachineIp(this.ipAddress)

        let jsondata = [];
        ///added code here for resting the machine
        this.write("R1620", "INT", "0");
        this.write("R160", "INT", "0");
        this.write("R1613", "INT", "0");
        this.write("R1614", "INT", "0");

        this.write("R1109", "INT", "0");
        this.write("R1112", "INT", "0");
        this.write("R1115", "INT", "0");
        this.write("R1118", "INT", "0");
        this.write("R1121", "INT", "0");

        for (var i = 1; i <= 5; i++) {
          let baseNum: number = 107 + (i * 3);
          this.write("R" + baseNum, 'INT', '90');
          baseNum = baseNum + 1;
          this.write("R" + baseNum, 'INT', '0');
          baseNum = baseNum + 1;
          this.write("R" + baseNum, 'INT', '0');
        }
        
        ////

        data.forEach(function (obj, index) {

          let angle = 'R' + (107 + (obj.spinIndex * 3));
          let spin = 'R' + (107 + (obj.spinIndex * 3 + 1));
          let rotation = 'R' + (107 + (obj.spinIndex * 3 + 2));
          let dass = {
            [spin]: obj.spin,
            [angle]: obj.angle,
            [rotation]: obj.rideRotation
          };
          jsondata.push(dass);
        });

       // this.write("R100", "DINT", riderId);
        console.log("data1.riderId", data1.riderId)
        this.write("R100", "DINT", data1.riderId);
        jsondata.forEach(function (obj, index) {
          let cols = Object.getOwnPropertyNames(obj);
          this.write(cols[0], 'INT', obj[cols[0]]);
          this.write(cols[1], 'INT', obj[cols[1]]);
          this.write(cols[2], 'INT', obj[cols[2]]);
        }, this)
      })

    })
  }

  write(register: string, datatype: string, value: string) {
    this.manageridesService.writeOnRegister(register, datatype, value).subscribe();
  }

  // ---------------------End-----------------------------------------


  newRides() {
    this.submitted = false;
    this.RideMaster = {
      PreRideSBP: '',
      PreRideDBP: '',
    };

  }
  cancelForm() {
    this.router.navigate(['/rides/ride-info', this.RiderAdminId]);
  }
  Signwavebutton() {
    this.runridebtn = true;
    this.isRedirectTosession = true;
    this.router.navigate(['/sign/concent', this.RiderId, this.ridemasterId]);
    //this.router.navigate(['sign/concent', { riderId: this.RiderId, riderMasterId: this.ridemasterId }]);
  }
}
