import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageridesComponent } from './managerides.component';
import { ManageridesRoutingModule } from './managerides-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LoadManageridesComponent } from './load-managerides.component';
import { AddRecipeComponent } from './Rides_Recipe/add-recipe.component';
import { RideRecipeListingComponent } from './Rides_Recipe/ride-recipe-listing.component';
import { SaveRideRecipeComponent } from './Rides_Recipe/save-ride-recipe.component';
import { ChartModule } from 'primeng/chart';
import { CalendarModule } from 'primeng/calendar';
import { RideevalutionComponent } from './evalutionrides/rideevalution.component';
import { EveluationbaselineComponent } from './evalutionrides/eveluationbaseline.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule } from '@angular/forms';
import { FeedbackListingComponent } from './feedback-listing/feedback-listing.component';
import { UtcToLocal } from '../../pipes/utctolocal.pipe';
import { ManageNotesComponent } from './managenotes.component';
import { ModalModule } from 'ngx-bootstrap';


@NgModule({
  declarations: [ManageridesComponent, LoadManageridesComponent, AddRecipeComponent,
    RideRecipeListingComponent, SaveRideRecipeComponent, RideevalutionComponent, EveluationbaselineComponent, FeedbackComponent,
    FeedbackListingComponent, ManageNotesComponent],
  imports: [
    CommonModule,
    ManageridesRoutingModule,
    SharedModule,
    CalendarModule,
    ChartModule,
  NgxSliderModule,
    FormsModule, ModalModule.forRoot(),
  ],
  providers: [UtcToLocal]
})
export class ManageridesModule { }
