import { Component, OnInit, Inject} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonService } from './views/shared/common.service';
import { debug } from 'util';
import { SignalRService } from './services/signal-r.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import * as signalR from '@aspnet/signalr';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'AllCore360';
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  public baseUri = `${environment.WebApiBaseUrl}`;
  themeType: any;

  constructor(private router: Router, public signalRService: SignalRService
    , private activatedRoute: ActivatedRoute, private http: HttpClient
    , private commonService: CommonService, @Inject(DOCUMENT) private document: Document) {
    let currentContext = this;
    if (this.commonService.hubConnection.state == signalR.HubConnectionState.Disconnected) {
      this.commonService.hubConnection.start().catch(m => { }).then(m => {
        //console.log('signal r initialize');
        currentContext.commonService.getConnectionID().subscribe((a: any) => {
          //console.log('connection detail: ', a)
          if (typeof a != 'undefined' && a != null && a != "") {
            currentContext.commonService.ConnectionID = JSON.parse(a).connnectionId;
            localStorage.setItem('connectionID', JSON.parse(a).connnectionId);
          }
        });
      });

      this.commonService.hubConnection.onreconnected(function (conntionId: string) {
        currentContext.commonService.getConnectionID().subscribe((a: any) => {
          //console.log('re-connection detail: ', a)
          currentContext.commonService.ConnectionID = JSON.parse(a).connnectionId;
          localStorage.setItem('connectionID', JSON.parse(a).connnectionId);
        });
      });
    }
    //localStorage.removeItem('menuStage');
  }

  ngOnInit() {
    localStorage.removeItem("menuStage");
    this.commonService.getCurrentUserDetail();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
      }
    });
    //this.signalRService.startConnection();
    //this.signalRService.addTransferChartDataListener();

    //if (environment.production) {
    //  if (location.protocol === 'http:') {
    //    if (this.router.url !== 'http://app.allcore360.com/rides/ride-recipe/*') {

    //      window.location.href = location.href.replace('http', 'https');
    //    }
    //  }
    //}

    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    ////this.commonService.getThemeType().subscribe((result: any) => {
    ////   this.themeType = "1"//result;
    ////   localStorage.removeItem('ThemeType');
    ////   localStorage.setItem('ThemeType', this.themeType);
    ////   //alert(localStorage.getItem('ThemeType'));
    ////   this.themeType = localStorage.getItem('ThemeType');
    ////   //this.themeType = 1;
    ////   if (this.themeType == 1) {
    ////     this.loadStyle("login.css");
    ////   }
    ////   else {
    ////     this.loadStyle("login_Green.css");
    ////     //this.loadStyle("style.default_Green.css");
    ////     //this.loadStyle("Custom_Green.css");
    ////   }
    ////})
    ////////this.themeType = 1;
    ////////localStorage.removeItem('ThemeType');
    ////////localStorage.setItem('ThemeType', this.themeType);

     
    this.commonService.getThemeType().subscribe((result: any) => {
      console.log("themeType result", result)
      this.themeType = result;
      localStorage.removeItem('ThemeType');
      localStorage.setItem('ThemeType', this.themeType);
      this.themeType = localStorage.getItem('ThemeType');
      console.log("themeType", this.themeType)

      if (this.themeType == 1) {
        this.loadStyle("login.css", "login");
        this.loadStyle("style.default.css", "styledefault");
        this.loadStyle("custom.css", "custom");
      }
      else {
        this.loadStyle("login_Green.css", "login");
        this.loadStyle("style.default_Green.css", "styledefault");
        this.loadStyle("custom_Green.css", "custom");
      } 
    });

    let currentContext = this;
    if (this.commonService.hubConnection.state == signalR.HubConnectionState.Disconnected) {
      this.commonService.hubConnection.start().catch(m => {
        setTimeout(() => {
          this.commonService.hubConnection.start();
        }, 1000)
      }).then(m => {
        //console.log('signal r initialize');
        currentContext.commonService.getConnectionID().subscribe((a: any) => {
          //console.log('connection detail: ', a)
          if (typeof a != 'undefined' && a != null && a != "") {
            currentContext.commonService.ConnectionID = JSON.parse(a).connnectionId;
            localStorage.setItem('connectionID', JSON.parse(a).connnectionId);
          }
        });
      });

      this.commonService.hubConnection.onreconnected(function (conntionId: string) {
        currentContext.commonService.getConnectionID().subscribe((a: any) => {
          //console.log('re-connection detail: ', a)
          currentContext.commonService.ConnectionID = JSON.parse(a).connnectionId;
          localStorage.setItem('connectionID', JSON.parse(a).connnectionId);
        });
      });
    }
  }

  loadStyle(styleName: string, stypeid: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = stypeid;//'client-theme'; 
    style.rel = 'stylesheet';
    style.href = `${styleName}`;

    head.appendChild(style);


    //let themeLink = this.document.getElementById(
    //  'client-theme'
    //) as HTMLLinkElement;
    //if (themeLink) {
    //  themeLink.href = styleName;
    //} else {
    //  const style = this.document.createElement('link');
    //  style.id = 'client-theme';
    //  style.rel = 'stylesheet';
    //  style.href = `${styleName}`;

    //  head.appendChild(style);
    //} 
  }


  loadStyl111e(styleName: string) {
    debugger;
    //if (styleName == "normal") {
    //  this.document.getElementById('theme').setAttribute('href', "assets/default-theme/css/login.css");
    //  this.document.getElementById('theme1').setAttribute('href', "assets/default-theme/css/style.default.css");
    //  this.document.getElementById('theme2').setAttribute('href', "assets/default-theme/css/Custom.css");
    //}
    //else {
    //  this.document.getElementById('theme').setAttribute('href', "assets/default-theme/css/login_Green.css");
    //  this.document.getElementById('theme1').setAttribute('href', "assets/default-theme/css/style.default_Green.css");
    //  this.document.getElementById('theme2').setAttribute('href', "assets/default-theme/css/Custom_Green.css");
    //}
  } 
   
  loadStyle111(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');

    style.id = 'login'; 
    style.rel = 'stylesheet';
    style.href = `${styleName}`;
    head.appendChild(style);

    //style.id = 'Style';
    //style.rel = 'stylesheet';
    //style.href = `${"style.default_Green.css"}`;
    //head.appendChild(style);

    //style.id = 'Custom';
    //style.rel = 'stylesheet';
    //style.href = `${"Custom_Green.css"}`;
    //head.appendChild(style);

  }
}

