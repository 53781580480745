import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { ConfirmationDialogService } from '../shared/confirmation-dialog/confirmation-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService, ModuleList } from '../shared/common.service';
import { ManageridesService } from './managerides.service';
import { RiderService } from '../rider/rider.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ManageNotesComponent } from './managenotes.component';

@Component({
  selector: 'app-managerides',
  templateUrl: './managerides.component.html',
  styleUrls: ['./managerides.component.scss']
})
export class ManageridesComponent implements OnInit {
  @ViewChild('clearDrop') clearDrop: NgSelectComponent;
  @ViewChild('clearDropRider') clearDropRider: NgSelectComponent;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('managenotes') notesModal: ManageNotesComponent;
 
  searchTxt = '';
  loading = false;
  sortDir = 'desc';
  sortColumn = 'CreatedOn';
  pagedData: any = {
    pager: {},
    data: []
  };
  pagedDataForImport: any = {
    pager: {},
    data: []
  };
  rowsForExport = [];
  pageNumber = 0;
  pageSizeValue: number;
  selected = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lstPageSize: any;
  nameFilter = '';
  idFilter = '';
  phnFilter = '';
  loginuserid: any;
  exportExcelTotalRecoards: any;
  lstActiveStatus: any;
  lstRider: any;
  moduleRiderPermission: ModuleList;
 // moduleLoadRidePermission: ModuleList;
  active: number;
  isOperatorUser: boolean = false;
  constructor(private dialogService: ConfirmationDialogService,
    private activeRoute: ActivatedRoute,
    private toaster: ToastrService,
    private router: Router,
    private commonService: CommonService,
    private rideService: ManageridesService,
    private riderservice: RiderService) {
    const moduleCode = this.activeRoute.snapshot.data.moduleCode;
    this.moduleRiderPermission = this.commonService.getPermission(1600);
   // this.moduleLoadRidePermission = this.commonService.getPermission(1611);
      this.commonService.getLoggedInName.subscribe((userdetail: any) => {
        this.loginuserid = userdetail.id;
        if (userdetail.userType == "usertypeoperator") {
          this.isOperatorUser = true;
        }
    });

  }

  ngOnInit() {
    this.pageSizeValue = 10;
    this.active = -1;
    this.getPageSizes();
    this.getRiderNameDDl();
    this.lstActiveStatus = this.ActiveStatus;
    this.refresh();
  }
  updateFilter(event) {
    this.searchTxt = event.target.value;
    let keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode === 13 || keycode === '13') {
      this.search();
    }
  }


  //getCurrentRiderRecipeDetail() {
  
  //  this.manageridesService.getCurrentRiderRecipeDetail(this.RiderAdminId, '1').subscribe((data: any) => {
  //    console.log('as', data);
  //    data.forEach(childObj => {
        
  //      this.isWaiverCollected = childObj.collectWaiver;
  //    });
     
      
  //  })
   



  //}
  Signwavebutton(row) {
    //this.runridebtn = true;
    //this.isRedirectTosession = true;
    this.router.navigate(['/sign/concent', row.Id, row.RiderAdminUserId]);
    //this.router.navigate(['sign/concent', { riderId: this.RiderId, riderMasterId: this.ridemasterId }]);
  }
  downloadWaiverDetail(RiderId,row) {
    console.log("downloadWaiverDetail", RiderId);
    this.rideService.ClickToDownload(RiderId).subscribe((result: any) => {
      console.log("result blob1", result);
      var filename = "Waiver_" + row.FirstN + '_' + row.LastN + new Date().getHours() + ".pdf";
      var file = new Blob([result], { type: result.type });
      saveAs(file, filename);
    },
      error => {
      });
  }

  getPageSizes() {
    this.commonService.getMasterItemsList("PageSize").subscribe((res: any) => {
      this.lstPageSize = this.commonService.masters;
    })
  }
  getRiderNameDDl() {
    this.riderservice.getRiderNameForFindAllDDl().subscribe((data: any) => {
      this.lstRider = data;
      console.log('asd', this.lstRider)
    })

  }
  SetRiderName($event) {
    console.log("SetRiderName", $event)
    if (typeof $event === 'undefined') {
      this.nameFilter = '';
    } else {
      this.nameFilter = $event.customerId;
    }
  }
  restRiderddl() {
    this.nameFilter = "";
  }
  onChange($event) {
    this.loading = true;
    this.rideService.getRidesList(this.nameFilter, this.idFilter, this.phnFilter, this.active, this.sortColumn, this.sortDir, 0, this.pageSizeValue, this.loginuserid).subscribe(response => {
     
      this.pagedData = this.rideService.pagedData;
      if (this.pagedData.data.length > 0) {
        this.exportExcelTotalRecoards = this.rideService.pagedData.data[0].TotalRecord;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }


  search() {
    this.loading = true;
    this.rideService.getRidesList(this.nameFilter, this.idFilter, this.phnFilter, this.active, this.sortColumn, this.sortDir, 0, this.pageSizeValue, this.loginuserid).subscribe(response => {
      this.pagedData = this.rideService.pagedData;
      if (this.pagedData.data.length > 0) {
        this.exportExcelTotalRecoards = this.rideService.pagedData.data[0].TotalRecord;
      }
      
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  reset() {
    this.table.sorts = [];
    this.loading = true;
    this.nameFilter = '';
    this.idFilter = '';
    this.phnFilter = '';
    this.clearDrop.clearModel();
    this.clearDropRider.clearModel(); 
    this.active = -1;
    this.sortDir = 'desc';
    this.sortColumn = 'CreatedOn';
    this.pageSizeValue = 10;
    this.rideService.getRidesList(this.nameFilter, this.idFilter, this.phnFilter, this.active, this.sortColumn, this.sortDir, 0, 10, this.loginuserid).subscribe(response => {
      this.pagedData = this.rideService.pagedData;
      if (this.pagedData.data.length > 0) {
        this.exportExcelTotalRecoards = this.rideService.pagedData.data[0].TotalRecord;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  setPage($event) {
    this.loading = true;
    this.rideService.getRidesList(this.nameFilter, this.idFilter, this.phnFilter, this.active, this.sortColumn, this.sortDir, $event.page - 1, this.pageSizeValue, this.loginuserid).subscribe(response => {
      this.pagedData = this.rideService.pagedData;
      if (this.pagedData.data.length > 0) {
        this.exportExcelTotalRecoards = this.rideService.pagedData.data[0].TotalRecord;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  onSort($event) {
    const sort = $event.sorts[0]
    this.sortDir = sort.dir;
    this.sortColumn = sort.prop;
    this.loading = true;
    this.rideService.getRidesList(this.nameFilter, this.idFilter, this.phnFilter, this.active, this.sortColumn, this.sortDir, $event.page - 1, this.pageSizeValue, this.loginuserid).subscribe(response => {
      
      this.pagedData = this.rideService.pagedData;
      if (this.pagedData.data.length > 0) {
        this.exportExcelTotalRecoards = this.rideService.pagedData.data[0].TotalRecord;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  refresh() {
    this.loading = true;
  
    this.rideService.getRidesList(this.nameFilter, this.idFilter, this.phnFilter, this.active, this.sortColumn, this.sortDir, 0, this.pageSizeValue, this.loginuserid).subscribe(response => {
      this.pagedData = this.rideService.pagedData;
      if (this.pagedData.data.length > 0) {
        this.exportExcelTotalRecoards = this.rideService.pagedData.data[0].TotalRecord;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    })
    this.loading = false;
  }
  loadRiders(row: any) {
   
    
    const url = '/rides/ride-info/' + row.RiderAdminUserId;
    
      this.router.navigateByUrl(url);
    
    
  }

  disable(row: any) {
    console.log("rowww", row);
    const message = `Are you sure you want to disable Rider  "${row.FirstN} ${row.LastN}"?`;
    this.dialogService.confirm('Disable Rider', message).subscribe(confirmed => {
      if (confirmed) {
        this.riderservice.setRiderStatus(row.Id, row.IsActive).subscribe(r => {
          this.toaster.success(`"${row.FirstN} ${row.LastN}" has been disabled  successfully`);
          this.refresh();
        }, error => {
        });
      }
    });
  }

  enable(row: any) {
    console.log("rowww",row);
    const message = `Are you sure you want to enable Rider "${row.FirstN} ${row.LastN}"?`;
    this.dialogService.confirm('Enable Rider', message).subscribe(confirmed => {
      if (confirmed) {
        this.riderservice.setRiderStatus(row.Id, row.IsActive).subscribe(r => {
          this.toaster.success(`"${row.FirstN} ${row.LastN}" has been enabled  successfully`);
          this.refresh();
        }, error => {
        });
      }
    });
  }
  ActiveStatus = [
    { value: 1, name: 'Active' },
    { value: 0, name: 'In Active' }

  ];
  SetStatus(stype: number) {
    this.active = stype;
  }
  restddl() {
    this.active = -1;
  }

  ExportTOExcel() {
    this.loading = true;
    this.rowsForExport = [];
    this.rideService.getRidesList(this.nameFilter, this.idFilter, this.phnFilter, this.active, this.sortColumn, this.sortDir, 0, this.exportExcelTotalRecoards, this.loginuserid,true).subscribe(response => {
      this.pagedDataForImport = this.rideService.pagedData;
      console.log('as', this.pagedDataForImport)
      if (this.pagedDataForImport.data.length > 0) {
        debugger;
        for (let itm of this.pagedDataForImport.data) {
         
            this.rowsForExport.push({
              'Name': itm.FirstN + ' ' + itm.LastN,
              'Rider ID': itm.RiderId,
              'Phone': itm.PhoneNo,
              'Status': itm.Status,
              'Rider Type': itm.RiderType,
            });
        }
        this.commonService.ExportData(this.rowsForExport, "Excel", "ManageSessions", null);
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  ShowNotesPopup(riderId: string, RiderAdminUserId: string) {
    this.router.navigate(['rides/notes', riderId, this.loginuserid, RiderAdminUserId]);
  }
}

