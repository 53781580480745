import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})


export class EmailnotificationService {
  public baseUri = `${environment.WebApiBaseUrl}`;
  pagedData: any;
  Employeename: any;
  name: any //= this.GetEmailnotificationCount();
  public content = new BehaviorSubject<any>(this.name);
  public share = this.content.asObservable();
  // public Employeename = [];

  getLatestValue(data) {
    this.content.next(data);
    this.Employeename = data;
    console.log("coEmp", this.Employeename);
  }

  constructor(private http: HttpClient) { }

  GetUserNamewithtype() {
    return this.http.get(this.baseUri + `UserProfile/GetUserNamewithtype`);
  }

  SendEmail(EmailData: Emailnotification) {
    return this.http.post(this.baseUri + `Common/Sendemailnotification`, EmailData);

  }
  GetEmailnotificationList(sortColumn: string, sortDir, page: number, pageSize: number, emailFilter: string,) {
    return this.http.get(this.baseUri + `Common/GetEmailnotificationList?SortColumn=${sortColumn}&SortDir=${sortDir}&PageNo=${page}&PageSize=${pageSize}&emailFilter=${emailFilter}`).pipe(
      map((response: any) => {
        this.pagedData = response;
        return true;
      })
    );
  }
  viewEmailnotificationList(sortColumn: string, sortDir, page: number, pageSize: number, emailFilter: string,) {
    return this.http.get(this.baseUri + `Common/viewEmailnotificationList?SortColumn=${sortColumn}&SortDir=${sortDir}&PageNo=${page}&PageSize=${pageSize}&emailFilter=${emailFilter}`);
  }
  setRead(id: string) {
    return this.http.get(this.baseUri + `Common/SetRead?id=${id}`);
  }
  GetEmailnotificationdataByid(Id: string) {
    return this.http.get<Emailnotification>(this.baseUri + `Common/GetEmailnotificationdataByid?Id=${Id}`)
      .pipe(
        tap(data => console.log('GetEmailnotificationdataByid', data)),
      );
  }

  GetEmailnotificationCount(): Observable<number> {
    return this.http.get<number>(this.baseUri + `Common/GetEmailnotificationCount`)
  }
  GetAnouncemenetNotificationList(): Observable<number> {
    return this.http.get<number>(this.baseUri + `Common/GetAnouncemenetNotificationList`)
  }

}


export class Emailnotification {
  id: any;
  selectUser: any;
  emailList: any;
  subject: string;
  content: string;
  selectUserWithStringFormat: any;
  emailListWithStringFormat: any;
  constructor() {
    this.id = "";
    this.selectUser = [];
    this.emailList = [];
    this.subject = "";
    this.content = "";
    this.selectUserWithStringFormat = "";
    this.emailListWithStringFormat = "";

  }


}
