import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';

import { CommonService } from '../../shared/common.service';
import { ManageridesService } from '../managerides.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-eveluationbaseline',
  templateUrl: './eveluationbaseline.component.html',
  styleUrls: ['./eveluationbaseline.component.scss']
})
export class EveluationbaselineComponent implements OnInit {
  riderAdminUserId: any;
  heading: any;
  isRider = false;
  isRiderViewProfile = false;
  ridemasterId: any;
  RiderId: any;
  constructor(
    private dialogService: ConfirmationDialogService,
    private activeRoute: ActivatedRoute,
    private toaster: ToastrService,
    private commonService: CommonService,
    private rideService: ManageridesService,
    private route: Router) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(params => {

      //const id = params.get('id');
      //this.riderAdminUserId = id;
      this.RiderId = params.get('RiderId');
      this.ridemasterId = params.get('RiderMasterId');
    });

    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      console.log("RiderInfo", userdetail);
      if (userdetail.userType == "usertyperider") {
        this.heading = "Manage Core Evaluation";
        this.isRider = true;
        this.riderAdminUserId = userdetail.id;

      }
      else {
        if (this.activeRoute.routeConfig.path == 'ride-info/:id') {

          this.heading = "Manage Core Evaluation";
        }
        else {

          this.heading = "Manage Core Evaluation";
          this.isRiderViewProfile = true;
        }
        this.activeRoute.paramMap.subscribe(params => {

          const id = params.get('id');
          this.riderAdminUserId = id;
        });


      }
    });
  }

  RedirectToEvalution() {
    this.route.navigate(['/rides/evalution-ride', this.RiderId]);
  }
  GoToloadSession() {
    this.route.navigate(['/rides/ride-info', this.ridemasterId]);
  }
  Back() {
    if (this.activeRoute.routeConfig.path == 'ride-history/:id') {
      this.route.navigate(['/rides/ride-info', this.ridemasterId]);
    }
    else {
      this.route.navigate(['/rides/ride-info', this.ridemasterId]);
    }
  }
}
