import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();  
    //enable('trust proxy');
    //app.use(function (req, res, next) {
    //  if (req.secure) {
    //    // https request, nothing to handle
    //    next();
    //  } else {
    //    // this is an http request, redirect to https
    //    res.redirect(301, 'https://' + req.headers.host + req.url);
    //  }
    //});
  
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
