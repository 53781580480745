import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ManageridesService, SaveRide } from '../managerides.service';

@Component({
  selector: 'app-save-ride-recipe',
  templateUrl: './save-ride-recipe.component.html',
  styleUrls: ['./save-ride-recipe.component.scss']
})
export class SaveRideRecipeComponent implements OnInit {
  RidesForm: FormGroup;
  RiderId: any;
  saveRide: SaveRide = new SaveRide();
  EndTime: string;
  name: any;
  CurrentRiderId: any;
  Dob: any;
  Gender: any;
  FirstName: string;
  LastName: string;
  RiderAdminId: any;
  emailAfterRides: any;
  email: any;
  preRideSBP: any;
  preRideDBP: any;
  riderDate: any;
  angle: any;
  personalBest: any;
  rideType: number = 0;
  coreScore: any;
  FromWeb: boolean = true;
  MachineAssignedName: any;
  constructor(private commonService: CommonService,
    private fb: FormBuilder,
    private manageridesService: ManageridesService,
    private dialogService: ConfirmationDialogService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.RiderId = params.get('id');
      }


    );
    this.RiderAdminId = localStorage.getItem("RiderAdminid");
    this.EndTime = new Date().toISOString();
    this.initalizeForm();
    this.getCurrentRiderRecipeDetail();
    this.MachineAssignedName = localStorage.getItem("MachineAssignedName");
  }
  private initalizeForm() {
    this.RidesForm = this.fb.group({
      postRideSBP: ['', [Validators.pattern("[0-9]+")]],
      postRideDBP: ['', [Validators.pattern("[0-9]+")]],
      //bMI: ['', [Validators.required, Validators.pattern("[0-9]+")]],
      //weight: ['', [Validators.required, Validators.pattern("[0-9]+")]],
      restingHeartRate: ['', [Validators.pattern("^[0-9]{0,3}.?[0-9]{0,3}$")]],
      bMI: ['00'],
      weight: ['', [Validators.pattern("^[0-9]{0,3}.?[0-9]{0,3}$")]],

      heightFeet: ['', [Validators.pattern("[0-9]+")]],
      heightInch: ['', [Validators.pattern("[0-9]+")]],
      ambientTemp: ['', [Validators.pattern("^[0-9]{0,3}.?[0-9]{0,3}$")]],
      fullSpecturum: false,
      Id: [null],
      RideEndTime: [''],
      EmailAfterRides: [''],
      angle: [''],
      rideType:[null]
    });
  }
  validateNumberInput(inputNumber) {
    return inputNumber.search(/^[0-9]{0,3}.?[0-9]{0,2}$/) == 0 ? true : false;
  }
  //For Inch Validation
  isNumberKey(event): boolean {
    if (event.which == 101 || event.which == 69 || event.which == 45) return false;
    var charCode = (event.which) ? event.which : event.keyCode;
    var num = event.target.value;
    if (num.length == 1 && charCode != 48 && charCode != 49) {
      return false;
    }
    else {
      return true;
    }
  }

  getCurrentRiderRecipeDetail() {
    this.manageridesService.getCurrentRiderRecipeDetail(this.RiderAdminId,'1').subscribe((data: any) => {
      console.log("data1201data", data);
      data.forEach(childObj => {
        this.name = childObj.firstName + ' ' + childObj.lastName;
        this.FirstName = childObj.firstName;
        this.LastName = childObj.lastName;
        this.CurrentRiderId = childObj.riderId;
        this.Gender = childObj.gender;
        this.Dob = childObj.dob;
        this.emailAfterRides = childObj.emailAfterRides;
        this.email = childObj.email;
        this.preRideSBP = childObj.preRideSBP;
        this.preRideDBP = childObj.preRideDBP;
        this.riderDate = childObj.riderDate;
        this.coreScore = childObj.coreScore;
        this.angle = childObj.angle;
        this.personalBest = childObj.personalBest;
        this.rideType = childObj.rideType

      });
      //this.CurrentRideUserDetail = data;
    })
  }
  cancelForm() {
    this.router.navigate(['/rides/ride-info', this.RiderAdminId]);
  }
  save() {
    debugger
    this.RidesForm.value.Id = this.RiderId;
    this.RidesForm.value.RideEndTime = this.EndTime;
    this.RidesForm.value.EmailAfterRides = this.emailAfterRides;
    this.RidesForm.value.Email = this.email;
    this.RidesForm.value.RideId = this.CurrentRiderId;
    this.RidesForm.value.RiderName = this.name;
    this.RidesForm.value.PreRideSBP = this.preRideSBP;
    this.RidesForm.value.PreRideDBP = this.preRideDBP;
    this.RidesForm.value.RiderDate = this.riderDate;
    this.RidesForm.value.CoreScore = this.coreScore;
    this.RidesForm.value.PersonalBest = this.personalBest;
    this.RidesForm.value.angle = this.angle;
    this.RidesForm.value.rideType = this.rideType;
    if (this.RidesForm.valid) {
      this.manageridesService.save(this.RidesForm.value, this.FromWeb).subscribe((result: any) => {
        if (result.statusCode == 200) {

          this.toastr.success(result.responseMessage);

          this.router.navigate(['/rides/ride-info', this.RiderAdminId]);

        }
        else {
          this.toastr.error('Error!', 'Unable to save record.');
        }
      })
    }
    else {
      this.commonService.validateAllFormFields(this.RidesForm);
    }
  }

  calculateScoreValues(event, section) {
    debugger
    let heightIntoFit = ((parseInt(this.heightFeet.value == '' ? 0 : this.heightFeet.value) * 12) + parseInt(this.heightInch.value == '' ? 0 : this.heightInch.value));
    let total;
    if ((this.heightInch.value != "" || this.heightFeet.value != '') && this.weight.value != '') {
      total = (parseInt(this.weight.value == '' ? 0 : this.weight.value) / heightIntoFit / heightIntoFit) * 703
      this.bMI.setValue(Math.round(total));
    }
    else {
      this.bMI.setValue(0);
    }
    
   
  }

  get restingHeartRate() { return this.RidesForm.get('restingHeartRate'); }
  get postRideSBP() { return this.RidesForm.get('postRideSBP'); }
  get postRideDBP() { return this.RidesForm.get('postRideDBP'); }
  get bMI() { return this.RidesForm.get('bMI'); }
  get weight() { return this.RidesForm.get('weight'); }
  get heightInch() { return this.RidesForm.get('heightInch'); }
  get ambientTemp() { return this.RidesForm.get('ambientTemp'); }
  get heightFeet() { return this.RidesForm.get('heightFeet'); }
  get Id() { return this.RidesForm.get('Id'); }
  get RideEndTime() { return this.RidesForm.get('RideEndTime'); }
  get EmailAfterRides() { return this.RidesForm.get('EmailAfterRides'); }
}
