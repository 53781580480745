import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUri = `${environment.WebApiBaseUrl}`;
  Browser: any;
  BrowserVersion: any;
  OS: string
  OSVersion: string;
  deviceInfo = null;
  constructor(private http: HttpClient, private deviceService: DeviceDetectorService) {}

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.Browser = this.deviceInfo.browser;
    this.BrowserVersion = this.deviceInfo.browser_version;
    this.OS = "";
    this.OSVersion = this.deviceInfo.os_version;
    
  }
  login(loginModel: Login) {
    return this.http.post(this.baseUri + `Account/Login`, loginModel);
  }
 
  ForgotPsw(forgotPswModel: ForgotPassword) {
    return this.http.post(this.baseUri + `Account/ForgotPassword`, forgotPswModel);
  }
  GetCred() {
    return this.http.get(this.baseUri + `Account/GetRememberMeCredentials`);
  }
  getCurrentUserDetail() {
    return this.http.get(this.baseUri + `UserProfile/GetUserDetail`);
  }

  confirmEmail(userid: any, token: string) {
    return this.http.get(this.baseUri + `Account/Confirm?userId=${userid}&token=${token}`);
  }
  refreshtoken(username: string,usertype :string,customerRiderId :string) {
    this.epicFunction();
    return this.http.get(this.baseUri + `Account/RefreshToken?username=${username}&browser=${this.Browser}&browserVersion=${this.BrowserVersion}&os=${this.OS}&OSVersion=${this.OSVersion}&usertype=${usertype}&customerRiderId=${customerRiderId}`);
  }
  setPassword(setPassword: SetPassword) {
    return this.http.post(this.baseUri + `Account/SetPassword`, setPassword);
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.post(this.baseUri + `Account/ResetPassword`, resetPassword);
  }

  getUserProfile() {
    return this.http.get(this.baseUri + `UserProfile/GetUserDetail`);
  }

  updateUserProfile(userProfileModel: UserProfile) {
    return this.http.post(this.baseUri + `UserProfile/updateUserProfile`, userProfileModel);
  }

  ancryptAllData() {
    return this.http.get(this.baseUri + `Account/GetAllUsersFromAspNetusers`);
  }
  ancryptRider() {
    return this.http.get(this.baseUri + `Account/GetAllRider`);
  }
  ancryptCustomer() {
    return this.http.get(this.baseUri + `Account/GetAllCustomer`);
  }
  updatetoken(username: string, usertypeid: any) {
    this.epicFunction();
    return this.http.get(this.baseUri + `Account/UpdateToken?username=${username}&browser=${this.Browser}&browserVersion=${this.BrowserVersion}&os=${this.OS}&OSVersion=${this.OSVersion}&switchAsUserTypeId=${usertypeid}`);
  }
}
export class Login {
  userName: string;
  password: string; 
  rememberMe: boolean;
  browser: string;
  os: string;
  iPAddress: string
  timeZoneOffSet: string
  constructor() {
    this.userName = '';
    this.password = ''; 
    this.rememberMe = false;
    this.browser = '';
    this.os = '';
    this.iPAddress = '';
    this.timeZoneOffSet = '';
  }
}

export class SetPassword {
  password: string;
  confirmPassword: string;
  userId: any;
  token: any;
  constructor() {
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
    this.token = "";
  }
}
export class ResetPassword {
  password: string;
  confirmPassword: string;
  userId: any;
  resetToken: any;
  constructor() {
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
  }
}
export class ForgotPassword {
  email: string;

  constructor() {
    this.email = '';
  }
}

export class UserProfile {
  FirstName: string;
  LastName: string;
  UserType: string;
  Email: string;
  PhoneNumber: string;
  Address: string;
  Position: string;
  employeeType: string;
  isActive: boolean;
  notes: string;
  profilePic: string;
  Id: string;
  constructor() {
    this.FirstName = "";
    this.LastName = "";
    this.UserType = "";
    this.Email = "";
    this.PhoneNumber = "";
    this.Address = "";
    this.Position = "";
    this.employeeType = "";
    this.isActive = false;
    this.notes = "";
    this.Id = "";
  }
}



