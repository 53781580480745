import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailnotificationlistComponent } from './emailnotificationlist.component';
import { SharedModule } from '../shared/shared.module';
import { EmailnotificationComponent } from './emailnotification.component';
import { EmailnotificationRoutingModule } from './emailnotification-routing.module';
import { ViewnotificationComponent } from './viewnotification.component';
import { ModalModule } from 'ngx-bootstrap';
import { CKEditorModule } from 'ng2-ckeditor';


@NgModule({
  declarations: [EmailnotificationlistComponent,
    EmailnotificationComponent,
    ViewnotificationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule, CKEditorModule,
    EmailnotificationRoutingModule, ModalModule.forRoot()
  ]
})
export class EmailnotificationModule {
}
