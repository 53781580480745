import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageridesService, SaveNotes } from './managerides.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../shared/common.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-managenotes',
  templateUrl: './managenotes.component.html',
})
export class ManageNotesComponent implements OnInit {
  notesForm: FormGroup;
  noteobj: SaveNotes = new SaveNotes()
  loadSave = false;
  riderId: any;
  notesList: any;
  loginUserId: any;
  riderAdminUserId: any;
  FirstName: string;
  LastName: string;
  riderName: string; bmi
  riderNumber: string;
  gender: string;
  sortDirEvaluation = 'desc';
  sortColumnEvaluation = 'CreatedOn';
  paged: any;
  isRider: boolean = false;
  loginuserid: any;
  sortDir = 'desc';
  sortColumn = 'CreatedOn';
  dob: any;
  personalBestEvaluation: any;
  pagedDataEvaluation: any = {
    pager: {},
    data: []
  };
  pageSizeValueEvaluation: number=10;
  pageSizeValue: number=100;
  pagedData: any = {
    pager: {},
    data: []
  };
  constructor(private router: Router, private fb: FormBuilder,
    private riderSerive: ManageridesService, private location: Location,
    private toaster: ToastrService, private route: ActivatedRoute,
    private commonService: CommonService) {
  }

  ngOnInit() {
    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      if (userdetail.userType == "usertyperider") {

        this.isRider = true;

      } else {
        this.isRider = false
      }

    })
    this.route.paramMap.subscribe(
      params => {
        this.riderId = params.get('riderId');
        this.loginUserId = params.get('userId');
        this.riderAdminUserId = params.get('riderAdminUserId');
        this.getRideInfo();
        this.getRideInfoEvaluation();
      })


    

    this.getRideInfo();
    this.initalizeForm();
    this.ReadMessage();
    this.GetNotesList();
    this.prepareSignalRRider();
    this.prepareSignalRClic();
  }

  getRideInfoEvaluation() {
    this.loadSave = true;
    if (this.riderAdminUserId != null) {
      this.riderSerive.getRideInfoList(this.sortColumnEvaluation, this.sortDirEvaluation, 0, this.pageSizeValueEvaluation, this.loginuserid, this.riderAdminUserId, 2).subscribe(response => {
        this.paged = this.riderSerive.pagedData;
        //////////// localStorage.removeItem('MachineAssignedName');
        console.log("Deepakjha", this.paged);
        if (this.paged.data != null && this.paged.data.length > 0) {

          if (this.paged.data[0].Id != null) {
            this.pagedDataEvaluation = this.riderSerive.pagedData;
            // this.TotalRecord = this.pagedData.data[0].TotalRecord;
            this.FirstName = this.paged.data[0].FirstName;
            this.LastName = this.paged.data[0].LastName;
            this.dob = this.paged.data[0].DOB;
            this.gender = this.paged.data[0].Gender;
            this.riderNumber = this.paged.data[0].RideId;
       
          }
          else {
            this.FirstName = this.paged.data[0].FirstName;
            this.LastName = this.paged.data[0].LastName;
            this.dob = this.paged.data[0].DOB;
            this.gender = this.paged.data[0].Gender;
            this.riderNumber = this.paged.data[0].RideId;
            
          }
        }

       
        ////////// localStorage.setItem('MachineAssignedName', this.MachineAssignedName);
        this.loadSave = false;
      }, error => {
        this.loadSave = false;
      })
    }
  }
  getRideInfo() {
    console.log("getRideInfo call");
    this.loadSave = true;
    debugger
    if (this.riderAdminUserId != null) {
      this.riderSerive.getRideInfoList(this.sortColumn, this.sortDir, 0, this.pageSizeValue, this.loginUserId, this.riderAdminUserId,1).subscribe(response => {
        this.paged = this.riderSerive.pagedData;
      
        console.log("getRideInfo", this.paged)
        if (this.paged.data.length > 0) {
          if (this.paged.data[0].Id != null) {
            this.pagedData = this.riderSerive.pagedData;
            this.FirstName = this.pagedData.data[0].FirstName;
            this.LastName = this.pagedData.data[0].LastName;
            this.dob = this.pagedData.data[0].DOB;
            this.gender = this.pagedData.data[0].Gender;
            this.riderNumber = this.pagedData.data[0].RideId;
          }
          else {
            this.FirstName = this.paged.data[0].FirstName;
            this.LastName = this.paged.data[0].LastName;
            this.dob = this.paged.data[0].DOB;
            this.gender = this.paged.data[0].Gender;
            this.riderNumber = this.paged.data[0].RideId;
          }
         
        }

        

       
       

        this.loadSave = false;
      }, error => {
        this.loadSave = false;
      })
    }
  }
  Cancel() {
    /*this.router.navigate(['/rides']);*/
    this.location.back();
  }
  private initalizeForm() {
    this.notesForm = this.fb.group({
      Note: ['', Validators.required],
    });
  }

  AddNotes() {
    if (this.notesForm.valid) {
      this.notesForm.value.RiderId = this.riderId
      this.riderSerive.AddNotes(this.notesForm.value).subscribe((result: any) => {
        if (result.statusCode == 200) {
          this.toaster.success('Note has been sent.');
          this.loadSave = false;
          this.notesForm.reset();
        }
        else {
          this.toaster.error(result.responseMessage);
        }
        this.GetNotesList();
      }, error => {
        this.loadSave = false;
      })
    }
    else {
      this.commonService.validateAllFormFields(this.notesForm);
    }
  }
  prepareSignalRRider() {
    this.commonService.hubConnection.on("sendChatMessageToRider", (message: string, isShowToaster: boolean) => {
     
      this.GetNotesList();

    });
  }
  prepareSignalRClic() {
    this.commonService.hubConnection.on("sendChatMessageToClinic", (message: string, isShowToaster: boolean) => {
     
      this.GetNotesList();

    });
  }
  GetNotesList() {
    this.loadSave = true;
    this.riderSerive.GetNotesList(this.riderId).subscribe(response => {
      this.notesList = response;
      this.loadSave = false;
    }, error => {
      this.loadSave = false;
    })
    
  }
  ReadMessage() {
    this.riderSerive.ReadMessage(this.loginUserId).subscribe((result: any) => {
    })
  }
  get Note() { return this.notesForm.get('Note'); }

  
}

