import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { text } from '@angular/core/src/render3';
import { validateConfig } from '@angular/router/src/config';
import { LowerCasePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ManageUserService {
  public baseUri = `${environment.WebApiBaseUrl}`;
  public userUri = `${environment.WebApiBaseUrl}UserProfile`;
  pagedData: any;
  constructor(private http: HttpClient) { }

  addeditUser(user: FormData,) {

    return this.http.post(this.baseUri + `Account/Register`, user);

    //if (user.Id == null) {//add
    //  return this.http.post(this.baseUri + `Account/Register`, user);
    //}
    //else {//update
    //  return this.http.post(this.baseUri + `UserProfile/UpdateUser`, user);
    //}
  }

  //getBankName() {
  //  return this.http.get(this.baseUri + `Bank/GetBankDropList`);
  //}
  getRolesName(id: any) {
    return this.http.get(this.baseUri + `Role/GetRolesDropListByUserType?id=${id}`)
  }

  getUserList(name: string, userType: string, status: number, sortColumn: string, sortDir, page: number, pageSize: number, userId: string, isExport = false): Observable<any> {
  
    if (typeof name == "undefined" || name == null) { name = null; }
    else { name = encodeURIComponent((name)); }
   
    return this.http.get(`${this.userUri}/GetUsersList?name=${name}&userType=${userType}&status=${status}&sortColumn=${sortColumn}&sortDir=${sortDir}&page=${page}&pageSize=${pageSize}&userId=${userId}&isExport=${isExport}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          return true;
        })
      );
  }

  //function used to set active/inactive status of user
  changeStatus(id: string) {
    return this.http.post(`${this.userUri}/ChangeStatus/${id}`, null);
  }

  //function is used to get detail of  user
  getUserDetail(id: any) {
    return this.http.get(this.baseUri + `UserProfile/GetUserDetailById?userId=${id}`)
  }
  getAssignedUserForRider(Id:any) {
    return this.http.get(this.baseUri + `UserProfile/GetAssignedUserForRider?Id=${Id}`)
  }
  //function is used to delte the  user
  deleteUser(id: any) {    
      return this.http.get(this.baseUri + `UserProfile/DeleteUser?userid=${id}`)    
  }
  DeleteUers(conId: string) {
    return this.http.get(this.baseUri + `UserProfile/DeletedMultipleUers?id=${conId}`)
  }
  //function is used to get the StateList
  getStateList() {
    return this.http.get(this.baseUri + `ManageInsurance/GetStateList`);
  }
  GetUserName() {
    return this.http.get(this.baseUri + `UserProfile/GetUserName`);
  }
}

export class User {
  firstName: string;
  lastName: string;
  userType: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  position: string;
  employeeType: string;
  isActive: boolean;
  notes: string;
  Id: string;
  UserId: string;
  assignedBankId: string;
  roleId: string;
  executiveCommisionFormula: string;
  uploadProfilePic: string;
  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.userType = "";
    this.email = "";
    this.phoneNumber = "";
    this.uploadProfilePic = "";
    this.isActive = false;
    this.Id = "";
    this.UserId = "";
    this.roleId = "";


    //this.address = "";
    //this.city = "";
    //this.state = "";
    //this.zipCode = "";
    //this.county = "";
    //this.position = "";
    //this.employeeType = "";
    //this.notes = "";
    //this.assignedBankId = "";
    //this.executiveCommisionFormula = "";
  }
}

export class MasterItems {
  MasterItemName: string;
  Text: string;
  Value: string
  constructor() {
    this.MasterItemName = "";
    this.Text = "";
    this.Value = "";
  }
}


