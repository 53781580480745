import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ConfirmationDialogService } from '../shared/confirmation-dialog/confirmation-dialog.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService, ModuleList } from '../shared/common.service';
import { ManageridesService } from './managerides.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { expand, filter, debounce } from 'rxjs/operators';
import { Chart } from 'chart.js';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ModalDirective } from 'ngx-bootstrap';
declare let html2canvas: any;


@Component({
  selector: 'app-load-managerides',
  templateUrl: './load-managerides.component.html',
  styleUrls: ['./load-managerides.component.scss']
})
export class LoadManageridesComponent implements OnInit {
  @ViewChild('myTable') table: any;
  @ViewChild('changePassModal') changePassModal: ModalDirective;
  @ViewChild('myTableEvaluation') Newtable: any;
  @ViewChild('targetEvalution') targerEvalutionDiv: any;
  @ViewChild('clearDropRider') clearDrop: NgSelectComponent;
  @ViewChild("screen") screen: ElementRef;
  finalOne: any;
  @ViewChild("canvas") canvas: ElementRef;
  @ViewChild("downloadLink") downloadLink: ElementRef;
  facebookUrl: any ='https://stage.allcore360.com/assets/images/logo_White.png';
  tabledata: any;
  loading = false;
  sortDir = 'desc';
  sortColumn = 'CreatedOn';
  paged: any;
  inlineStye = 'overflow: initial !important;';
  loadSave = false;
  myChart: any;
  pagedData: any = {
    pager: {},
    data: []
  };
  GetAllClinicpagedData: any = {
    pager: {},
    data: []
  };
  historyData: any = {
    pager: {},
    data: []
  };
  historyDataEvalutions: any = {
    pager: {},
    data: []
  };
  pageNumber = 0;
  lstAssociaeclinicList: any;
  EvalutionTimeByQuadrantchart = [];
  pageSizeValue: number;
  lstPageSize: any;
  loginuserid: any;
  riderAdminUserId: any;
  riderName: string; bmi
  riderNumber: string;
  IsAverageAngle: boolean;
  IsCoreScore: boolean;
  gender: string;
  dob: any;
  expanded: any = {};
  timeout: any;
  showAngle = false;
  TotalRecord: any;
  angleGraphWidth: any = 300;
  angleGraphHeight: any = 400;
  MonthlyCount: any;
  YearlyCount: any;
  addAllCoreRiderId: any;
  detailData: any;
  detailDataEvalution: any;
  show: boolean = false;
  evaluationshow: boolean = false;
  isRider = false;
  heading: any;
  QuarterlyCount: any;
  ColumnMode = ColumnMode;
  // moduleLoadRidePermission: ModuleList;
  toggle: any;
  Fromdate: any;
  AllcoreTotalRecoards: number = 0;
  Todate: any;
  FromdateAngle: any;
  TodateAngle: any;
  EvaluationTodate: any;
  EvaluationFromdate: any;
  Date = new Date();
  CoreScore: any[];
  CreatedOn: any[];
  FirstName: string;
  LastName: string;
  angle: any[];
  CoreScoreDate: any[];
  CreatedOnDate: any[];
  CreatedOnDateAngle: any[];
  RideMasterId: any;
  TimeForSession: any;
  personalBest: any;
  RiderIsActive: boolean = false;
  MachineAssigned: boolean = false;
  chartDateRange: Chart;
  evaluationchartDateRange: Chart;
  QuadarantChart: Chart;
  chartCoreScoreAngle: Chart;
  CoreSCoreGraph: Chart;
  public myChartQuadrant: Chart;
  AnglechartWithCoreScore: Chart;
  MachineAssignedName: any;
  CurrentAssignedMachine: any;
  isRiderViewProfile: boolean = false;
  BPGraph: number[];
  PreBPGraph: number[];
  WeightGraph: number[];
  BMIGraph: number[];
  CreatedOn1: string[];
  BPGraphEvalution: number[];
  PreBPGraphEvalution: number[];
  WeightGraphEvalution: number[];
  RestingHeartRate: number[];
  BMIGraphEvalution: number[];
  CreatedOn1Evalution: string[];
  CreatedOnQuadrant: string[];
  EvalutionWeightDate: string[];
  RestingHeartRateDate: string[];
  BMIDate: string[];
  Anterior: number[];
  Posterior: number[];
  RightLateral: number[];
  LeftLateral: number[];
  ForDataTableUsed = [];
  values: any;
  Evalutionvalues: any;
  isbiomatrics = true;
  isEvaluation = true;
  isCoreCores = true;
  isSessionResults = true;
  lstMonthDrp: any;
  duration = '7';
  ridertemplateData: any;
  personalBestEvaluation: any;
  pagedDataEvaluation: any = {
    pager: {},
    data: []
  };
  pageSizeValueEvaluation: number;
  sortDirEvaluation = 'desc';
  sortColumnEvaluation = 'CreatedOn';
  currentClinicId: any;
  @ViewChild('myTableEvaluation') tableEvaluation: any;
  moduleSessionPermission: ModuleList;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  constructor(
    private dialogService: ConfirmationDialogService,
    private activeRoute: ActivatedRoute, private elementRef: ElementRef,
    private toaster: ToastrService,
    private commonService: CommonService,
    private rideService: ManageridesService, private cdRef: ChangeDetectorRef,
    private route: Router) {
    // this.moduleLoadRidePermission = this.commonService.getPermission(1611);
    this.values = 7;
    this.lstMonthDrp = this.MonthDusraion;
    this.MonthlyCount = "0";
    this.QuarterlyCount = "0";
    this.YearlyCount = "0";
    this.TotalRecord = "0";
    this.moduleSessionPermission = this.commonService.getPermission(1111);

  }

  ngOnInit() {
    debugger
    this.loadSave = true;
    this.currentUrl = this.route.url;
    
    

    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        let data = this.previousUrl.indexOf('/rides/evalution-ride/');
        let previusData = this.previousUrl.indexOf('/rides/save-ride/');
        //rides/ride-recipe/
        let previusDataSave = this.previousUrl.indexOf('/rides/ride-recipe/');
        
        if (data == 0) {

          setTimeout(function () {

            document.getElementById("personalbestEvaluationTable").scrollIntoView(true);
            debugger

          }, 1000);
          //var scroldata = document.getElementById('personalbestEvaluationTable').scrollHeight=0;


        }
        if (previusData == 0 || previusDataSave == 0) {
          setTimeout(function () {
            document.getElementById('personalbestSessionHis').scrollIntoView(true);
          }, 1000);
        }
      };
      
    });

    this.show = false;
    this.showAngle = false;
    this.IsCoreScore = true;
    this.CreatedOn = [];
    this.CoreScore = [];
    this.angle = [];
    this.CreatedOnDate = [];
    this.CoreScoreDate = [];
    this.pageSizeValue = 10;
    this.pageSizeValueEvaluation = 10;
    this.getPageSizes();
    let grapharea = document.getElementById("canvas3");
    //grapharea.remove();
    let grapharea1 = document.getElementById("canvas1");
    // grapharea1.remove();
    this.personalBest = "";
    this.personalBestEvaluation = "";
    this.RiderIsActive = false;
    this.MachineAssigned = false;
    this.BPGraph = [];
    this.PreBPGraph = [];
    this.WeightGraph = [];
    this.BMIGraph = [];
    this.CreatedOn1 = [];
    this.BPGraphEvalution = [];
    this.PreBPGraphEvalution = [];
    this.WeightGraphEvalution = [];
    this.RestingHeartRate = [];
    this.BMIGraphEvalution = [];
    this.CreatedOn1Evalution = [];
    this.values = 7;
    this.Evalutionvalues = 7;
    this.lstMonthDrp = this.MonthDusraion;

    this.activeRoute.paramMap.subscribe(params => {

      const id = params.get('id');
      this.riderAdminUserId = id;
    });

    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      if (userdetail.userType == "usertyperider") {
        this.heading = "Sessions History";
        this.isRider = true;
        this.riderAdminUserId = userdetail.id;
        this.getRideInfo();
        this.getRideInfoEvaluation();
        this.GetAllRiderForAllClinic();
      }
      else {
        if (this.activeRoute.routeConfig.path == 'ride-info/:id') {
          this.heading = "Manage Sessions";
          ////// this.getRideInfo();
          ///// this.getRideInfoEvaluation();
        }
        else {
          this.heading = "Sessions History";
          this.isRiderViewProfile = true;
        }
        this.activeRoute.paramMap.subscribe(params => {
          const id = params.get('id');
          this.riderAdminUserId = id;
        });


        this.getRideInfo();
        this.getRideInfoEvaluation();

        //this.route.queryParams.subscribe(params => {
        //  let isMyProfiles = params['isMyProfile'];

        //});
      }

      var $this = this;
      //this.GetRiderDashboardGraphs(this.riderAdminUserId, this.duration);
      setTimeout(function () {
        $this.GetRiderDashboardGraphs($this.riderAdminUserId, 1);
        $this.GetRiderDashboardGraphsForEvalution($this.riderAdminUserId, 1, '', '');
        $this.getRidesHistoryGraph('1');
        $this.evaluationgetRidesHistoryGraph('1');
        // $this.getRidesHistoryGraphAngle('1');
        $this.GetRiderDashboardGraphsForQuadrant($this.riderAdminUserId, 1, '', '');
      }, 1000);
    });
    localStorage.removeItem('RiderAdminid');
    localStorage.setItem('RiderAdminid', this.riderAdminUserId);
    localStorage.removeItem('MachineAssignedName');
    

    setTimeout(function () {
      var div = document.getElementById("CoreScore_Show");
      if (div != null) {
        div.style.display = "block";
      }
      var divAngle = document.getElementById("AverageAngle_Show");
      if (divAngle != null) {
        divAngle.style.display = "none";
      }
    }, 1000);
      let dataBiomatrics = this.currentUrl.indexOf('/biometrics');
      if (dataBiomatrics == 0) {
        this.isbiomatrics = true;
        this.isEvaluation = false;
        this.isSessionResults = false;
        this.isCoreCores = false;
      }
      let datasessionresults = this.currentUrl.indexOf('/sessionresults');
      if (datasessionresults == 0) {
        this.isSessionResults = true;
        this.isbiomatrics = false;
        this.isEvaluation = false;
        this.isCoreCores = false;
      }
      let dataevaluations = this.currentUrl.indexOf('/evaluations');
      if (dataevaluations == 0) {
        this.isEvaluation = true;
        this.isSessionResults = false;
        this.isbiomatrics = false;
        this.isCoreCores = false;
      }
      let datacorescores = this.currentUrl.indexOf('/corescores');
      if (datacorescores == 0) {
        this.isEvaluation = false;
        this.isSessionResults = false;
        this.isbiomatrics = false;
        this.isCoreCores = true;
      }
    //}, 1000);
    //this.loadSave = false;
    console.log("isCoreCores", this.isCoreCores);

    let actualClinicId = JSON.parse(localStorage.getItem('userinfo')).clinicId;
    if (actualClinicId != null && typeof actualClinicId != undefined) {
      this.currentClinicId = actualClinicId;
    }
    
  }

  GetAllRiderForAllClinic() {
    this.loading = true;
    this.commonService.GetAllRiderForAllClinic('', -1, 'CreatedOn', 'Desc', 0, 100).subscribe((response) => {
      this.GetAllClinicpagedData = this.commonService.pagedData.data;
      console.log("GetAllClinicpagedData", this.pagedData);

      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  SetAllClinicpaged(event) {
    const message = `Are you sure you want to switch to "${event.ClinicName}" clinic?`;
    this.dialogService.confirm('Switch Clinic', message).subscribe(confirmed => {
      if (confirmed) {
        //if (event.asociatedUser==1 || event.asociatedUser==1) {

        //}
        const basicuserinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.commonService.UpdateTokenForMultipleClinic(basicuserinfo.email, basicuserinfo.userTypeID, event.ParentClinicId).subscribe((res: any) => {
          if (res) {
            if (res.status == 200) {
              localStorage.setItem("access_token", res.token);
              this.commonService.getCurrentUserDetail();
              setTimeout(() => {
                this.commonService.getLoggedInName.subscribe((userdetail: any) => {
                  console.log("switchUser 12", userdetail);

                  this.loading = false;
                  if (userdetail.userType == 'usertyperider') {

                    console.log("switch usertyperider");
                    this.commonService.getUserModulePermissions().subscribe((m: any) => {
                      localStorage.removeItem('moduleList');
                      localStorage.setItem('moduleList', JSON.stringify(m));
                      // window.location.href = "ridehistory";
                      this.toaster.success(`You are switched as ` + event.ClinicName);
                      //this.route.navigateByUrl("/ridehistory");
                      window.location.href = "ridehistory";
                    });
                  }
                  else {
                    console.log("switch dashboard");
                    this.commonService.getUserModulePermissions().subscribe((m: any) => {
                      localStorage.removeItem('moduleList');
                      localStorage.setItem('moduleList', JSON.stringify(m));
                      this.route.navigateByUrl("/dashboard");
                      // window.location.href = "dashboard";
                    });
                  }
                });
              }, 2000);

            }
            else if (res.status == 201) {
              this.toaster.error(res.token);
            }
          }
        }, error => {
        });
      } else {
        this.clearDrop.clearModel();
      }
    });
  }
  displayCheck(row) {
    return row.asociatedUser !== 1;
  }

  onDetailToggle(event) {
    //debugger
    this.loading = true;
    if (this.Newtable && this.Newtable.rowDetail) {
      //this.Newtable.rowDetail.expandAllRows();
      this.Newtable.rowDetail.collapseAllRows();
      this.cdRef.detectChanges();
    }

    var id = event.value.Id;
    if (id != undefined) {
      this.rideService.getRideHistoryDetail(this.sortColumn, this.sortDir, 0, this.pageSizeValue, this.loginuserid, id).subscribe(response => {
        this.historyData = this.rideService.historyData;
        this.detailData = this.historyData.data;
        this.loading = false;
      }, error => {
        this.loading = false;
      })
    }
    this.loading = false;
  }
  onDetailToggleEvalutions(event) {
    this.loading = true;
    var id = event.value.Id;
    if (id != undefined) {
      this.rideService.getRideHistoryDetail(this.sortColumn, this.sortDir, 0, this.pageSizeValue, this.loginuserid, id).subscribe(response => {
        this.historyDataEvalutions = this.rideService.historyData;
        this.detailDataEvalution = this.historyDataEvalutions.data;
        this.loading = false;
      }, error => {
        this.loading = false;
      })
    }
    this.loading = false;
  }
  getPageSizes() {
    this.commonService.getMasterItemsList("PageSize").subscribe((res: any) => {
      this.lstPageSize = this.commonService.masters;
    })
  }

  getRideInfo() {

    console.log("getRideInfo call");
    this.loading = true;
    if (this.riderAdminUserId != null) {
      this.rideService.getRideInfoList(this.sortColumn, this.sortDir, 0, this.pageSizeValue, this.loginuserid, this.riderAdminUserId, 1).subscribe(response => {
        this.paged = this.rideService.pagedData;
        localStorage.removeItem('MachineAssignedName');
        console.log("getRideInfo", this.paged)
        if (this.paged.data.length > 0) {
          if (this.paged.data[0].Id != null) {
            //debugger;
            this.pagedData = this.rideService.pagedData;

            console.log("getRideInfo 1", this.pagedData)

            this.TotalRecord = this.pagedData.data[0].TotalRecord;
            this.FirstName = this.pagedData.data[0].FirstName;
            this.LastName = this.pagedData.data[0].LastName;
            this.dob = this.pagedData.data[0].DOB;
            this.gender = this.pagedData.data[0].Gender;
            this.riderNumber = this.pagedData.data[0].RideId;
            this.MonthlyCount = this.pagedData.data[0].MonthlyCount;
            this.YearlyCount = this.pagedData.data[0].YearlyCount;
            this.addAllCoreRiderId = this.pagedData.data[0].RId;
            this.QuarterlyCount = this.pagedData.data[0].QuarterlyCount;
            this.personalBest = this.pagedData.data[0].PersonalBestD;
            this.MachineAssigned = this.pagedData.data[0].isMachineAssigned;
            this.RiderIsActive = this.pagedData.data[0].Active;
            this.MachineAssignedName = this.pagedData.data[0].MachineName;
            this.CurrentAssignedMachine = this.pagedData.data[0].CurrentAssignedMachine;
            this.RideMasterId = this.pagedData.data[0].RideMasterId;
            this.AllcoreTotalRecoards = this.pagedData.pager.totalItems;

          }
          else {
            this.AllcoreTotalRecoards = 0;
            console.log("getRideInfo 2", this.paged.data)
            this.FirstName = this.paged.data[0].FirstName;
            this.LastName = this.paged.data[0].LastName;
            this.dob = this.paged.data[0].DOB;
            this.gender = this.paged.data[0].Gender;
            this.riderNumber = this.paged.data[0].RideId;
            this.TotalRecord = 0;
            this.MonthlyCount = this.paged.data[0].MonthlyCount;
            this.YearlyCount = this.paged.data[0].YearlyCount;
            this.addAllCoreRiderId = this.paged.data[0].RId;
            this.QuarterlyCount = this.paged.data[0].QuarterlyCount;
            this.personalBest = this.paged.data[0].PersonalBestD;
            this.MachineAssigned = this.paged.data[0].isMachineAssigned;
            this.RiderIsActive = this.paged.data[0].Active;
            this.MachineAssignedName = this.paged.data[0].MachineName;
            this.CurrentAssignedMachine = this.paged.data[0].CurrentAssignedMachine;
          }
        }

        if (this.MachineAssignedName == null || this.MachineAssignedName == 'undefined') {
          this.MachineAssignedName = 'N/A';
        } if (this.CurrentAssignedMachine == null || this.CurrentAssignedMachine == 'undefined') {
          this.CurrentAssignedMachine = 'N/A';
        }


        localStorage.setItem('MachineAssignedName', this.MachineAssignedName);
        if (this.paged.data.length > 0) {
          if (this.paged.data[0].Id != null) {

          }
          else {
            this.pagedData = this.rideService.pagedData;
            this.pagedData.data = null;
            this.pagedData.pager.totalItems = 0;
          }
        }
        else {
          this.pagedData = this.rideService.pagedData;
          this.pagedData.data = null;
          this.pagedData.pager.totalItems = 0;
        }

        this.loading = false;
      }, error => {
        this.loading = false;
      })
    }
  }

  onChange($event) {
    this.loading = true;
    this.rideService.getRideInfoList(this.sortColumn, this.sortDir, 0, this.pageSizeValue, this.loginuserid, this.riderAdminUserId, 1).subscribe(response => {
      this.pagedData = this.rideService.pagedData;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  setPage($event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);

    this.loading = true;
    this.rideService.getRideInfoList(this.sortColumn, this.sortDir, $event.page - 1, this.pageSizeValue, this.loginuserid, this.riderAdminUserId, 1).subscribe(response => {
      this.pagedData = this.rideService.pagedData;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  onSort($event) {
    const sort = $event.sorts[0]
    this.sortDir = sort.dir;
    this.sortColumn = sort.prop;
    this.loading = true;
    this.rideService.getRideInfoList(this.sortColumn, this.sortDir, $event.page - 1, this.pageSizeValue, this.loginuserid, this.riderAdminUserId, 1).subscribe(response => {
      this.pagedData = this.rideService.pagedData;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  addEvaluation() {
    //debugger
    if (this.RiderIsActive == false) {
      this.toaster.error("Please Enable the Rider first to Add AllCore360 Session.");
      //Kiran ---7 July --- this msg should not be shown in confimation msg--no need to take any input from user
      //const message = `Please Enable the Rider first to Add AllCore 360 Ride.`;
      //this.dialogService.confirm('Enable the Rider', message).subscribe(confirmed => {
      //});

    }
    else if (this.MachineAssigned == false) {

      this.rideService.SaveMachineWhenClinicHaveOneMachine(this.addAllCoreRiderId).subscribe((response: any) => {
        if (response != "0") {
          this.getRideInfo();
          //evalution-baseline this.addAllCoreRiderId, this.riderAdminUserId
          this.route.navigate(['/rides/evalution-ride', this.riderAdminUserId, this.addAllCoreRiderId]);
          // this.route.navigate(['/rides/evalution-baseline', this.addAllCoreRiderId, this.riderAdminUserId]);
        }
        else {
          this.toaster.error("Please assign machine to Rider first to Load Session.");
        }
      });

      //const message = `Please assign Machine to Rider first to Load Ride.`;
      //this.dialogService.confirm('Assign Machine To Rider', message).subscribe(confirmed => {
      //});
    }

    else {
      // window.open('https://google.com/about', '_blank');

      //if (location.protocol === 'https:') {
      //  window.location.href = location.href.replace('https', 'http');


      //}
      this.route.navigate(['/rides/evalution-ride', this.riderAdminUserId, this.addAllCoreRiderId]);
      /////  this.route.navigate(['/rides/evalution-baseline', this.addAllCoreRiderId, this.riderAdminUserId]);
    }

  }

  addAll360() {
    debugger
    if (this.RiderIsActive == false) {
      this.toaster.error("Please Enable the Rider first to Add AllCore360 Session.");
      //Kiran ---7 July --- this msg should not be shown in confimation msg--no need to take any input from user
      //const message = `Please Enable the Rider first to Add AllCore 360 Ride.`;
      //this.dialogService.confirm('Enable the Rider', message).subscribe(confirmed => {
      //});

    }
    else if (this.MachineAssigned == false) {

      this.rideService.SaveMachineWhenClinicHaveOneMachine(this.addAllCoreRiderId).subscribe((response: any) => {
        if (response != "0") {
          this.getRideInfo();

          this.route.navigate(['/rides/ride-recipe', this.addAllCoreRiderId, this.riderAdminUserId]);
        }
        else {
          this.toaster.error("Please assign machine to Rider first to Load Session.");
        }
      });

      //const message = `Please assign Machine to Rider first to Load Ride.`;
      //this.dialogService.confirm('Assign Machine To Rider', message).subscribe(confirmed => {
      //});
    }

    else {
      // window.open('https://google.com/about', '_blank');

      //if (location.protocol === 'https:') {
      //  window.location.href = location.href.replace('https', 'http');


      //}
      this.route.navigate(['/rides/ride-recipe', this.addAllCoreRiderId, this.riderAdminUserId]);
    }

  }
  addAll360bkkkk() {
    this.rideService.SaveMachineWhenClinicHaveOneMachine(this.addAllCoreRiderId).subscribe((response: any) => {
      if (response.StatusCode == '200') {
        this.getRideInfo();
        this.toaster.success("Machine Assingned to rider");
      }
    });
    if (this.RiderIsActive == false) {
      this.toaster.error("Please Enable the Rider first to Add AllCore360 Ride.");
      //Kiran ---7 July --- this msg should not be shown in confimation msg--no need to take any input from user
      //const message = `Please Enable the Rider first to Add AllCore 360 Ride.`;
      //this.dialogService.confirm('Enable the Rider', message).subscribe(confirmed => {
      //});

    }
    else if (this.MachineAssigned == false) {

      this.rideService.SaveMachineWhenClinicHaveOneMachine(this.addAllCoreRiderId).subscribe((response: any) => {
        if (response.statusCode == '200') {
          this.getRideInfo();
          this.route.navigate(['/rides/ride-recipe', this.addAllCoreRiderId, this.riderAdminUserId]);
        }
        else {
          this.toaster.error("Please assign Machine to Rider first to Load Ride.");
        }
      });
      //const message = `Please assign Machine to Rider first to Load Ride.`;
      //this.dialogService.confirm('Assign Machine To Rider', message).subscribe(confirmed => {
      //});
    }
    else {
      this.route.navigate(['/rides/ride-recipe', this.addAllCoreRiderId, this.riderAdminUserId]);
    }
  }
  playbutton(RiderMasterId) {
    if (this.RiderIsActive == false) {
      this.toaster.error("Please Enable the Rider first to Add AllCore360 Ride.");
      //const message = `Please Enable the Rider first to Add AllCore 360 Ride.`;
      //this.dialogService.confirm('Enable the Rider', message).subscribe(confirmed => {
      //});
    }
    else if (this.MachineAssigned == false) {
      this.toaster.error("Please assign Machine to Rider first to Load Ride.");

      //const message = `Please assign Machine to Rider first to Load Ride.`;
      //this.dialogService.confirm('Assign Machine To Rider', message).subscribe(confirmed => {
      // });
    }
    else {
      this.route.navigate(['/rides/ride-recipe', this.addAllCoreRiderId, RiderMasterId]);
    }
  }
  close() {
    if (this.activeRoute.routeConfig.path == 'ride-history/:id') {
      this.route.navigateByUrl("/rider");
    }
    else {
      this.route.navigateByUrl("/rides");
    }
  }

  getRidesHistoryGraph(graph: any) {
    let canvasname = "";
    var $this = this;
    //debugger
    if (graph == "1") {
      var canvasMonth = document.getElementById("canvasMonth");
      if (canvasMonth!=null) {
        canvasMonth.style.display = "";
      }
      var canvasQuarter = document.getElementById("canvasQuarter");
      if (canvasQuarter!=null) {
        canvasQuarter.style.display = "none";
      }
      var canvasYear = document.getElementById("canvasYear");
      if (canvasYear!=null) {
        canvasYear.style.display = "none";
      }
      var canvasDate = document.getElementById("canvasDate");
      if (canvasDate!=null) {
        canvasDate.style.display = "none";
      }
      canvasname = "canvasMonth";

    }
    else if (graph == "2") {
      var canvasMonth = document.getElementById("canvasMonth");
      if (canvasMonth!=null) {
        canvasMonth.style.display = "none";
      }
      var canvasQuarter = document.getElementById("canvasQuarter");
      if (canvasQuarter!=null) {
        canvasQuarter.style.display = "";
      }
      var canvasYear = document.getElementById("canvasYear");
      if (canvasYear!=null) {
        canvasYear.style.display = "none";
      }
      var canvasDate = document.getElementById("canvasDate");
      if (canvasDate!=null) {
        canvasDate.style.display = "none";
      }
      
      canvasname = "canvasQuarter";
    }
    else if (graph == "3") {
      var canvasMonth = document.getElementById("canvasMonth");
      if (canvasMonth!=null) {
        canvasMonth .style.display = "none";
      }
      var canvasQuarter = document.getElementById("canvasQuarter");
      if (canvasQuarter!=null) {
        canvasQuarter .style.display = "none";
      }
      var canvasYear = document.getElementById("canvasYear");
      if (canvasYear!=null) {
        canvasYear.style.display = "";
      }
      var canvasDate = document.getElementById("canvasDate");
      if (canvasDate!=null) {
        canvasDate.style.display = "none";
      }
     
      canvasname = "canvasYear";
    }
    this.show = false;
    if (this.CreatedOn != null) {
      this.CreatedOn = [];
      this.CoreScore = [];
      this.angle = [];

    }

    this.rideService.getRidesHistoryGraph(this.riderAdminUserId, graph, '1').subscribe((data: any) => {

      this.CreatedOn = [];
      this.CoreScore = [];
      this.angle = [];
      data.forEach(childObj => {
        this.CreatedOn.push(childObj.createdOn);
        this.CoreScore.push(childObj.coreScore);
        this.angle.push(childObj.angle);

      });
      //debugger
      let chart = new Chart(canvasname, {
        type: 'line',
        lineWidth: 10,

        data: {
          labels: this.CreatedOn,
          datasets: [
            {
              label: 'Core Score',
              data: this.CoreScore,
              borderColor: '#eb2127',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            },


          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          //maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            mode: 'x-axis',

            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 400,
            width: 300
          },
          scales: {

            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true,
                labelString: 'Core Score',

              },
              ticks: {
                //beginAtZero: true,
                stacked: true,
                fontColor: '#fff'
              }
            }],
            //x1Axes: [{

            //  lineThickness: 0,
            //  angleLines: {
            //    display: false
            //  },
            //  gridLines: {
            //    display: true
            //  },
            //  ticks: {
            //    //display: false,
            //    beginAtZero: true,
            //    fontColor: '#fff'
            //  }
            //}],
            //y1Axes: [{

            //  lineThickness: 0,
            //  angleLines: {
            //    display: false
            //  },
            //  gridLines: {
            //    display: true
            //  },
            //  ticks: {
            //    //display: false,
            //    stacked: true,
            //    fontColor: '#fff'
            //  }
            //}],
          }
        }
      });

    });

    this.getRidesHistoryGraphAngle(graph);

  }


  getRidesHistoryGraphAngle(graph: any) {
    let canvasname = "";
    this.showAngle = false;
    if (graph == "1") {
      var canvasMonthAngle = document.getElementById("canvasMonthAngle");
      if (canvasMonthAngle!=null) {
        canvasMonthAngle.style.display = "";
      }
      var canvasQuarterAngle = document.getElementById("canvasQuarterAngle");
      if (canvasQuarterAngle!=null) {
        canvasQuarterAngle.style.display = "none";
      }
      var canvasYearAngle = document.getElementById("canvasYearAngle");
      if (canvasYearAngle!=null) {
        canvasYearAngle.style.display = "none";
      }
      var canvasDateAngle = document.getElementById("canvasDateAngle");
      if (canvasDateAngle!=null) {
        canvasDateAngle.style.display = "none";
      }

      canvasname = "canvasMonthAngle";
    }
    else if (graph == "2") {
      var canvasMonthAngle = document.getElementById("canvasMonthAngle");
      if (canvasMonthAngle!=null) {
        canvasMonthAngle.style.display = "none";
      }
      var canvasQuarterAngle = document.getElementById("canvasQuarterAngle");
      if (canvasQuarterAngle!=null) {
        canvasQuarterAngle.style.display = "";
      }
      var canvasYearAngle = document.getElementById("canvasYearAngle");
      if (canvasYearAngle!=null) {
        canvasYearAngle.style.display = "none";
      }
      var canvasDateAngle = document.getElementById("canvasDateAngle");
      if (canvasDateAngle!=null) {
        canvasDateAngle.style.display = "none";
      }

      canvasname = "canvasQuarterAngle";
    }
    else if (graph == "3") {
      var canvasMonthAngle = document.getElementById("canvasMonthAngle");
      if (canvasMonthAngle!=null) {
        canvasMonthAngle.style.display = "none";
      }
      var canvasQuarterAngle = document.getElementById("canvasQuarterAngle");
      if (canvasQuarterAngle!=null) {
        canvasQuarterAngle.style.display = "none";
      }
      var canvasYearAngle = document.getElementById("canvasYearAngle");
      if (canvasYearAngle!=null) {
        canvasYearAngle.style.display = "";
      }
      var canvasDateAngle = document.getElementById("canvasDateAngle");
      if (canvasDateAngle!=null) {
        canvasDateAngle.style.display = "none";
      }

      canvasname = "canvasYearAngle";
    }
    this.show = false;
    //if (this.AnglechartWithCoreScore) {
    //  this.AnglechartWithCoreScore.destroy();
    //}
    if (this.CreatedOn != null) {
      this.CreatedOn = [];
      this.CoreScore = [];
      this.angle = [];

    }

    this.rideService.getRidesHistoryGraph(this.riderAdminUserId, graph, '1').subscribe((data: any) => {

      this.CreatedOn = [];
      this.CoreScore = [];
      this.angle = [];
      data.forEach(childObj => {
        this.CreatedOn.push(childObj.createdOn);
        this.CoreScore.push(childObj.coreScore);
        this.angle.push(childObj.angle);

      });
      //debugger
      let chart = new Chart(canvasname, {
        type: 'line',
        lineWidth: 10,

        data: {
          labels: this.CreatedOn,
          datasets: [
            {
              label: 'Average Angle',
              data: this.angle,
              borderColor: '#ffffff',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            },


          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            mode: 'x-axis',

            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 400,
            width: 300
          },
          scales: {

            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true,
                labelString: 'Average Angle',

              },
              ticks: {
                stacked: true,
                fontColor: '#fff'
              }
            }],
            //x1Axes: [{

            //  lineThickness: 0,
            //  angleLines: {
            //    display: false
            //  },
            //  gridLines: {
            //    display: true
            //  },
            //  ticks: {
            //    //display: false,
            //    beginAtZero: true,
            //    fontColor: '#fff'
            //  }
            //}],
            //y1Axes: [{

            //  lineThickness: 0,
            //  angleLines: {
            //    display: false
            //  },
            //  gridLines: {
            //    display: true
            //  },
            //  ticks: {
            //    //display: false,
            //    beginAtZero: true,
            //    fontColor: '#fff'
            //  }
            //}],
          }
        }
      });

    })
  }

  evaluationgetRidesHistoryGraph(graph: any) {
    //debugger
    let canvasname = "";
    if (graph == "1") {
      var evaluationcanvasMonth = document.getElementById("evaluationcanvasMonth");
      if (evaluationcanvasMonth!=null) {
        evaluationcanvasMonth.style.display = "";
      }
      var evaluationcanvasQuarter = document.getElementById("evaluationcanvasQuarter");
      if (evaluationcanvasQuarter!=null) {
        evaluationcanvasQuarter.style.display = "none";
      }
      var evaluationcanvasYear = document.getElementById("evaluationcanvasYear");
      if (evaluationcanvasYear!=null) {
        evaluationcanvasYear.style.display = "none";
      }
      var evaluationcanvasDate = document.getElementById("evaluationcanvasDate");
      if (evaluationcanvasDate!=null) {
        evaluationcanvasDate .style.display = "none";
      }
      canvasname = "evaluationcanvasMonth";
    }
    else if (graph == "2") {
      var evaluationcanvasMonth = document.getElementById("evaluationcanvasMonth");
      if (evaluationcanvasMonth!=null) {
        evaluationcanvasMonth.style.display = "none";
      }
      var evaluationcanvasQuarter = document.getElementById("evaluationcanvasQuarter");
      if (evaluationcanvasQuarter!=null) {
        evaluationcanvasQuarter.style.display = "";
      }
      var evaluationcanvasYear = document.getElementById("evaluationcanvasYear");
      if (evaluationcanvasYear!=null) {
        evaluationcanvasYear.style.display = "none";
      }
      var evaluationcanvasDate = document.getElementById("evaluationcanvasDate");
      if (evaluationcanvasDate!=null) {
        evaluationcanvasDate.style.display = "none";
      }
      canvasname = "evaluationcanvasQuarter";
    }
    else if (graph == "3") {
      var evaluationcanvasMonth = document.getElementById("evaluationcanvasMonth");
      if (evaluationcanvasMonth!=null) {
        evaluationcanvasMonth.style.display = "none";
      }
      var evaluationcanvasQuarter = document.getElementById("evaluationcanvasQuarter");
      if (evaluationcanvasQuarter!=null) {
        evaluationcanvasQuarter.style.display = "none";
      }
      var evaluationcanvasYear = document.getElementById("evaluationcanvasYear");
      if (evaluationcanvasYear!=null) {
        evaluationcanvasYear.style.display = "";
      }
      var evaluationcanvasDate = document.getElementById("evaluationcanvasDate");
      if (evaluationcanvasDate!=null) {
        evaluationcanvasDate.style.display = "none";
      }
      canvasname = "evaluationcanvasYear";
    }
    this.evaluationshow = false;
    if (this.CreatedOn != null) {
      this.CreatedOn = [];
      this.CoreScore = [];

    }
    debugger
    this.rideService.getRidesHistoryGraph(this.riderAdminUserId, graph, '2').subscribe((data: any) => {
      this.CreatedOn = [];
      this.CoreScore = [];
      console.log("CoreEvalutions", data)
      data.forEach(childObj => {
        if (childObj.coreScore != null) {
          this.CreatedOn.push(childObj.createdOn);
          this.CoreScore.push(childObj.coreScore);
        }


      });
      let chart = new Chart(canvasname, {
        type: 'line',
        lineWidth: 10,

        data: {
          labels: this.CreatedOn,
          datasets: [
            {
              label: 'Core Evaluation Score',
              data: this.CoreScore,
              borderColor: '#eb2127',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          //maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            //callbacks: {
            //  //labelColor: function (tooltipItem, chart) {
            //  //  return {
            //  //  // backgroundColor: 'red',
            //  //    padding:'50px'
            //  //  }
            //  //},
            //},
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 400,
            width: 300
          },
          scales: {

            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true,
                labelString: 'Core Evaluation Score',

              },
              ticks: {
                // beginAtZero: true,
                stacked: true,
                fontColor: '#fff'
              }
            }],
          }
        }
      });

    });


    this.ChangingEvalutionValue("", graph);
  }

  getRidesHistoryGraphDate(callFrom: any) {
    document.getElementById("canvasMonth").style.display = "none";
    document.getElementById("canvasQuarter").style.display = "none";
    document.getElementById("canvasYear").style.display = "none";
    document.getElementById("canvasDate").style.display = "";
    if (callFrom == "2") {
      if (this.Fromdate != undefined || this.Todate != undefined) {
      }
      else {
        this.toaster.error("Please select atleast one Date to search.");
        return;
      }
    }
    if (this.chartDateRange) {
      this.chartDateRange.destroy();
    }
    //if (this.Fromdate != undefined || this.Todate != undefined)
    //{
    //  if (this.Fromdate <= this.Todate) {
    if (this.CreatedOnDate != null) {
      this.CreatedOnDate = [];
    }
    if (this.CoreScoreDate != null) {
      this.CoreScoreDate = [];
      this.CoreScore = [];
      this.angle = [];
    }
    this.rideService.getRidesHistoryGraphDate(this.riderAdminUserId, this.Fromdate, this.Todate, '1')
      .subscribe((data: any) => {
        console.log("dataDate", data);
        data.forEach(childObj => {
          if ((childObj.coreScore != '' || childObj.angle != '') && childObj.createdOn != '') {
            this.CreatedOnDate.push(childObj.createdOn);
            this.CoreScore.push(childObj.coreScore);
            this.angle.push(childObj.angle);
          }

        });



        let chart = new Chart('canvasDate', {
          type: 'line',
          lineWidth: 10,

          data: {
            labels: this.CreatedOnDate,
            datasets: [
              {
                label: 'Core Score',
                data: this.CoreScore,
                borderColor: '#eb2127',
                borderWidth: 3,
                fill: false,
                lineTension: 0
              }
            ]
          },
          options: {
            responsive: false,

            chartArea: {
              backgroundColor: 'rgba(251, 85, 85, 0.4)'
            },
            //maintainAspectRatio: false,
            tooltips: {
              yAlign: 'top',
              mode: 'x-axis',
              //callbacks: {
              //  //labelColor: function (tooltipItem, chart) {
              //  //  return {
              //  //  // backgroundColor: 'red',
              //  //    padding:'50px'
              //  //  }
              //  //},
              //},
              backgroundColor: '#eb2127',
              titleFontSize: 15,
              bodyFontSize: 17,
              displayColors: false
            },
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                fontColor: '#fff'
              }
            },
            size: {
              height: 400,
              width: 300
            },
            scales: {

              xAxes: [{
                gridLines: {
                  display: false
                },
                ticks: {
                  display: true,
                  fontColor: '#fff'
                }
              }],
              yAxes: [{
                gridLines: {
                  display: true,
                  labelString: 'Core Score',

                },
                ticks: {
                  stacked: true,
                  fontColor: '#fff'
                }
              }],
              //x1Axes: [{

              //  lineThickness: 0,
              //  angleLines: {
              //    display: false
              //  },
              //  gridLines: {
              //    display: true
              //  },
              //  ticks: {
              //    //display: false,
              //    beginAtZero: true,
              //    fontColor: '#fff'
              //  }
              //}],
              //y1Axes: [{

              //  lineThickness: 0,
              //  angleLines: {
              //    display: false
              //  },
              //  gridLines: {
              //    display: true
              //  },
              //  ticks: {
              //    //display: false,
              //    stacked: true,
              //    fontColor: '#fff'
              //  }
              //}],
            }
          }
        });
      });

    this.getRidesHistoryGraphDateAngle(callFrom);
  }

  getRidesHistoryGraphDateAngle(callFrom: any) {
    document.getElementById("canvasMonthAngle").style.display = "none";
    document.getElementById("canvasQuarterAngle").style.display = "none";
    document.getElementById("canvasYearAngle").style.display = "none";
    document.getElementById("canvasDateAngle").style.display = "";
    if (callFrom == "2") {
      if (this.Fromdate != undefined || this.Todate != undefined) {
      }
      else {
        this.toaster.error("Please select atleast one Date to search.");
        return;
      }
    }
    if (this.chartCoreScoreAngle) {
      this.chartCoreScoreAngle.destroy();
    }
    //if (this.Fromdate != undefined || this.Todate != undefined)
    //{
    //  if (this.Fromdate <= this.Todate) {
    if (this.CreatedOnDateAngle != null) {
      this.CreatedOnDateAngle = [];
    }
    if (this.CoreScoreDate != null) {
      this.CreatedOnDateAngle = [];
      this.CoreScore = [];
      this.angle = [];
    }
    this.rideService.getRidesHistoryGraphDate(this.riderAdminUserId, this.Fromdate, this.Todate, '1')
      .subscribe((data: any) => {
        console.log("dataDate", data);
        data.forEach(childObj => {
          if (childObj.coreScore != '' || childObj.angle != '') {
            this.CreatedOnDateAngle.push(childObj.createdOn);
            this.CoreScore.push(childObj.coreScore);
            this.angle.push(childObj.angle);
          }

        });



        this.chartCoreScoreAngle = new Chart('canvasDateAngle', {
          type: 'line',
          lineWidth: 10,

          data: {
            labels: this.CreatedOnDateAngle,
            datasets: [
              {
                label: 'Angle',
                data: this.angle,
                borderColor: '#ffffff',
                borderWidth: 3,
                fill: false,
                lineTension: 0
              }


            ]
          },
          options: {
            responsive: true,
            chartArea: {
              backgroundColor: 'rgba(251, 85, 85, 0.4)'
            },
            maintainAspectRatio: false,
            tooltips: {
              yAlign: 'top',
              mode: 'x-axis',
              //callbacks: {
              //  //labelColor: function (tooltipItem, chart) {
              //  //  return {
              //  //  // backgroundColor: 'red',
              //  //    padding:'50px'
              //  //  }
              //  //},
              //},
              backgroundColor: '#eb2127',
              titleFontSize: 15,
              bodyFontSize: 17,
              displayColors: false
            },
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                fontColor: '#fff'
              }
            },
            size: {
              height: this.angleGraphHeight,
              width: this.angleGraphWidth
            },
            scales: {

              xAxes: [{
                gridLines: {
                  display: false
                },
                ticks: {
                  display: true,
                  fontColor: '#fff'
                }
              }],
              yAxes: [{
                gridLines: {
                  display: true,
                  labelString: 'Core Score',

                },
                ticks: {
                  stacked: true,
                  fontColor: '#fff'
                }
              }],
              //x1Axes: [{

              //  lineThickness: 0,
              //  angleLines: {
              //    display: false
              //  },
              //  gridLines: {
              //    display: true
              //  },
              //  ticks: {
              //    //display: false,
              //    beginAtZero: true,
              //    fontColor: '#fff'
              //  }
              //}],
              //y1Axes: [{

              //  lineThickness: 0,
              //  angleLines: {
              //    display: false
              //  },
              //  gridLines: {
              //    display: true
              //  },
              //  ticks: {
              //    //display: false,
              //    beginAtZero: true,
              //    fontColor: '#fff'
              //  }
              //}],
            }
          }
        });




      });

    var chartEl = document.getElementById("canvasDateAngle");
  }

  evaluationgetRidesHistoryGraphDate(callFrom: any) {
    // debugger
    document.getElementById("evaluationcanvasMonth").style.display = "none";
    document.getElementById("evaluationcanvasQuarter").style.display = "none";
    document.getElementById("evaluationcanvasYear").style.display = "none";
    document.getElementById("evaluationcanvasDate").style.display = "";
    if (callFrom == "2") {
      if (this.EvaluationFromdate != undefined || this.EvaluationTodate != undefined) {
      }
      else {
        this.toaster.error("Please select atleast one Date to search.");
        return;
      }
    }
    if (this.evaluationchartDateRange) {
      this.evaluationchartDateRange.destroy();
    }
    //if (this.Fromdate != undefined || this.Todate != undefined)
    //{
    //  if (this.Fromdate <= this.Todate) {
    if (this.CreatedOnDate != null) {
      this.CreatedOnDate = [];
    }
    if (this.CoreScoreDate != null) {
      this.CoreScoreDate = [];
    }
    this.rideService.getRidesHistoryGraphDate(this.riderAdminUserId, this.EvaluationFromdate, this.EvaluationTodate, '2').subscribe((data: any) => {
      data.forEach(childObj => {
        this.CreatedOnDate.push(childObj.createdOn);
        this.CoreScoreDate.push(childObj.coreScore);
      });

      this.evaluationchartDateRange = new Chart('evaluationcanvasDate', {
        type: 'line',
        lineWidth: 5,
        data: {
          labels: this.CreatedOnDate,
          datasets: [
            {
              data: this.CoreScoreDate,
              borderColor: '#eb2127',
              label: 'Core Evaluation Score',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 400,
            width: 200
          },
          scales: {

            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true,
                labelString: 'Core Evaluation Score'
              },
              ticks: {
                //beginAtZero: true,
                stacked: true,
                fontColor: '#fff'
              }
            }],
          }
        }
      });

    });

    this.GetRiderDashboardGraphsForEvalution(this.riderAdminUserId, "4", this.EvaluationFromdate, this.EvaluationTodate);
    this.GetRiderDashboardGraphsForQuadrant(this.riderAdminUserId, "4", this.EvaluationFromdate, this.EvaluationTodate);
  }
  showcalender() {
    this.show = true;
    document.getElementById("canvasMonth").style.display = "none";
    document.getElementById("canvasQuarter").style.display = "none";
    document.getElementById("canvasYear").style.display = "none";
    document.getElementById("canvasDate").style.display = "";
    this.getRidesHistoryGraphDate('1');
    //this.getRidesHistoryGraphDateAngle('1');
  }

  showcalenderAngle() {
    this.showAngle = true;
    document.getElementById("canvasMonthAngle").style.display = "none";
    document.getElementById("canvasQuarterAngle").style.display = "none";
    document.getElementById("canvasYearAngle").style.display = "none";
    document.getElementById("canvasDateAngle").style.display = "";
    this.getRidesHistoryGraphDateAngle('1');
  }
  evaluationshowcalender() {
    this.evaluationshow = true;
    document.getElementById("evaluationcanvasMonth").style.display = "none";
    document.getElementById("evaluationcanvasQuarter").style.display = "none";
    document.getElementById("evaluationcanvasYear").style.display = "none";
    document.getElementById("evaluationcanvasDate").style.display = "";
    this.evaluationgetRidesHistoryGraphDate('1');
  }
  //Rider Dashboard Graphs

  GetRiderDashboardGraphs(UserId: any, duration: any) {

    this.rideService.GetRiderDashboardGraphs(UserId, duration, 1, '', '').subscribe((data: any) => {
      //debugger;
      console.log("canvas1", data);

      this.CreatedOn1 = [];
      this.PreBPGraph = [];
      this.BPGraph = [];
      this.WeightGraph = [];
      this.BMIGraph = [];

      data.forEach(childObj => {
        if (childObj.bmi != '') {
          this.BMIGraph.push(childObj.bmi);
        } if (childObj.weight != '') {
          this.WeightGraph.push(childObj.weight);
          //this.CreatedOn1.push(childObj.createdOn);
        }
        if (childObj.postRideSBP != '') {
          this.BPGraph.push(childObj.postRideSBP);
          //this.CreatedOn1.push(childObj.createdOn);
        }
        if (childObj.postRideDBP != '') {
          this.PreBPGraph.push(childObj.postRideDBP);
        }
        if (childObj.BMIGraph != '') {
          this.PreBPGraph.push(childObj.BMIGraph);
        }
        this.CreatedOn1.push(childObj.createdOn);


      });
      let chart = new Chart('canvas1', {

        type: 'line',
        data: {
          labels: this.CreatedOn1,
          datasets: [
            {
              data: this.BMIGraph,
              borderColor: '#eb2127',
              // borderColor: '#78b942',
              label: 'BMI',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          // maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            // backgroundColor: '#78b942',
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 500,
            width: 600
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true

              },
              ticks: {
                beginAtZero: true,
                fontColor: '#fff'
              }

            }],
          }
        },

      });



      let chart1 = new Chart('canvas2', {
        type: 'line',
        data: {
          labels: this.CreatedOn1,
          datasets: [
            {
              data: this.BPGraph,
              borderColor: '#b9b9b9',
              label: 'Post Session SBP(mm-hg)',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            },
            {
              data: this.PreBPGraph,
              borderColor: '#eb2127',
              label: 'Post Session DBP(mm-hg)',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },

        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          //  maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 500,
            width: 600
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              lineThickness: 0,
              angleLines: {
                display: false
              },
              gridLines: {
                display: true
              },
              ticks: {
                //display: false,
                beginAtZero: true,
                fontColor: '#fff'
              }
            }],
          }
        }
        //showDefaultText(chart1, "");
      });

      //Weight Graph
      //  if (this.WeightGraph.length != 0) {

      let chart2 = new Chart('canvas3', {
        type: 'line',
        data: {
          labels: this.CreatedOn1,
          type: "line",
          datasets: [
            {
              data: this.WeightGraph,
              borderColor: '#eb2127',
              label: 'Weight(lbs)',
              type: 'line',
              markerType: "line", borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          //  maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            usePointStyle: true,

            labels: {
              fontColor: '#fff',
              useLineStyle: true,
              markerType: "line",
            }
          },
          size: {
            height: 500,
            width: 600
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                beginAtZero: true,
                fontColor: '#fff'
              }
            }],
          }
        }
      });
    })
  }

  GetRiderDashboardGraphsForEvalution(UserId: any, duration: any, EvaluationFromdate: any, EvaluationTodate: any) {
    this.Evalutionvalues = duration;
    this.rideService.GetRiderDashboardGraphs(UserId, duration, 2, EvaluationFromdate, EvaluationTodate).subscribe((data: any) => {
      this.CreatedOn1Evalution = [];
      this.PreBPGraphEvalution = [];
      this.BPGraphEvalution = [];
      this.WeightGraphEvalution = [];
      this.RestingHeartRate = [];
      this.BMIGraphEvalution = [];
      this.EvalutionWeightDate = [];
      this.RestingHeartRateDate = [];
      this.BMIDate = [];
      data.forEach(childObj => {
        if (childObj.bmi != '') {
          this.BMIGraphEvalution.push(childObj.bmi);
          this.BMIDate.push(childObj.createdOn);
        } if (childObj.weight != '') {
          this.WeightGraphEvalution.push(childObj.weight);
          this.EvalutionWeightDate.push(childObj.createdOn);;
        }
        if (childObj.postRideSBP != '') {
          this.BPGraphEvalution.push(childObj.postRideSBP);

        }
        if (childObj.postRideDBP != '') {
          this.PreBPGraphEvalution.push(childObj.postRideDBP);
          //this.CreatedOn1Evalution.push(childObj.createdOn);
        }
        if (childObj.restingHeartRate != '') {
          this.RestingHeartRate.push(childObj.restingHeartRate);
          this.RestingHeartRateDate.push(childObj.createdOn);
        }
        this.CreatedOn1Evalution.push(childObj.createdOn);


      });

      let chart = new Chart('canvasEvalution1', {
        type: 'line',
        data: {
          labels: this.BMIDate,
          datasets: [
            {
              data: this.BMIGraphEvalution,
              borderColor: '#eb2127',
              // borderColor: '#78b942',
              label: 'BMI',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          // maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            // backgroundColor: '#78b942',
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 500,
            width: 600
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true

              },
              ticks: {
                //beginAtZero: true,
                stacked: true,
                fontColor: '#fff'
                //callback: function (label, index, labels) {
                //  return label+1 ;
                //}
              }

            }],
          }
        },

      });



      let chart1 = new Chart('canvasEvalution2', {
        type: 'line',
        data: {
          labels: this.CreatedOn1Evalution,

          datasets: [
            {
              data: this.BPGraphEvalution,
              borderColor: '#b9b9b9',
              label: 'Post Session SBP(mm-hg)',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            },
            {
              data: this.PreBPGraphEvalution,
              borderColor: '#eb2127',
              label: 'Post Session DBP(mm-hg)',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },

        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          //  maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 500,
            width: 600
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{

              lineThickness: 0,
              angleLines: {
                display: false
              },
              gridLines: {
                display: true
              },
              ticks: {
                //display: false,
                //beginAtZero: true,
                stacked: true,
                fontColor: '#fff',
              }
            }],
          }
        }
        //showDefaultText(chart1, "");
      });

      //Weight Graph
      //  if (this.WeightGraph.length != 0) {

      let chart2 = new Chart('canvasEvalution3', {
        type: 'line',
        data: {
          labels: this.EvalutionWeightDate,
          type: "line",
          datasets: [
            {
              data: this.WeightGraphEvalution,
              borderColor: '#eb2127',
              label: 'Weight(lbs)',
              type: 'line',
              markerType: "line", borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          //  maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            usePointStyle: true,

            labels: {
              fontColor: '#fff',
              useLineStyle: true,
              markerType: "line",
            }
          },
          size: {
            height: 500,
            width: 600
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                //beginAtZero: true,
                stacked: true,
                fontColor: '#fff'
              }
            }],
          }
        }
      });


      let chart4 = new Chart('canvasEvalution4', {
        type: 'line',
        data: {
          labels: this.RestingHeartRateDate,
          type: "line",
          datasets: [
            {
              data: this.RestingHeartRate,
              borderColor: '#eb2127',
              label: 'Resting Heart Rate',
              type: 'line',
              markerType: "line", borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },
          //  maintainAspectRatio: false,
          tooltips: {
            yAlign: 'top',
            backgroundColor: '#eb2127',
            titleFontSize: 15,
            bodyFontSize: 17,
            displayColors: false
          },
          legend: {
            display: true,
            position: 'bottom',
            usePointStyle: true,

            labels: {
              fontColor: '#fff',
              useLineStyle: true,
              markerType: "line",
            }
          },
          size: {
            height: 500,
            width: 600
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                //beginAtZero: true,
                stacked: true,

                fontColor: '#fff'
              }
            }],
          }
        }
      });
    })
  }

  GetRiderDashboardGraphsForQuadrant(UserId: any, duration: any, EvaluationFromdate: any, EvaluationTodate: any) {
    this.Evalutionvalues = duration;
    let graph = this;
    // debugger
    //var chart = document.getElementById("EvalutionTimeByQuadrant");
    var canvas: any = document.getElementById("EvalutionTimeByQuadrant");
    //if (document.getElementById('EvalutionTimeByQuadrant')) {

    //  document.getElementById('EvalutionTimeByQuadrant').remove();
    //  alert(1);
    //}
    if (this.myChartQuadrant) {
      //debugger
      this.myChartQuadrant.destroy();
      var canvas: any = document.getElementById("evalutionTimeByQuadrant");


    }
    this.rideService.GetRiderDashboardGraphsForQuadrant(UserId, duration, 2, EvaluationFromdate, EvaluationTodate).subscribe((data: any) => {
      this.CreatedOnQuadrant = [];
      this.Anterior = [];
      this.Posterior = [];
      this.RightLateral = [];
      this.LeftLateral = [];
      this.ForDataTableUsed = [];
      //debugger
      console.log("dataQuadarant", data);
      data.forEach(childObj => {
        if ((childObj.angles != '' && childObj.angles != null) && childObj.angles != 0) {
          if (childObj.spin == '1') {
            this.Anterior.push(childObj.angles);

          }

        }
        if ((childObj.angles != '' && childObj.angles != null) && childObj.angles != 0) {
          if (childObj.spin == '2') {
            this.Posterior.push(childObj.angles);
          }
          //this.CreatedOn1.push(childObj.createdOn);
        }
        if ((childObj.angles != '' && childObj.angles != null) && childObj.angles != 0) {
          if (childObj.spin == '3') {
            this.RightLateral.push(childObj.angles);
          }
          //this.CreatedOn1.push(childObj.createdOn);
        }
        if ((childObj.angles != '' && childObj.angles != null) && childObj.angles != 0) {
          if (childObj.spin == '4') {
            this.LeftLateral.push(childObj.angles);
            this.CreatedOnQuadrant.push(childObj.createdOn);
          }
        }



      });
      //debugger

      //debugger

      console.log("DataTable", this.ForDataTableUsed);

      this.myChartQuadrant = new Chart('evalutionTimeByQuadrant', {
        type: 'line',

        data: {
          labels: this.CreatedOnQuadrant,
          datasets: [
            {
              data: this.Anterior,
              borderColor: '#eb2127',
              label: 'Anterior',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            },
            {
              data: this.Posterior,
              borderColor: '#000',
              label: 'Posterior',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            },
            {
              data: this.RightLateral,
              borderColor: '#3f3938',
              label: 'Right Lateral',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            },
            {
              data: this.LeftLateral,
              borderColor: '#ffffff',
              label: 'Left Lateral',
              borderWidth: 3,
              fill: false,
              lineTension: 0
            }
          ]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          tension: 0,

          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)'
          },


          tooltips: {
            //yAlign: 'bottom',
            backgroundColor: '#eb2127',
            titleFontSize: 20,

            bodyFontSize: 20,
            displayColors: false,
            mode: 'x-axis'

          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          size: {
            height: 500,
            width: 600
          },
          scales: {

            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                //stacked: true,
                fontColor: '#fff'
              }
            }],
            yAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                //beginAtZero: true,
                stacked: true,

                fontColor: '#fff'
              }
            }],
            x1Axes: [{

              lineThickness: 0,
              angleLines: {
                display: false
              },
              gridLines: {
                display: true
              },
              ticks: {
                //display: false,
                // beginAtZero: false,
                stacked: true,
                fontColor: '#fff'
              }
            }],
            y1Axes: [{

              lineThickness: 0,
              angleLines: {
                display: false
              },
              gridLines: {
                display: true
              },
              ticks: {
                display: false,
                //beginAtZero: false,
                stacked: true,
                fontColor: '#fff'
              }
            }],
          }
        }

      });

    })

    console.log("EvalutionTimeByQuadrantchart", this.EvalutionTimeByQuadrantchart);
  }

  restEvalutionddl() {
    //debugger
    this.Evalutionvalues = 7;
  }

  ChangingValue(event) {
    if (this.BMIGraph != null) {
      this.BMIGraph = [];
      this.BPGraph = [];
      this.WeightGraph = [];
      this.CreatedOn1 = [];

    }
    this.BMIGraph = [];
    this.BPGraph = [];
    this.WeightGraph = [];
    this.CreatedOn1 = [];
    var duration = 7;
    if (event) {
      duration = event.value;
    }
    this.GetRiderDashboardGraphs(this.riderAdminUserId, duration);
  }

  ChangingEvalutionValue(event, duration) {
    this.loadSave = true;
    //document.getElementById("EvalutionTimeByQuadrant").destroy();
    if (this.BMIGraphEvalution != null) {
      this.BMIGraphEvalution = [];
      this.BPGraphEvalution = [];
      this.WeightGraphEvalution = [];
      this.CreatedOn1Evalution = [];

    }
    this.BMIGraphEvalution = [];
    this.BPGraphEvalution = [];
    this.WeightGraphEvalution = [];
    this.CreatedOn1Evalution = [];

    this.CreatedOnQuadrant = [];
    this.Anterior = [];
    this.Posterior = [];
    this.RightLateral = [];
    this.LeftLateral = [];
    this.ForDataTableUsed = [];
    //var duration = 7;
    if (event) {
      duration = event.value;
    }
    this.GetRiderDashboardGraphsForEvalution(this.riderAdminUserId, duration, '', '');
    this.GetRiderDashboardGraphsForQuadrant(this.riderAdminUserId, duration, '', '');
    this.loadSave = false;
  }

  showDefaultText(chart, text) {
    var dataPoints = chart.options.data[0].dataPoints;
    var isEmpty = !(dataPoints && dataPoints.length > 0);

    if (!isEmpty) {
      for (var i = 0; i < dataPoints.length; i++) {
        isEmpty = !dataPoints[i].y;
        if (!isEmpty)
          break;
      }
    }

    if (!chart.options.subtitles)
      chart.options.subtitles = [];
    if (isEmpty)
      chart.options.subtitles.push({
        text: text,
        verticalAlign: 'center',
      });
    else
      chart.options.subtitles = [];
  }
  restddl() {
    // this.status = -1;
  }
  MonthDusraion = [
    { value: 7, name: 'Last 7 Days' },
    { value: 30, name: '1 Month' },
    { value: 60, name: '2 Month' }

  ];



  getRideInfoEvaluation() {
    this.loading = true;
    if (this.riderAdminUserId != null) {
      this.rideService.getRideInfoList(this.sortColumnEvaluation, this.sortDirEvaluation, 0, this.pageSizeValueEvaluation, this.loginuserid, this.riderAdminUserId, 2).subscribe(response => {
        this.paged = this.rideService.pagedData;
        //////////// localStorage.removeItem('MachineAssignedName');
        console.log("response12333##", this.paged);
        if (this.paged.data != null && this.paged.data.length > 0) {

          if (this.paged.data[0].Id != null) {
            this.pagedDataEvaluation = this.rideService.pagedData;
            // this.TotalRecord = this.pagedData.data[0].TotalRecord;
            this.FirstName = this.paged.data[0].FirstName;
            this.LastName = this.paged.data[0].LastName;
            this.dob = this.paged.data[0].DOB;
            this.gender = this.paged.data[0].Gender;
            this.riderNumber = this.paged.data[0].RideId;
            // this.MonthlyCount = this.paged.data[0].MonthlyCount;
            // this.YearlyCount = this.paged.data[0].YearlyCount;
            this.addAllCoreRiderId = this.paged.data[0].RId;
            //   this.QuarterlyCount = this.paged.data[0].QuarterlyCount;
            this.personalBestEvaluation = this.paged.data[0].PersonalBestD;
            this.MachineAssigned = this.paged.data[0].isMachineAssigned;
            this.RiderIsActive = this.paged.data[0].Active;
            this.MachineAssignedName = this.paged.data[0].MachineName;
            this.CurrentAssignedMachine = this.paged.data[0].CurrentAssignedMachine;
          }
          else {
            this.FirstName = this.paged.data[0].FirstName;
            this.LastName = this.paged.data[0].LastName;
            this.dob = this.paged.data[0].DOB;
            this.gender = this.paged.data[0].Gender;
            this.riderNumber = this.paged.data[0].RideId;
            ////////  this.TotalRecord = 0;
            ///////// this.MonthlyCount = this.paged.data[0].MonthlyCount;
            /////////  this.YearlyCount = this.paged.data[0].YearlyCount;
            this.addAllCoreRiderId = this.paged.data[0].RId;
            /////////  this.QuarterlyCount = this.paged.data[0].QuarterlyCount;
            this.personalBestEvaluation = this.paged.data[0].PersonalBestD;
            this.MachineAssigned = this.paged.data[0].isMachineAssigned;
            this.RiderIsActive = this.paged.data[0].Active;
            this.MachineAssignedName = this.paged.data[0].MachineName;
            this.CurrentAssignedMachine = this.paged.data[0].CurrentAssignedMachine;
          }
        }

        if (this.MachineAssignedName == null || this.MachineAssignedName == 'undefined') {
          this.MachineAssignedName = 'N/A';
        }
        if (this.CurrentAssignedMachine == null || this.CurrentAssignedMachine == 'undefined') {
          this.CurrentAssignedMachine = 'N/A';
        }

        if (this.paged.data.length > 0) {
          if (this.paged.data[0].Id != null) {

          }
          else {
            this.pagedDataEvaluation = this.rideService.pagedData;
            this.pagedDataEvaluation.data = null;
            this.pagedDataEvaluation.pager.totalItems = 0;
          }
        }
        else {
          this.pagedDataEvaluation = this.rideService.pagedData;
          this.pagedDataEvaluation.data = null;
          this.pagedDataEvaluation.pager.totalItems = 0;
        }
        ////////// localStorage.setItem('MachineAssignedName', this.MachineAssignedName);
        this.loading = false;
      }, error => {
        this.loading = false;
      })
    }
  }

  getRideInfoEvaluation111() {
    this.loading = true;
    if (this.riderAdminUserId != null) {
      this.rideService.getRideInfoList(this.sortColumnEvaluation, this.sortDirEvaluation, 0, this.pageSizeValueEvaluation, this.loginuserid, this.riderAdminUserId, 2).subscribe(response => {
        this.paged = this.rideService.pagedData;
        //////////// localStorage.removeItem('MachineAssignedName');
        console.log("response12333##", this.paged);
        if (this.paged.data != null && this.paged.data.length > 0) {

          if (this.paged.data[0].Id != null) {
            this.pagedDataEvaluation = this.rideService.pagedData;
            // this.TotalRecord = this.pagedData.data[0].TotalRecord;
            //this.FirstName = this.paged.data[0].FirstName;
            //this.LastName = this.paged.data[0].LastName;
            //this.dob = this.paged.data[0].DOB;
            //this.gender = this.paged.data[0].Gender;
            //this.riderNumber = this.paged.data[0].RideId;
            // this.MonthlyCount = this.paged.data[0].MonthlyCount;
            // this.YearlyCount = this.paged.data[0].YearlyCount;
            this.addAllCoreRiderId = this.paged.data[0].RId;
            //   this.QuarterlyCount = this.paged.data[0].QuarterlyCount;
            this.personalBestEvaluation = this.paged.data[0].PersonalBestD;
            // this.MachineAssigned = this.paged.data[0].isMachineAssigned;
            //this.RiderIsActive = this.paged.data[0].Active;
            //this.MachineAssignedName = this.paged.data[0].MachineName;
          }
          else {
            //this.FirstName = this.paged.data[0].FirstName;
            //this.LastName = this.paged.data[0].LastName;
            //this.dob = this.paged.data[0].DOB;
            //this.gender = this.paged.data[0].Gender;
            //this.riderNumber = this.paged.data[0].RideId;
            ////////  this.TotalRecord = 0;
            ///////// this.MonthlyCount = this.paged.data[0].MonthlyCount;
            /////////  this.YearlyCount = this.paged.data[0].YearlyCount;
            this.addAllCoreRiderId = this.paged.data[0].RId;
            /////////  this.QuarterlyCount = this.paged.data[0].QuarterlyCount;
            this.personalBestEvaluation = this.paged.data[0].PersonalBestD;
            //this.MachineAssigned = this.paged.data[0].isMachineAssigned;
            //this.RiderIsActive = this.paged.data[0].Active;
            //this.MachineAssignedName = this.paged.data[0].MachineName;
          }
        }

        if (this.MachineAssignedName == null || this.MachineAssignedName == 'undefined') {
          this.MachineAssignedName = 'N/A';
        } if (this.CurrentAssignedMachine == null || this.CurrentAssignedMachine == 'undefined') {
          this.CurrentAssignedMachine = 'N/A';
        }

        ////////// localStorage.setItem('MachineAssignedName', this.MachineAssignedName);
        this.loading = false;
      }, error => {
        this.loading = false;
      })
    }
  }

  onChangeEvaluation($event) {
    this.loading = true;
    this.rideService.getRideInfoList(this.sortColumnEvaluation, this.sortDirEvaluation, 0, this.pageSizeValueEvaluation, this.loginuserid, this.riderAdminUserId, 2).subscribe(response => {
      this.pagedDataEvaluation = this.rideService.pagedData;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  setPageEvaluation($event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);

    this.loading = true;
    this.rideService.getRideInfoList(this.sortColumnEvaluation, this.sortDirEvaluation, $event.page - 1, this.pageSizeValueEvaluation, this.loginuserid, this.riderAdminUserId, 2).subscribe(response => {
      this.pagedDataEvaluation = this.rideService.pagedData;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  onSortEvaluation($event) {
    const sort = $event.sorts[0]
    this.sortDirEvaluation = sort.dir;
    this.sortColumnEvaluation = sort.prop;
    this.loading = true;
    this.rideService.getRideInfoList(this.sortColumnEvaluation, this.sortDirEvaluation, $event.page - 1, this.pageSizeValueEvaluation, this.loginuserid, this.riderAdminUserId, 2).subscribe(response => {
      this.pagedDataEvaluation = this.rideService.pagedData;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  toggleExpandRowEvaluation(row, expanded) {
    this.tableEvaluation.rowDetail.collapseAllRows();
    if ( this.table !== undefined ) {
      this.table.rowDetail.collapseAllRows();
    }
    if (expanded == false) {
      this.tableEvaluation.rowDetail.toggleExpandRow(row);
    }
    else {
      this.tableEvaluation.rowDetail.collapseAllRows();
    }
  }
  toggleExpandRow(row, expanded) {
    this.table.rowDetail.collapseAllRows();
    if (this.tableEvaluation !== undefined ) {
      this.tableEvaluation.rowDetail.collapseAllRows();
    }
    if (expanded == false) {
      this.table.rowDetail.toggleExpandRow(row);
    }
    else {
      this.table.rowDetail.collapseAllRows();
    }
  }

  DisplayCorescoreAndAngle(event) {
    if (this.IsCoreScore == true && event.target.value) {
      var divCore = document.getElementById("CoreScore_Show");
      divCore.style.display = "block"
      var div = document.getElementById("AverageAngle_Show");
      div.style.display = "none";
      this.IsCoreScore = true;
      this.IsAverageAngle = false;
    }
    else {
      var divCore = document.getElementById("CoreScore_Show");
      divCore.style.display = "none"
      var div = document.getElementById("AverageAngle_Show");
      div.style.display = "block";
      this.IsCoreScore = false;
      this.IsAverageAngle = true;
    }


  }

  DisplayIsAverageAngle(event) {
    //debugger

    var divCore = document.getElementById("CoreScore_Show");
    divCore.style.display = "none"
    var div = document.getElementById("AverageAngle_Show");
    div.style.display = "block";
    this.IsCoreScore = false;
    this.IsAverageAngle = true;
  }
  ShowNotesPopup() {
    this.route.navigate(['rides/notes', this.addAllCoreRiderId, this.riderAdminUserId,'']);
  }

  SocialMedia(row,data) {
    this.ridertemplateData = row;
    console.log("ridertemplateData", this.ridertemplateData);
    this.TimeForSession=0
    this.changePassModal.show();
  }
  closepopup() {
    this.changePassModal.hide();
  }

  ClickSharefacebook() {

    this.tabledata = window.document.documentElement.innerHTML;
    //this.PreparedshareUrl();
    this.convertImage();

  }
  convertImage() {
    try {
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.finalOne = canvas.toDataURL();
        //Base64StringIntoUrl
        var dataFinal = this.finalOne.split("base64,");
        console.log("dataFinal", dataFinal);
        debugger
        var data = {
          Id: dataFinal[1]
        }
        this.rideService.getImageUrl(data).subscribe((result: any) => {
          if (result == null || result == '')
            result = 'https://stage.allcore360.com/assets/images/logo_White.png';
          if (result) {
            setTimeout(() => {
              var link = document.createElement('a');
              link.href = `https://www.facebook.com/sharer.php?u=${result}`,

                link.target = '_blank';
              link.textContent = 'Open in New Window';
              link.click();
            }, 100);
          }
        })
      });
    }
    catch (err) {
      console.log("err", err.message);
    }
  }
  shareOnInstagram() {
    // Replace 'url_to_share' with the URL you want to share
    let url = encodeURIComponent(('https://portal.fraxioned.com/assets/asset_638324605773712387.jpg'))
    const urlToShare = encodeURIComponent(url);

    const instagramShareUrl = `https://www.instagram.com/share?url=${urlToShare}`;

    // Open Instagram in a new window
    window.open(instagramShareUrl, '_blank');
  }
  ClickToTutorDoc() {
    try {
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.finalOne = canvas.toDataURL();
        //Base64StringIntoUrl
        var dataFinal = this.finalOne.split("base64,");
        console.log("dataFinal", dataFinal);
        debugger
        var data = {
          Id: dataFinal[1]
        }
        this.rideService.getImageUrl(data).subscribe((result: any) => {
          if (result) {
            //var finalUrl = result.replace("\","//")
            setTimeout(() => {
              var link = document.createElement('a');
              link.href = `https://twitter.com/intent/tweet?url=${result}`,
                //link: `https://twitter.com/intent/tweet?url=${this.shareData?.url}&text=${this.shareData?.description}&
                link.target = '_blank';
              link.textContent = 'Open in New Window';
              link.click();
            }, 100);
          }
        })
      });
    }
    catch (err) {
      console.log("err", err.message);
    }
  }
  ClickToInstaGramDoc() {
    try {
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.finalOne = canvas.toDataURL();
        //Base64StringIntoUrl
        var dataFinal = this.finalOne.split("base64,");
        console.log("dataFinal", dataFinal);
        debugger
        var data = {
          Id: dataFinal[1]
        }
        this.rideService.getImageUrl(data).subscribe((result: any) => {
          if (result) {
            //var finalUrl = result.replace("\","//")
            setTimeout(() => {
              let url = encodeURIComponent(('https://portal.fraxioned.com/assets/asset_638324605773712387.jpg'))
              const urlToShare = encodeURIComponent(url);

              const instagramShareUrl = `https://www.instagram.com/share?url=${urlToShare}`;

              // Open Instagram in a new window
              window.open(instagramShareUrl, '_blank');
            }, 100);
          }
        })
      });

    } catch (ex) {
    }
  }

  //return false;
  PreparedshareUrl() {
    setTimeout(() => {
      const canvas = document.getElementById(
        'textCanvas'
      ) as HTMLCanvasElement;
      const tCtx: any = canvas.getContext('2d');
      const imageElem: any = document.getElementById('image');
      const font = '50px Parisienne';
      tCtx.font = font;
      tCtx.canvas.width = tCtx.measureText(this.tabledata).width + 50;
      tCtx.canvas.height = 100;
      tCtx.font = font;
      tCtx.fillStyle = '#000';
      tCtx.fillText(this.tabledata, 20, 50);
      setTimeout(() => {
        imageElem.src = tCtx.canvas.toDataURL();
        this.facebookUrl = imageElem.src;
        console.log('image', imageElem.src);
        imageElem.width = tCtx.measureText(this.tabledata).width + 50;
        //imageElem.height = 50;
      }, 100);
    }, 100);
  }
}


