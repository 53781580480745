import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Rider } from '../rider/rider.service';

@Injectable({
  providedIn: 'root'
})
export class ManageridesService {
  public baseUri = `${environment.WebApiBaseUrl}`;
  public rideUri = `${environment.WebApiBaseUrl}Rides`;
  public RiderModuleUri = `${environment.WebApiBaseUrl}rider`;
  public sessionUrlStage = `${environment.sessionUrlStage}`;
  public sessionUrlLive = `${environment.sessionUrlLive}`;
  public production = `${environment.production}`;
  pagedData: any;
  historyData: any;

  private machineIP: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    //this.setMachineIp();
  }
  getSessionURL() {
    if (this.production == "true") { return this.sessionUrlLive; }
    else { return this.sessionUrlStage; }
  }

  getRidesList(nameSearch: any, idSearch: any, phnSearch: any, active: number, sortColumn: string, sortDir, page: number, pageSize: number, userId: string,isExport=false): Observable<any> {
    return this.http.get(`${this.rideUri}/GetRidesList?nameSearch=${nameSearch}&idSearch=${idSearch}&phnSearch=${phnSearch}&active=${active}&sortColumn=${sortColumn}&sortDir=${sortDir}&PageNo=${page}&pageSize=${pageSize}&userId=${userId}&isExport=${isExport}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          return true;
        })
      );
  }

  getRideInfoList(sortColumn: string, sortDir, page: number, pageSize: number, userId: string, riderAdminUserId: string,rideType:number): Observable<any> {
    return this.http.get(`${this.rideUri}/GetRideInfoList?sortColumn=${sortColumn}&sortDir=${sortDir}&page=${page}&pageSize=${pageSize}&userId=${userId}&riderAdminUserId=${riderAdminUserId}&rideType=${rideType}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          return true;
        })
      );
  }
  getImageUrl(Id) {
    return this.http.post(this.baseUri + `CustomSignPdfViewer/Base64StringIntoUrl`, Id);
  }
  getRideHistoryDetail(sortColumn: string, sortDir, page: number, pageSize: number, userId: string, id: string): Observable<any> {
    return this.http.get(`${this.rideUri}/GetRideHistoryDetail?sortColumn=${sortColumn}&sortDir=${sortDir}&page=${page}&pageSize=${pageSize}&userId=${userId}&id=${id}`)
      .pipe(
        map((response: any) => {
          this.historyData = response;
          return true;
        })
      );
  }
  save(rides: RideMaster, FromWeb: boolean) {
    
    return this.http.post(`${this.rideUri}/save?FromWeb=${FromWeb}`, rides);
  }
  SaveEvaluation(rides: CoreEvalutionRider, FromWeb: boolean) {
    return this.http.post(`${this.rideUri}/SaveEvaluation?FromWeb=${FromWeb}`, rides);
  }
  UpdateRides(rides: SaveRide) {
    return this.http.post(`${this.rideUri}/save`, rides);
  }
  getRiderRecipeDetailById(RiderMasterId: number) {
    return this.http.get(`${this.rideUri}/getRiderRecipeDetailById?RiderMasterId=${RiderMasterId}`);
  }
  getRiderRecipeDetail(RiderId: number) {
    return this.http.get(`${this.rideUri}/getRiderRecipeDetail?RiderId=${RiderId}`);
  }
  getCurrentRiderRecipeDetail
    (RiderId: number, rideType) {
    return this.http.get(`${this.rideUri}/getCurrentRiderRecipeDetail?RiderId=${RiderId}&rideType=${rideType}`);
  }
  GetCompleteRideByRideMasterId(rideMasterId) {
    return this.http.get(`${this.rideUri}/GetCompleteRideByRideMasterId?rideMasterId=${rideMasterId}`);
  }
  getRidesHistoryGraph(riderAdminUserId: number, graphtype: string,rideType:any) {
    

    return this.http.get(`${this.rideUri}/getRidesHistoryGraph?riderAdminUserId=${riderAdminUserId}&graphtype=${graphtype}&rideType=${rideType}`);
  }
  GetRiderDashboardGraphs(UserId: number, duration: any, rideType, FromDate: any, ToDate:any) {
    if (typeof FromDate == "undefined" || FromDate == null || FromDate=='' ) { FromDate = null; }
    else { FromDate = FromDate.toDateString(); }
    if (typeof ToDate == "undefined" || ToDate == null || ToDate=='') { ToDate = null; }
    else { ToDate = ToDate.toDateString(); }
    return this.http.get(this.baseUri + `Dashboard/GetRiderDashboardGraphs?UserId=${UserId}&duration=${duration}&rideType=${rideType}&FromDate=${FromDate}&ToDate=${ToDate}`)
  }

  GetRiderDashboardGraphsForQuadrant(UserId: number, duration: any, rideType, FromDate: any, ToDate: any) {
    if (typeof FromDate == "undefined" || FromDate == null || FromDate=='') { FromDate = null; }
    else { FromDate = FromDate.toDateString(); }
    if (typeof ToDate == "undefined" || ToDate == null || ToDate=='') { ToDate = null; }
    else { ToDate = ToDate.toDateString(); }
    return this.http.get(this.baseUri + `Dashboard/GetRiderDashboardGraphsForQuadrant?UserId=${UserId}&duration=${duration}&rideType=${rideType}&FromDate=${FromDate}&ToDate=${ToDate}`)
  }
  SaveMachineWhenClinicHaveOneMachine(customerid: number) {
    return this.http.get(`${this.rideUri}/SaveMachineWhenClinicHaveOneMachine?customerid=${customerid}`);
  }
  
  getRidesHistoryGraphDate(riderAdminUserId: number, FromDate: any, ToDate: any,RideType:any) {
    if (typeof FromDate == "undefined" || FromDate == null || FromDate=='' ) { FromDate = null; }
    else { FromDate = FromDate.toDateString(); }
    if (typeof ToDate == "undefined" || ToDate == null || ToDate=='') { ToDate = null; }
    else { ToDate = ToDate.toDateString(); }

    return this.http.get(`${this.rideUri}/getRidesHistoryGraphDate?riderAdminUserId=${riderAdminUserId}&FromDate=${FromDate}&RideType=${RideType}&ToDate=${ToDate}`);
  } 
  
  // ----------------------------Start-Load Recipe Into Machine-----------------------------

  GetRiderById(Id: string) {
    return this.http.get(`${this.RiderModuleUri}/GetRiderById?Id=${Id}`);
      //.pipe(
      //);
  }

  setMachineIp(ip: string) {
    //this.machineIP = "192.168.0.110"; //fetch IP from remote server
    //this.machineIP = "localhost:51346";
    this.machineIP = ip;
    this.httpHeaders = new HttpHeaders();
  }
  writeOnRegister(register, datatype, value): Observable<any> {
    this.httpHeaders.set('Access-Control-Allow-Origin', '*');
    return this.http.post(`http://${this.machineIP}/writereg.htm;reg-${register};fmt-${datatype}`, `reg=${value}`);
  }
  
  // For Saving Feed Back of rider
  saveFeedBack(ridesfeed: RideFeedBack, FromWeb: boolean) {
    debugger;
    return this.http.post(`${this.rideUri}/SaveRiderFeedBack?FromWeb=${FromWeb}`, ridesfeed);
  }

    // geting  Feek of rider by Id
  getRiderFeedBackDetailById(RiderMasterId: number) {
    
    return this.http.get(`${this.rideUri}/getRiderFeedBackDetailById?RiderMasterId=${RiderMasterId}`);
  }

  GetFeeBackList(RiderMasterId: any, sortColumn: string, sortDir, page: number, pageSize: number, FeedFilter: string,) {
    return this.http.get(`${this.rideUri}/GetFeeBackList?RiderMasterId=${RiderMasterId}&SortColumn=${sortColumn}&SortDir=${sortDir}&PageNo=${page}&PageSize=${pageSize}&FeedFilter=${FeedFilter}`);
  }
  DeleteFeedBack(selected: string) {
    
    return this.http.get(`${this.rideUri}/DeleteFeedBack?selected=${selected}`)
  }
  ClickToDownload(id: any) {
    return this.http.get(this.baseUri + `rider/DownloadwaiverPdfFile?id=${id}`, { responseType: 'blob' });
  }
  AddNotes(notes: SaveNotes) {
    return this.http.post(this.baseUri+ `Role/SaveNotes`, notes);
  }
  GetNotesList(riderId: string) {
    return this.http.get(this.baseUri+`Role/GetNotesList?riderId=${riderId}`);
  }
  

  ReadMessage(userId: any) {
    return this.http.get(this.baseUri+ `Role/ReadMessage?userId=${userId}`);
  }
  //----------------------------End------------------------------------
  
}
export class RideMaster {
  PreRideSBP: string
  PreRideDBP: string;
  RiderRecipeDetailModel: any[];
  RiderId: number;
  RideStartTime: string;
  constructor() {
    this.PreRideDBP = "";
    this.PreRideSBP = "";
    this.RiderRecipeDetailModel = [];
    this.RiderId = 0;
    //this.RideStartTime = '';
  }
}

export class SaveRide {
  Id: string;
  postRideSBP: string;
  postRideDBP: string;
  bMI: string;
  heightInch: string;
  heightFeet: string;
  weight: string;
  ambientTemp: string;
  RideEndTime: string;
  fullSpecturum: boolean;
  restingHeartRate: string;
  constructor() {
    this.Id = "";
    this.postRideSBP = "";
    this.postRideDBP = "";
    this.bMI = "";
    this.heightInch = "";
    this.heightFeet = "";
    this.weight = "";
    this.ambientTemp = "";
    this.fullSpecturum = false;
    this.restingHeartRate = "";
  }
}
export class CoreEvalutionRider {

  RiderId: any;
  CoreEvalutionRiderDetail: any[];
  RideStartTime: string;
  CoreScore: string;
  PersonalBest: string;
  newCoreScore: string;
  postRideSBP: string;
  postRideDBP: string;
  bMI: string;
  heightInch: string;
  heightFeet: string;
  weight: string;
  ambientTemp: string;
  RideEndTime: string;
  fullSpecturum: boolean;
  restingHeartRate: string;
  riderDate: string;
  riderName: string;
  email: string;


  constructor() {
    this.CoreEvalutionRiderDetail = [];
    this.RiderId = "";
    this.CoreScore = "";
    this.postRideSBP = "";
    this.postRideDBP = "";
    this.bMI = "";
    this.heightInch = "";
    this.heightFeet = "";
    this.weight = "";
    this.newCoreScore = "";
    this.ambientTemp = "";
    this.fullSpecturum = false;
    this.restingHeartRate = "";
    this.PersonalBest = "";
    this.riderDate = "";
    this.riderName = "";
    this.email = "";
  }
}

export class RideFeedBack {
  RiderId: any;
  RideMasterId: any;
  Painscale: number;
  Balance: number;
  Flexibility: number;
  RangeOfMotion: number;
  Strenght: number;
  Status: boolean;
  CreatedOn: string
  CraetedBy: any;
  constructor() {
    this.RiderId = "";
    this.RideMasterId = "";
    this.Painscale = 0;
    this.Balance = 0;
    this.Flexibility = 0;
    this.RangeOfMotion = 0;
    this.Strenght = 0;
    this.Status = false;
    this.CreatedOn = "";
    this.CraetedBy = "";
  }
}
export class SaveNotes {
  Note: string
  RiderId :any
  constructor() {
    this.Note = "";
    this.RiderId = "";
  }
}
