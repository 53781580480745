import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, UserDetails, ChangePasswordModel, ModuleList } from 'src/app/views/shared/common.service';
import { ToastrService } from 'ngx-toastr';
import * as signalR from '@aspnet/signalr';
import { EmailnotificationService } from '../../views/emailnotification/emailnotification.service';
import { ConfirmationDialogService } from '../../views/shared/confirmation-dialog/confirmation-dialog.service';
import { UserService } from '../../views/shared/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
   name:any;
  count: number = 5;
  Announcementcount: number = 5;
  headerData: any; imageLink = '';
  modulePermission: ModuleList;
  openModulePermission: ModuleList;
  userType: any;
  today: number = Date.now();
  isClinicAdmin = false;
  isRidder = false;
  isSupperAdmin = false;
  isOtherUser = false;
  loginUserId: any;
  clinicAdminUser: any;
  isMyProfile = false;
  notificationList: any = [];
  loginlogo: any;
  themeType: any;
  SwitchAsUserTypes: any;
  connection: any;
  loading = false;
  hubConnection: signalR.HubConnection;
  ConnectionID: string = null;
  isSwAsSupAdmin = false;
  SwitchUserTypeName: any;
  constructor(private routerService: Router, private toaster: ToastrService,
    private commonService: CommonService, private activeRoute: ActivatedRoute,
    private Emailservices: EmailnotificationService, private dialogService: ConfirmationDialogService, private userService: UserService ) {
    this.isClinicAdmin = false;
    this.isRidder = false;
    this.isSupperAdmin = false;
    this.isOtherUser = false;
    //const moduleCode = this.activeRoute.snapshot.data.moduleCode;
    //console.log("moduleCode", moduleCode)
    //this.openModulePermission = this.commonService.getPermission(moduleCode);
    //console.log("this.openModulePermission", this.openModulePermission);   
  }

  username: any;
  usernameFull: any;
  ngOnInit() {
    if (localStorage.getItem("ThemeType").toString() == "1") {
      this.loginlogo = 'assets/images/logo_White.png';
    }
    else {
      this.loginlogo = 'assets/images/login-logo.png';
    }

    this.commonService.getLoggedInName.subscribe((user: any) => { 
      setTimeout(() => {
        this.modulePermission = this.commonService.getPermission(1021);
      }, 2000);
      this.headerSetup(user);
      this.loginUserId = user.id;
    });

    this.prepareSignalR();
    
    //this.Emailservices.share.subscribe(data => {
    //  console.log("con", data)
    //  this.count = data;
    //});
    this.GetEmailNotificationList();
    this.GetAnouncemenetNotificationList();
    debugger
    if (localStorage.getItem("SwAsSupAdmin") == "false" || localStorage.getItem("SwAsSupAdmin") == null) {
      this.isSwAsSupAdmin = false;
    } else {
      this.commonService.getLoggedInName.subscribe((userdetail: any) => {
        if (userdetail.userType == "usertype01" || userdetail.userType == "usertypesubadmin" ||  userdetail.userType == "usertypeclinicadmin"  || userdetail.userType == "usertypeclinicsubadmin") {
          this.isSwAsSupAdmin = true;
        }
      });
    
      this.SwitchUserTypeName = localStorage.getItem("SwAsuserTypeName");
    }
  }

  headerSetup(user: any) {
    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      console.log("userdetail", userdetail)
      this.userType = userdetail.userType;
      this.loginUserId = userdetail.id;
      if (userdetail.id == null) {
        this.usernameFull = user.firstName + " " + user.lastName;

        if (user.firstName != null && user.firstName!='') {
          if (user.firstName.length > 16) {
            this.username = user.firstName.substring(0, 16) + "..";
          }
          else {
            var nm = user.firstName + " " + user.lastName;
            if (nm.length > 16) {
              this.username = nm.substring(0, 16) + "..";
            }
            else { this.username = nm.substring(0, 16); }
          }
        } 
              
      }
      else {
        //this.username = userdetail.firstName + " " + userdetail.lastName
        this.usernameFull = userdetail.firstName + " " + userdetail.lastName;

        if (userdetail.firstName.length > 15) {
          this.username = userdetail.firstName.substring(0, 15) + "..";
        }
        else {
          var nm = userdetail.firstName + " " + userdetail.lastName;
          if (nm.length > 15) {
            this.username = nm.substring(0, 15) + "..";
          }
          else { this.username = nm.substring(0, 15); }

         // this.username = nm.substring(0, 15) + "..";
        }       
        this.getHeaderData(userdetail.id);
      }

      if (userdetail.userType == 'usertypeclinicadmin') {
        this.isClinicAdmin = true;
        this.clinicAdminUser = userdetail.customerRiderId;
        this.isSupperAdmin = false;
        this.isRidder = false;
        this.isOtherUser = false;
      }
      else if (userdetail.userType == 'usertyperider') {
        this.isRidder = true;
        this.clinicAdminUser = userdetail.customerRiderId;
        this.isSupperAdmin = false;
        this.isClinicAdmin = false;
        this.isOtherUser = false;
      } 
      else if (userdetail.userType == 'usertype01') {
        this.isSupperAdmin = true;
        this.isMyProfile = true;
        this.isClinicAdmin = false;
        this.isRidder = false;
        this.isOtherUser = false;
      } 
      else if (userdetail.userType != 'usertypeclinicadmin' && userdetail.userType != 'usertyperider' && userdetail.userType != 'usertype01')  {
        this.isSupperAdmin = false;
        this.isClinicAdmin = false;
        this.isRidder = false;
        this.isOtherUser = true;
      }
    });
  }
  setValueForCurrentStage() {
    //localStorage.removeItem('moduleList');
   let dataof = localStorage.getItem("menuStage");
    
    if (localStorage.getItem("menuStage") == '0' || localStorage.getItem("menuStage") ==null) {
      localStorage.removeItem('menuStage');
      localStorage.setItem('menuStage', '1');
      let data = document.getElementById('setmenustage');
      //if (data != null) {
      //  setTimeout(function ()
      //  {
      //   // document.getElementById('setmenustage').className = "page Active";
      //    document.getElementById('setmenustage').removeAttribute("class");
      //    document.getElementById('setmenustage').setAttribute("class", "page active");
      //  },
      //    2000);
      //}
    }
    else {
      localStorage.removeItem('menuStage');
      localStorage.setItem('menuStage', '0');
      let data = document.getElementById('setmenustage');
      //if (data != null) {
      //  setTimeout(function () {
      //    document.getElementById('setmenustage').removeAttribute("class");
      //    document.getElementById('setmenustage').setAttribute("class", "page");
          
      //  },
      //    2000);
        
      //}
    }
  }
  getHeaderData(id: any) {
    this.commonService.getHeaderData(id).subscribe((res: any) => {
      this.headerData = res;
      this.imageLink = res.profilePic;
    },
      (error: any) => {
      });

    this.commonService.GetSwitchAsUserTypes().subscribe((res: any) => {
      console.log("switch as users types", res);
      this.SwitchAsUserTypes = res;
  
      console.log("switch as users types 1", this.SwitchAsUserTypes.length);
    });
  }

  logout() {
    this.commonService.logout();
  }
  //switchUser(item: any) {
  //  this.loading = true;
  //  this.commonService.switchUser(item);
  //  this.loading = true;
  //}
  switchUserSuperadmin() {
    let UserTypeName = localStorage.getItem("SwAsuserTypeName");
    let UserTypeId = localStorage.getItem("SwAsUserTypeID");
    let SwAsemail = localStorage.getItem("SwAsemail");
    const message = `Are you sure you want to Switch as ` + UserTypeName + `?`;
    this.dialogService.confirm('Switch As ' + UserTypeName, message).subscribe(confirmed => {
      if (confirmed) {
        this.loading = true;
        localStorage.removeItem("SwAsSupAdmin");
        localStorage.setItem("SwAsSupAdmin", "false");
        localStorage.removeItem("SwAsuserTypeName");
        localStorage.removeItem("SwAsUserTypeID");
        localStorage.removeItem("SwAsemail");
        const basicuserinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.userService.updatetoken(SwAsemail, UserTypeId).subscribe((res: any) => {
          if (res) {
            if (res.status == 200) {
              localStorage.setItem("access_token", res.token);
              this.commonService.getCurrentUserDetail();
              setTimeout(() => {
                this.commonService.getLoggedInName.subscribe((userdetail: any) => {
                  console.log("switchUser 12", userdetail);

                  this.loading = false;
                  if (userdetail.userType == 'usertyperider') {

                    console.log("switch usertyperider");
                    this.commonService.getUserModulePermissions().subscribe((m: any) => {
                      localStorage.removeItem('moduleList');
                      localStorage.setItem('moduleList', JSON.stringify(m));
                      // window.location.href = "ridehistory";
                      this.routerService.navigateByUrl("/ridehistory");
                    });
                  }
                  else {
                    console.log("switch dashboard");
                    this.commonService.getUserModulePermissions().subscribe((m: any) => {
                      localStorage.removeItem('moduleList');
                      localStorage.setItem('moduleList', JSON.stringify(m));
                      //this.routerService.navigateByUrl("/dashboard");
                       window.location.href = "dashboard";
                    });
                  }
                });
              }, 2000);

            }
            else if (res.status == 201) {
              this.toaster.error(res.token);
            }
          }
        }, error => {
        });
        setTimeout(() => {
          let TypeName = JSON.parse(localStorage.getItem("userinfo")).userTypeHeader;
          console.log("deepakjhadeepakjhadeepakjhadeepakjha", localStorage.getItem("userinfo"));
          this.toaster.success(`You are switched as ` + TypeName);
        }, 2000);
      }
      else {
        this.loading = false;
      }
    });
  }
  switchUser(item: any) {
    const message = `Are you sure you want to Switch as ` + item.UserTypeName + `?`;
    this.dialogService.confirm('Switch As ' + item.UserTypeName, message).subscribe(confirmed => {
      if (confirmed) {
        this.loading = true;
        console.log("switchUser item", item);
        console.log("switchUser 11", localStorage.getItem('userinfo'));
        const basicuserinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.userService.updatetoken(basicuserinfo.email, item.UserTypeID).subscribe((res: any) => {
          if (res) {
            if (res.status == 200) {
              localStorage.setItem("access_token", res.token);
              this.commonService.getCurrentUserDetail();
              setTimeout(() => {
                this.commonService.getLoggedInName.subscribe((userdetail: any) => {
                  console.log("switchUser 12", userdetail);

                  this.loading = false;
                  if (userdetail.userType == 'usertyperider') {

                    console.log("switch usertyperider");
                    this.commonService.getUserModulePermissions().subscribe((m: any) => {
                      localStorage.removeItem('moduleList');
                      localStorage.setItem('moduleList', JSON.stringify(m));
                    // window.location.href = "ridehistory";
                    this.routerService.navigateByUrl("/ridehistory");
                    });
                  }
                  else {
                    console.log("switch dashboard");
                    this.commonService.getUserModulePermissions().subscribe((m: any) => {
                      localStorage.removeItem('moduleList');
                      localStorage.setItem('moduleList', JSON.stringify(m));
                      this.routerService.navigateByUrl("/dashboard");
                      // window.location.href = "dashboard";
                    });
                  }
                });
              }, 2000);

            }
            else if (res.status == 201) {
              this.toaster.error(res.token);
            }
          }
        }, error => {
        });
        this.toaster.success(`You are switched as ` + item.UserTypeName);
      }
      else {
        this.loading = false;
      } 
    });
  }


  GetEmailNotificationList() {   
    this.Emailservices.GetEmailnotificationCount().subscribe((data: any) => {   
      console.log("Count", data);
      this.count = data;    
    })

  }
  GetAnouncemenetNotificationList() {
    this.Emailservices.GetAnouncemenetNotificationList().subscribe((data: any) => {
      console.log("Count", data);
      this.Announcementcount = data;
    })

  }
  prepareSignalR() {
    this.commonService.hubConnection.on("sendNotificationToClient", (message: string, isShowToaster: boolean) => {
      this.getHeaderNotification(isShowToaster, message);

    });
  }

  connectionId: string;
  totalNotification: number = 0;
  getHeaderNotification(isNew = false, message = null) {
    //this.commonService.getHeaderNotification().subscribe((m: any[]) => {
    //  this.totalNotification = m.length;
    //  this.count = m.length;
    //  this.notificationList = m.slice(0, 5);

    //  if (isNew) {
    //    this.toaster.info(message);
    //  }
    //});
    this.Emailservices.GetEmailnotificationCount().subscribe((data: any) => {
    
      this.count = data;
    })
    this.Emailservices.GetAnouncemenetNotificationList().subscribe((data: any) => {
     
      this.Announcementcount = data;
    })
  }
  //onNotification(notification: any) {

  //  this.commonService.setNotificationToRead(notification.UserNotificationId).subscribe(m => {
  //    this.getHeaderNotification();
  //    this.routerService.navigateByUrl('/viewnotification')

  //  });
  //}

}
