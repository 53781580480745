import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ManageUserService } from '../manageuser/addedituser.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Emailnotification, EmailnotificationService } from './emailnotification.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService, ModuleList } from '../shared/common.service';
import { SharedModule } from '../shared/shared.module';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from '../customer/customer.service';
import { RiderService } from '../rider/rider.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-emailnotification',
  templateUrl: './emailnotification.component.html',
  styleUrls: ['./emailnotification.component.scss']
})
export class EmailnotificationComponent implements OnInit {
  emailModel: Emailnotification = new Emailnotification();
  @ViewChild('clearDropCustomer') clearDropCustomer: NgSelectComponent;
  @ViewChild('#Description') notificationDescription: ModalDirective;
  lstUser: any;
  listFilter = '';
  @ViewChild("myckeditor") ckeditor: any;
  ckeConfig: any;
  loadSave=false;
  emailnotifyform: FormGroup;
  userlists: [];
  selected: any;
  userlist: any;
  emaillist: any;
  isMyprofile: any;
  MyProfile: any; 
  fromsession: boolean;
  loginid: any;
  isClinicAdmin = false;
  isSupperAdmin = false;
  isRidder = false;
  isSubAdmin = false;
  errorMessage: any;
  pageTitle: string;
  addOrUpdatePermission: boolean;
  lstCustomer: any;
  userTypeSelection: any;
  Selectedoption: any;
  lstRider: any;
  listcustid: any;
  ProfileError: boolean = false;
  modulePermission: ModuleList;
  result: Emailnotification;

  NotificationDetails: any;
  allPartnerFacility: string = "";
  selectPartnerFacility: string = "";

  Facility: any;
  FacilityUsers: any;
  FacilityRiders: any;

  constructor(private userService: ManageUserService,
    private fb: FormBuilder,
    private Emailservices: EmailnotificationService,
    private toaster: ToastrService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private riderservice: RiderService,
    private customerservice: CustomerService,

  )
  {
    //if (this.route.routeConfig.path == 'emailnotification/:Id') {
    //  this.fromsession = true;
    //}
    //else {
    //  this.fromsession = false;
    //}
  }

  ngOnInit() {
    this.initForm();
    this.getUserNameDDl();
    this.addOrUpdatePermission = false;
    this.getFacilityNameDDl('0');
    this.getRiderNameDDl();
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      versionCheck: false
    };
    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      console.log('asd', userdetail)
      this.loginid = userdetail.id;

      this.allPartnerFacility = "All Facilities";
      this.selectPartnerFacility = "Select Facilities";

      if (userdetail.userType == 'usertypeclinicadmin') {
        this.isClinicAdmin = true;
      }
      else if (userdetail.userType == 'usertyperider') {
        this.isRidder = true;
      }
      else if (userdetail.userType == 'usertype01') {
        this.isSupperAdmin = true;
        this.allPartnerFacility = "All Partners";
        this.selectPartnerFacility = "Select Partners";
      }
      else if (userdetail.userType == 'usertypesubadmin') {
        this.isSubAdmin = true;
        this.allPartnerFacility = "All Partners";
        this.selectPartnerFacility = "Select Partners";
      }
      //else if (this.usertype != "usertype01" && this.usertype != "usertypesubadmin" && this.usertype != "usertypeclinicadmin" && this.usertype != "usertyperider" && this.usertype != "usertypeclinicsubadmin" && this.usertype != "usertypeoperator") {
      //  this.isdoctor = true;
      //}

    });

    this.route.paramMap.subscribe(
      params => {
        const Id = params.get('Id');
        if (Id) {
          this.loadSave = true;
          this.GetEmailnotificationdataByid(Id);
        }
        else {
          this.pageTitle = 'Compose and Send Email';
          //this.addOrUpdatePermission = this.modulePermission.roleModuleAddFlag;
        }
      });

    //this.route.queryParams.subscribe(params => {
    //  let isMyProfiles = params['isMyProfile'];
    //  this.isMyprofile = isMyProfiles;
    //  this.MyProfile = this.isMyprofile;
      
    //});
  }

  initForm() {
    this.emailnotifyform = this.fb.group({
      selectUser: [null, Validators.nullValidator],
      subject: ['', Validators.required],
      content: ['', Validators.required]

    })
  }

  GetEmailnotificationdataByid(Id: string) {
    this.loadSave = true;
    this.Emailservices.GetEmailnotificationdataByid(Id).subscribe((Emailnotification: Emailnotification) => {
      console.log("GetEmailnotificationdata", Emailnotification);
      this.result = Emailnotification;
      console.log("listts", this.result);
      this.displayEmaildata(Emailnotification);
      this.loadSave = false;
    }, (error: any) => {
      this.errorMessage = <any>error;
      this.loadSave = false;
    });
  }
  displayEmaildata(Emailnotification: any): void {
    if (Emailnotification) {
      this.pageTitle = 'Edit';
      this.emailnotifyform.patchValue({
        subject: Emailnotification.subject,
        content: Emailnotification.matter
      })
    }
    else {
      this.pageTitle = 'Add';
      this.emailnotifyform.reset();
    }
  }

  //getUserNameDDl() {
  //  this.Emailservices.GetUserNamewithtype().subscribe((data: any) => {
  //    this.lstUser = data;
  //    console.log("user", this.lstUser);
  //  })
  //}
 
  restUserddl() {
    this.listFilter = '';
  }
  //storevalues(event: any) {
  //  console.log("event",event);
  //  this.userlist = [];
  //  this.emaillist = [];
  //  for (let i = 0; i < event.length; i++) {
  //    this.userlist += event[i].userId.toString() + ",";
  //    this.emaillist += event[i].email.toString() + ",";
  //  }
  //}
storevalues(event: any) {
  this.emaillist = [];
    for (let i = 0; i < event.length; i++) {
      this.emaillist.push( event[i].email );
  }
  this.listcustid = [];
  for (let i = 0; i < event.length; i++) {
    this.listcustid.push(event[i].userId);
  }
  
  }
  storevaluesforuser(event: any) {
    this.emaillist = [];
    for (let i = 0; i < event.length; i++) {
      this.emaillist.push(event[i].email);
    }
    this.listcustid = [];
    for (let i = 0; i < event.length; i++) {
      this.listcustid.push(event[i].userId  );
    }
  }


  sendEmail() {
    console.log("emailnotifyform", this.emailnotifyform)
    debugger;
    this.loadSave = true;
    this.ProfileError = false;

    if (this.Selectedoption == null) {
      this.ProfileError = true;
    }
    if (this.emailnotifyform.valid) {
      if (this.Selectedoption != null) {
        if (this.Selectedoption == 3) {
          this.storevalues(this.lstCustomer)
        }
        if (this.Selectedoption == 5) {
          this.storevaluesforuser(this.lstUser)
        }
        if (this.Selectedoption == 7) {
          this.storevalues(this.lstRider)
        }
      }
      this.emailModel.selectUser = this.listcustid;
      this.emailModel.subject = this.emailnotifyform.value.subject;
      this.emailModel.content = this.emailnotifyform.value.content;
      this.emailModel.emailList = this.emaillist;
      let data = this.listcustid;
      this.emailModel.selectUserWithStringFormat = JSON.stringify(data);
      let emailList = this.emaillist;
      this.emailModel.emailListWithStringFormat = JSON.stringify(emailList);
      console.log("this.emailModel.selectUser", this.emailModel.selectUser);

      if (this.emailModel.selectUser == '' || typeof this.emailModel.selectUser == 'undefined' ) {
        this.toaster.error('', 'No User available to send Email');
        this.loadSave = false;
        return false;
      }
      this.Emailservices.SendEmail(this.emailModel).subscribe((result: any) => {
        this.loadSave = false;
        if (result.statusCode == 200) {
         // this.toaster.success(result.responseMessage);
          this.toaster.success("Email Notification has been sent successfully to the selected users.");
          this.router.navigateByUrl("/emailnotify");
          
        }
        else {
          this.toaster.error('Error!', 'Unable to save record.');
        }
      })
    }
    else {
      this.commonService.validateAllFormFields(this.emailnotifyform);
      this.loadSave = false;

    }
  }


  CancelButton() {
    this.router.navigateByUrl("/emailnotify");
  }
  setUser(entry) {
    debugger;
    this.ProfileError = false;
    this.selectUser.reset();
    this.Selectedoption = null;
    //this.Selectedoption = entry;
    this.userTypeSelection = entry;
    if (this.userTypeSelection == 1) {
      var text = document.getElementById('cli_partner');
      var textuser = document.getElementById('cli_user');
      var textrider = document.getElementById('cli_rider');
      if (textuser!=null) {
        textuser.classList.remove('selected_users');
      }
      
      if (textrider!=null) {
        textrider.classList.remove('selected_users');
      }
      if (text!=null) {
        text.classList.add('selected_users');
      }
      
    this.setOptions(3);
    }
    if (this.userTypeSelection == 0) {
      this.setOptions(5);
      var text = document.getElementById('cli_partner');
      var textuser = document.getElementById('cli_user');
      var textrider = document.getElementById('cli_rider');
      if (textuser!=null) {
        textuser.classList.add('selected_users');
      }
      if (textrider != null) {
        textrider.classList.remove('selected_users');
      }
      if (text!=null) {
        text.classList.remove('selected_users');
      }
      
    }
    if (this.userTypeSelection == 2) {
      this.setOptions(7);
      var text = document.getElementById('cli_partner');
      var textuser = document.getElementById('cli_user');
      var textrider = document.getElementById('cli_rider');
      if (textuser!=null) {
        textuser.classList.remove('selected_users');
      }
      if (textrider!=null) {
        textrider.classList.add('selected_users');
      }
      if (text!=null) {
        text.classList.remove('selected_users');
      }
      
    }
  }
  setOptions(entry) {
    debugger;
    this.Selectedoption = entry;
    if (this.Selectedoption == 3 || this.Selectedoption== 5 || this.Selectedoption==7 ) {
      this.selectUser.setValidators([Validators.nullValidator]);
      this.selectUser.updateValueAndValidity();
    }
    else {
      this.selectUser.setValidators([Validators.required]);
      this.selectUser.updateValueAndValidity();

    }

    //this.PHousingStatus.setValidators([Validators.required]);
    //this.PHousingStatus.updateValueAndValidity();
  }

  getFacilityNameDDl(Id: string) {
    this.customerservice.getCusotmerNameDDl().subscribe((data: any) => {
      this.lstCustomer = data;
      console.log('custmer', this.lstCustomer);
    })
  }


  getUserNameDDl() {
    this.userService.GetUserName().subscribe((data: any) => {
      this.lstUser = data;
      console.log('list', this.lstUser);
    })

  }
  getRiderNameDDl() {
    this.riderservice.getRiderNameDDl().subscribe((data: any) => {
      this.lstRider = data;
      console.log('asd', this.lstRider)
    })
  }
  SetRiderName($event) {
    if (typeof $event === 'undefined') {
      this.listFilter = '';
    } else {
      this.listFilter = $event.customerId;
    }
  }
  SetCustomerName(e:any) {
    console.log("email",e)
  }
  displayDetail(detail) {
    try {
      this.NotificationDetails = detail;
      this.notificationDescription.show();
    }
    catch (err) {
      console.log("error modal popup window", err);
    }
  } 

  onChange($event: any): void {
  }

  onPaste($event: any): void {
  }


  get selectUser() { return this.emailnotifyform.get('selectUser'); }
  get subject() { return this.emailnotifyform.get('subject'); }
  get content() { return this.emailnotifyform.get('content'); }
}

