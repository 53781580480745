import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/views/shared/common.service';
import { ModulesService, ModuleModel } from 'src/app/views/managemodules/modules.service';
import { debug } from 'util';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {
  loadSave = false;
  moduleList: ModuleModel[];
  userId: any;
  userName: any;
  constructor(private commonService: CommonService, private router: Router,
    private moduleService: ModulesService) { }

  ngOnInit() {
    this.commonService.getLoggedInName.subscribe((user: any) => {
      this.sidebarSetup(user);
    });
  }
  SignOut() {
    this.commonService.logout();
  }
  sidebarSetup(user: any) {
    this.loadSave = true;
    this.userId = user.id;
    this.commonService.getLoggedInName.subscribe((userdetail: any) => {
      if (userdetail.id != null) {
        this.userId = userdetail.id;
        this.userName = userdetail.userName

      }
    });
    if (typeof this.userId !== "undefined" && typeof this.userId !== null) {
      this.moduleService.getModuleList(this.userId).subscribe((response: any) => {
        if (response) {
          this.moduleList = response;
          this.loadSave = false;
        }
        else {
          this.moduleList = [];
        }
      }, error => {
        this.loadSave = false;
      });
    }
  }

  link(path: any,id) {
    debugger
    let dataof = localStorage.getItem("menuStage");
    //document.getElementById('selected_' + id).removeAttribute("class");
    //document.getElementById('selected_' + id).setAttribute("class", "selected");
    debugger
    if (localStorage.getItem("menuStage") == '0' || localStorage.getItem("menuStage") == null) {
      let data = document.getElementById('setmenustage');
      if (data != null) {
        setTimeout(function () {
         
          document.getElementById('setmenustage').removeAttribute("class");
          document.getElementById('setmenustage').setAttribute("class", "page");

        },
          300);

      }

    }
    else {

      let data = document.getElementById('setmenustage');
      if (data != null) {
        setTimeout(function () {
          // document.getElementById('setmenustage').className = "page Active";
          document.getElementById('setmenustage').removeAttribute("class");
          document.getElementById('setmenustage').setAttribute("class", "active page");
        },
          300);
      }
    }
    this.router.navigateByUrl(path);
  }
}
