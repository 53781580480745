  import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { Router, NavigationEnd } from '@angular/router';
import { User } from '../manageuser/addedituser.service';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { request } from 'http';
import { UserService } from './user.service';
import * as signalR from '@aspnet/signalr';
import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public baseUri = `${environment.WebApiBaseUrl}`;
 
  
  public commonUri = `${environment.WebApiBaseUrl}Common`;
  public getLoggedInName = new BehaviorSubject<UserDetails>(new UserDetails());
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  masters: Master[] = [];
  mastersWithIncreption: MasterWithIncreption[] = [];
  states: SelectItemModel[] = [];
  Templates: SelectTemplateItemModel[] = [];
  downLoadModel: DownloadModel = new DownloadModel();
  businessName: SelectBusinessContactModel[]=[];
  isUploadImageValid = true;
  isUploadFileValid = true;
  LoginUser: UserDetails = new UserDetails();
  Browser: any;
  BrowserVersion: any;
  OS: string
  OSVersion: string;
  deviceInfo = null;
  hubConnection: signalR.HubConnection;
  ConnectionID: string = null;
  pagedData: any;
  constructor(private http: HttpClient, private toastr: ToastrService, private deviceService: DeviceDetectorService,
    private routerService: Router, private toaster: ToastrService, private dialogService: ConfirmationDialogService, private userService: UserService) {

    this.currentUrl = this.routerService.url;
    routerService.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });

    this.hubConnection = new signalR.HubConnectionBuilder().withUrl('/hub').withAutomaticReconnect().configureLogging(signalR.LogLevel.Information).build();
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
  isFileValid = true;

  validateAllFormFields(formGroup: FormGroup,iscroll=true) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
        return;
      }
      else if (control instanceof FormArray) {
        control.controls.forEach(arrControl => {
          if (arrControl instanceof FormGroup) {
            this.validateAllFormFields(arrControl);
            return;
          }
        })
        if (iscroll) {
          this.scroll(formGroup);
        }
      }
      
    });

  }

  customSubstring(str: string) {
    if (str.length > 50)
      str = str.slice(0, 50) + '..';
    return str;
  }

  removeConnectionbyUserID(id: string) {
    return this.http.get(this.baseUri + `Account/removeConnection?userId=${id}`);
  }
  getHeaderData(id: string) {
    return this.http.get(this.baseUri + `Common/GetHeaderData?id=${id}`);
  }
  getMasterItemsList(masterNames: any): Observable<any> {
    return this.http.get(this.baseUri + `Common/GetMasterItems/${masterNames}`).pipe(
      map((response: any) => {
        this.masters = response;

      })
    );
  }
  getConnectionID() {
    return this.http.get(`${environment.WebApiBaseUrl}Account/GetConnectionID`);
  }
  GetFacilityPartner() {
    return this.http.get(`${environment.WebApiBaseUrl}Common/GetFacilityPartnerItem`);
  }
  getMasterItemsListWithIncreption(masterNames: any): Observable<any> {
    return this.http.get(this.baseUri + `Common/GetMasterItemsListWithIncrption/${masterNames}`).pipe(
      map((response: any) => {
        this.mastersWithIncreption = response;

      })
    );
  }

  getBusinessContactList(query: any): Observable<any> {
    return this.http.get(this.baseUri + `Contact/GetSearchResult?searchType=${query}`).pipe(
      map((response: any) => {
        this.businessName = response;

      })
    );
  }

  getMasterItemsInProgress(masterNames: any, masterKeyText: string): Observable<any>{
    return this.http.get(this.baseUri + `Common/GetMasterItems/masterNames=${masterNames}&masterKeyText=${masterKeyText}`).pipe(
      map((response: any) => {
        this.masters = response;

      })
    );
  }
  SendRegitraionLink(id:any, email:any) {
    return this.http.get(this.baseUri + `Account/SendRegitraionLink?id=${id}&email=${email}`);
  }
  getStatesList(): Observable<any> {
    return this.http.get(this.baseUri + `Common/GetStatesList`).pipe(
      map((response: any) => {
        this.states = response;
      })
    );
  }

  getTemplateList(): Observable<any> {
    return this.http.get(this.baseUri + `Common/GetTemplateList`).pipe(
      map((response: any) => {
        this.Templates = response;
      })
    );
  }


  getBankDropList(){
    return this.http.get(this.baseUri + `Bank/GetBankDropList`);
  }

  getCurrentUserDetail() {
    return this.http.get(this.baseUri + `UserProfile/GetUserInfo`).subscribe((response: any) => {
      this.LoginUser = response;
      
      localStorage.removeItem('userinfo');
      localStorage.setItem('userinfo', JSON.stringify(response));
      this.getLoggedInName.next(this.LoginUser);
    })
  }
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.Browser = this.deviceInfo.browser;
    this.BrowserVersion = this.deviceInfo.browser_version;
    this.OS = "";
    this.OSVersion = this.deviceInfo.os_version;

  }
  UpdateTokenForMultipleClinic(username: string, usertypeid: any,clinicId:any) {
    this.epicFunction();
    return this.http.get(this.baseUri + `Account/UpdateTokenForMultipleClinic?username=${username}&switchClinicId=${clinicId}&browser=${this.Browser}&browserVersion=${this.BrowserVersion}&os=${this.OS}&OSVersion=${this.OSVersion}&switchAsUserTypeId=${usertypeid}`);
  }
  GetAllRiderForAllClinic(name: any, active: number, sortColumn: string, sortDir, page: number, pageSize: number) {

    if (typeof name == "undefined" || name == null) { name = null; }
    else { name = encodeURIComponent((name)); }
    return this.http.get(this.baseUri + `PushNotification/GetAllRiderForAllClinic?searchItem=${name}&sortColumn=${sortColumn}&sortDir=${sortDir}&page=${page}&pageSize=${pageSize}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          return true;
        })
      );
  }
  getUserModulePermissions() {
    return this.http.get(this.baseUri + `common/GetUserModulePermissionList`);
  }
  getHeaderNotification() {
    return this.http.get(this.baseUri + `Account/GetHeaderNotification`);
  }
  setNotificationToRead(noificationId: any) {
    return this.http.get(this.baseUri + `Account/SetNotificationToRead?noificationId=${noificationId}`)
  }
  getPermission(moduleCode: number) {
    const moduledata = JSON.parse(localStorage.getItem('moduleList'));
    console.log("moduledata", moduledata);
    if (moduledata !== null && typeof moduledata !== 'undefined') {
      let module1 = moduledata.find(m => m.moduleCode == moduleCode);
      console.log("module111111", module1);
      if (module1 == undefined)
        module1 = null;
      console.log("module1", module1);
      return module1;
    }
    else {
      return null;
    }
  }

  uploadDocumentSize(type: string, filesize: number, filelength: string) {
    if (filelength == "30MB") {

      if (type.toLowerCase() !== '') {
        if (filesize > 31457280) {    // 30MB Size in Bytes.
          this.toastr.error("Please upload a file of max. size 30MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }

    }
    else if (filelength == "10MB")
    {
      if (type.toLowerCase() !== '') {
        if (filesize > 10485760) { //10MB Size in Bytes.
          this.toastr.error("Please upload a file of max. size 10MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }
    }
      else {
      if (type.toLowerCase() !== '') {
        if (filesize > 5242880) {//5MB Size in Bytes.
          this.toastr.error("Please upload a file of max. size 5MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }
      }
  }

  uploadDocumentSizeLeaseManagement(type: string, filesize: number) {
    if (type.toLowerCase() !== '') {
      if (filesize > 31457280) { //30MB Size in Bytes.
        this.toastr.error("Please upload a file of max. size 30MB.");
        this.isFileValid = false;
      }
      else {
        this.isFileValid = true;
      }
    }
  }
  uploadImageFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".png", ".jpeg", ".jpg", ".gif"];
    let blnValid = false; this.isUploadImageValid = false
    for (var j = 0; j < extensions.length; j++)
    {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase())
      { blnValid = true; break; }
    }
    if (!blnValid)
    {
      $file.target.value = ""; this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    }
    else
    {
      this.isUploadImageValid = true;
    }
  }

  uploadDocumentFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".doc", ".docx", ".pdf", ".xlsx", ".xls", ".png", ".jpeg", ".jpg", ".gif"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }

  uploadPDFFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".pdf"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }

  uploadBulkFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".xlsx", ".xls"];
    let blnValid = false;
    this.isUploadFileValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      $file.target.value = "";
      this.toastr.error(`Valid file format is ${extensions.join(", ")}`);
    } else {
      this.isUploadFileValid = true;
    }

  }

  uploadBulkFileSize(type: string, filesize: number) {
    if (type.toLowerCase() !== '') {
      if (filesize > 26214400) {//25MB              5242880) {//5MB Size in Bytes.
        this.toastr.error("Please upload a file of max. size 25MB.");
        this.isFileValid = false;
      }
      else {
        this.isFileValid = true;
      }
    }
  }
  

  scrollTo(el: Element) {
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  scroll(form: FormGroup) {
    this.scrollIfFormHasErrors(form).then(() => {
    });
  }

  async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }
  Delete(tableName: any, primaryid: any, moduleName: any) {
    return this.http.get(this.baseUri + `Common/DeleteRecord?tableName=${tableName}&primaryKey=${primaryid}&moduleName=${moduleName}`)
  }
  ActiveInactive(tableName: any, primaryid: any, status: any, moduleName: any, statusName: any) {
  return this.http.get(this.baseUri + `Common/UpdateActiveInactiveStatus?tableName=${tableName}&primaryKey=${primaryid}&status=${status}&moduleName=${moduleName}&statusName=${statusName}`)
  }
  removeUserConnections() {
    return this.http.get(this.baseUri + `Account/RemoveConnections`);
  }
  ChangePassword(changePassword: ChangePasswordModel) {
    return this.http.post(this.baseUri + `Account/ChangePassword`, changePassword);
  }
  logout() {
    const message = `Are you sure you want to "sign out"?`;
    this.dialogService.confirm('Sign out', message).subscribe(confirmed => {
      if (confirmed) {
        this.removeUserConnections().subscribe((m) => { });
        //this.hubConnection.state ='Disconnected';
        localStorage.removeItem("access_token");
        localStorage.removeItem("moduleList");
        localStorage.removeItem("userinfo");
        localStorage.removeItem('connectionID');
        localStorage.removeItem("menuStage");
        
        this.LoginUser = new UserDetails();
        this.routerService.navigateByUrl("/account");
        this.toaster.success(`You are signed out successfully`);
      }
          else {
          }
    });
    

  }
  //To Export data into .pdf, .xlsx and csv
  ExportData(records: any, type: any, filename: any, pageLength: any = "") {
    if (type == 'Excel') {
     // var blob = new Blob([records], { type: 'text/plain;charset=windows-1252' });
      
      this.http.post(this.commonUri + `/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).subscribe(blob => {
       // var blob = new Blob([blob], { type: 'text/ plain; charset = windows - 1252' });
        var blob = new Blob([blob], { type: 'application/x-www-form-urlencoded' });
        saveAs(blob, filename + ".xlsx")
      })
    }
    else if (type == 'CSV') {
      var blob = new Blob([records], { type: 'text/csv' });
      this.http.post(this.commonUri + `/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).subscribe(blob => {
        saveAs(blob, filename + ".csv")

      })
    }
    else {

      var blob = new Blob([new Uint8Array(records)], { type: 'application/pdf' });
      this.http.post(this.commonUri + `/ExportExcelInvoice/${type}/${filename}/${pageLength}`, records, { responseType: 'blob' }).subscribe(blob => {
        saveAs(blob, filename + ".pdf")
      })
    }
  }
 
  getThemeType(userId: any = "") {
    //alert(this.baseUri + `Common/GetThemeType/${userId}`);
    return this.http.get(this.baseUri + `Common/GetThemeType/${userId}`);
  }

  DownloadDocument(filename: any,folderName:any) {
    let fileType = this.DownLoadFiles(filename);
    this.downLoadModel.documentName = filename;
    this.downLoadModel.folderName = folderName;
    var blob = new Blob([], { type: fileType });
    this.http.post(`${this.commonUri}/DownLoadDocument`, this.downLoadModel, { responseType: 'blob' }).subscribe(blob => {
      saveAs(blob, filename)
    });
  }
  DownLoadFiles(filename) {
    let fileName = filename;
    let checkFileType = fileName.split('.').pop();
    var fileType;
    if (checkFileType == ".txt") {
      fileType = "text/plain";
    }
    else if (checkFileType == ".pdf") {
      fileType = "application/pdf";
    }
    else if (checkFileType == ".doc") {
      fileType = "application/vnd.ms-word";
    }
    else if (checkFileType == ".docx") {
      fileType = "application/vnd.ms-word";
    }
    else if (checkFileType == ".xls") {
      fileType = "application/vnd.ms-excel";
    }
    else if (checkFileType == ".png") {
      fileType = "image/png";
    }
    else if (checkFileType == ".jpg") {
      fileType = "image/jpeg";
    }
    else if (checkFileType == ".jpeg") {
      fileType = "image/jpeg";
    }
    else if (checkFileType == ".gif") {
      fileType = "image/gif";
    }
    else if (checkFileType == ".csv") {
      fileType = "text/csv";
    }

    return fileType;
  }

  GetCurrency() {
    return "$";
  }

  getLicenseFee() {
    return 30;
  }
  GetSwitchAsUserTypes() {
    return this.http.get(this.baseUri + `UserProfile/GetSwitchAsUserTypes`);
  }
  switchUser(item:any) {
    const message = `Are you sure you want to Switch as ` + item.UserTypeName+`?`;
    this.dialogService.confirm('Switch As ' + item.UserTypeName, message).subscribe(confirmed => {
      if (confirmed) {
        console.log("switchUser item", item);
        console.log("switchUser 11", localStorage.getItem('userinfo'));
        const basicuserinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.userService.updatetoken(basicuserinfo.email, item.UserTypeID).subscribe((res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.loadSave = true;
              localStorage.setItem("access_token", res.token);
              this.getCurrentUserDetail();
              // this.commonService.GetServiceGetFileList("switch").subscribe(m => { });
              // setTimeout(() => {
              this.getLoggedInName.subscribe((userdetail: any) => {
                console.log("switchUser 12", userdetail);
                //alert(userdetail.userType);
                if (userdetail.userType == 'usertyperider') {// rider user
                  this.getUserModulePermissions().subscribe((m: any) => {
                    localStorage.removeItem('moduleList');
                    localStorage.setItem('moduleList', JSON.stringify(m));
                    window.location.href = "ridehistory";
                  });
                }
                else {
                  this.getUserModulePermissions().subscribe((m: any) => {
                    localStorage.removeItem('moduleList');
                    localStorage.setItem('moduleList', JSON.stringify(m));
                    window.location.href = "dashboard";
                  });
                }

                //this.getUserModulePermissions().subscribe((m: any) => {
                //  localStorage.removeItem('moduleList');

                //  localStorage.setItem('moduleList', m);
                //  //  this.router.navigateByUrl('/dashboard');
                //  window.location.href = "dashboard";
                //});
              });
              //}, 2000);
              //this.loadSave = true;
            }
            else if (res.status == 201) {
             // this.loadSave = false;
              this.toaster.error(res.token);
            }
          }
        }, error => {
        //  this.loadSave = false;
        });
      //  this.loadSave = false;
       // this.routerService.navigateByUrl("/dashboard");
        this.toaster.success(`You are switched as ` + item.UserTypeName);
      }
      else {
      }
    });
  } 

}

export class UserDetails {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  userType: string;
  UserType: string;
  fullName = this.firstName + " " + this.lastName;
}
export class Master {
  masterId: string;
  masterNameId: string;
  masterName: string;
  masterNames: string;
  masterKey: string;
  masterValue: string;
  masterStatusId: string;
  masterStatusName: string;
  masterIsDeleted?: boolean;
  enabled?: boolean;
  userId?: any;
  text: string;
  value: string;
  isActive: string;
  masterDescription: string;
  constructor() {
    this.masterId = '';
    this.masterNameId = null;
    this.masterName = '';
    this.masterNames = '';
    this.masterKey = '';
    this.masterValue = '';
    this.masterStatusId = null;
    this.masterStatusName = '';
    this.enabled = true;
    this.isActive = '';
    this.masterIsDeleted = false;
    this.masterDescription = '';
  }
}

export class MasterWithIncreption {
  masterId: string;
  masterNameId: string;
  masterName: string;
  masterNames: string;
  masterKey: string;
  masterValue: string;
  FirstName: string;
  LastName: string;
  masterDescription: string;
  Name: string;
  constructor() {
    this.masterId = '';
    this.masterNameId = null;
    this.masterName = '';
    this.masterNames = '';
    this.masterKey = '';
    this.masterValue = '';
    this.FirstName = "";
    this.LastName = "";
    this.Name = "";
  }
}

export class SelectItemModel {
  value: string;
  text: string;
  constructor() {
    this.value = "";
    this.text = "";
  }
}
export class SelectBusinessContactModel {
  value: string;
  text: string;
  constructor() {
    this.value = "";
    this.text = "";
  }
}

export class SelectTemplateItemModel {
  value: string;
  text: string;
  constructor() {
    this.value = "";
    this.text = "";
  }
}

export enum ModuleNames {
  Vendor = 0,
  Bank = 1,
  Location=2,
  Customer = 3,
  Subscription = 4,
  pushNotification=6
}
export class ChangePasswordModel {
  currentPassword: string;
  password: string;
  confirmPassword: string;
  constructor() {
    this.currentPassword = "";
    this.password = "";
    this.confirmPassword = "";
  }
}

export class DownloadModel {
  documentID: any;
  documentName: string;
  folderName: string;
  constructor() {
    this.documentID = null;
    this.documentName = '';
    this.folderName = '';
  }
}


export class ModuleList {
  moduleId: string;
  moduleCode: number;
  moduleName: string;
  moduleRoute: string;
  moduleUserType: string;
  roleName: string;
  roleID: string;
  roleDescription: string;
  roleModuleAddFlag: boolean;
  roleModuleUpdateFlag: boolean;
  roleModuleReadFlag: boolean;
  roleModuleDeleteFlag: boolean;
  roleModuleEmailFlag: boolean;
  roleModuleSMSFlag: boolean;
  roleModuleNotificationFlag: boolean;
}
