import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageridesComponent } from './managerides.component';
import { LoadManageridesComponent } from './load-managerides.component';
import { AddRecipeComponent } from './Rides_Recipe/add-recipe.component';
import { RideRecipeListingComponent } from './Rides_Recipe/ride-recipe-listing.component';
import { SaveRideRecipeComponent } from './Rides_Recipe/save-ride-recipe.component';
import { RideevalutionComponent } from './evalutionrides/rideevalution.component';
import { EveluationbaselineComponent } from './evalutionrides/eveluationbaseline.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackListingComponent } from './feedback-listing/feedback-listing.component';
import { ManageNotesComponent } from './managenotes.component';


const routes: Routes = [
  { path: '', component: ManageridesComponent },
  { path: 'ride-info/:id', component: LoadManageridesComponent },
  { path: 'ride-recipe/:RiderId/:RiderMasterId', component: AddRecipeComponent },
  { path: 'ride-list/:id', component: RideRecipeListingComponent },
  { path: 'ride-history/:id', component: LoadManageridesComponent },
  { path: 'save-ride/:id', component: SaveRideRecipeComponent },
  { path: 'evalution-ride/:id/:riderId', component: RideevalutionComponent },
  { path: 'evalution-baseline/:RiderId/:RiderMasterId', component: EveluationbaselineComponent },
  { path: 'feedback/:RiderId/:RiderMasterId', component: FeedbackComponent },
  { path: 'feedbacklisting/:RiderId/:RiderMasterId', component: FeedbackListingComponent },
  { path: 'ridehistory', component: LoadManageridesComponent, data: { moduleCode: '1234' } },
  { path: 'ridehistory/feedback/:RiderId/:RiderMasterId', component: FeedbackComponent },

  { path: 'biometrics', component: LoadManageridesComponent },
  { path: 'evaluations', component: LoadManageridesComponent },
  { path: 'sessionresults', component: LoadManageridesComponent },
  { path: 'corescores', component: LoadManageridesComponent },
  { path: 'rides/notes/:riderId/:userId/:riderAdminUserId', component: ManageNotesComponent },
  
];

@NgModule({ 
  imports: [RouterModule.forChild(routes)], 
  exports: [RouterModule]
})
export class ManageridesRoutingModule { }
