import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManageridesService } from '../managerides.service';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-feedback-listing',
  templateUrl: './feedback-listing.component.html',
  styleUrls: ['./feedback-listing.component.scss']
})
export class FeedbackListingComponent implements OnInit {
  dataList: any = {
    pager: {},
    data: []
  };
  RiderId: any;
  RiderMasterId: any;
  loading = false;
  sortDir = 'desc';
  sortColumn = 'CreatedOn';
 
  pagedData: any = {
    pager: {},
    data: []
  };
  listFilter = '';
  idFilter = '';
  FeedFilter = '';
  pageNumber = 0;
  pageSizeValue = 10;
  totalRecords: any;

  selected = [];

  constructor(
    private route: ActivatedRoute,
    private manageridesService: ManageridesService,
    private dialogService: ConfirmationDialogService,
    private toaster: ToastrService
  ) { }

  ngOnInit() { 
    this.route.paramMap.subscribe(
      params => {
        this.RiderId = params.get('RiderId');
        this.RiderMasterId = params.get('RiderMasterId');
      });
   this.feedbackList()
  }

  feedbackList() {
    debugger;
    this.loading = true;
    this.selected = [];
    this.manageridesService.GetFeeBackList(this.RiderMasterId,this.sortColumn, this.sortDir, this.pageNumber, this.pageSizeValue, this.FeedFilter).subscribe((data: any) => {
      this.dataList = data;

      this.loading = false;
      this.totalRecords = this.dataList.data[0].totalRecord;
      console.log("Datalist ", this.dataList);
    })
  }
  DeleteFeedBack(row: any) {
    const message = `Are you sure you want to delete FeedBack?`;
    this.dialogService.confirm('Delete FeedBack', message).subscribe(confirmed => {
      if (confirmed) {
        debugger;
        this.manageridesService.DeleteFeedBack(row.feedbackId.toString() + ',').subscribe((response: any) => {
          if (response.statusCode == 200) {
            this.toaster.success(`FeedBack has been deleted successfully`);
           // this.search();
          }
          else {
            this.toaster.error(response.responseMessage);
          }
        }, error => {

        });
      }
    });
  }
 
}
