import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonService, Master } from '../shared/common.service';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public baseUri = `${environment.WebApiBaseUrl}`;
  public rideUri = `${environment.WebApiBaseUrl}Reports`;
  public RiderModuleUri = `${environment.WebApiBaseUrl}rider`;
  pagedData: any;
  Browser: any;
  BrowserVersion: any;
  OS: string
  OSVersion: string;
  deviceInfo = null;
  masters: Master[] = [];
  constructor(private http: HttpClient, private deviceService: DeviceDetectorService, private commonService: CommonService) { }

  uploadProfilePic(profilePic: File) {
    return this.http.post(this.baseUri + `UserProfile/UploadProfilePic`, profilePic);
  }
  //function is used to add edit Customer
  addEditCustomer(customermodel12: FormData) {
    return this.http.post(this.baseUri + `Customer/AddEditCustomer`, customermodel12);
  }
  AssignToddl(masterNames: any): Observable<any> {
    
    return this.http.get(`${this.RiderModuleUri}/AssigntoDDl?masterNames=${masterNames}`).pipe(
      map((response: any) => {
        this.masters = response;

      })
    );
  }
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.Browser = this.deviceInfo.browser;
    this.BrowserVersion = this.deviceInfo.browser_version;
    this.OS = "";
    this.OSVersion = this.deviceInfo.os_version;

  }
  updatetoken(username: string, usertypeid: any) {
    this.epicFunction();
    return this.http.get(this.baseUri + `Account/UpdateToken?username=${username}&browser=${this.Browser}&browserVersion=${this.BrowserVersion}&os=${this.OS}&OSVersion=${this.OSVersion}&switchAsUserTypeId=${usertypeid}`);
  }
  AssignToOpearatotddl(masterNames: any): Observable<any> {
    
    return this.http.get(`${this.RiderModuleUri}/AssigntoDDl?masterNames=${masterNames}`).pipe(
      map((response: any) => {
        this.masters = response;

      })
    );
  }
  //function used to fetch list of Customer
  getCustomerList(name: any, active: number, customerType: any, sortColumn: string, sortDir, page: number, pageSize: number, isExport = false) {
    if (typeof name == "undefined" || name == null) { name = null; }
    else { name = encodeURIComponent((name)); }
    return this.http.get(this.baseUri + `Customer/getCustomerList?nameSearch=${name}&active=${active}&customerType=${customerType}&sortColumn=${sortColumn}&sortDir=${sortDir}&pageIndex=${page}&pageSize=${pageSize}&isExport=${isExport}`)
      .pipe(
        map((response: any) => {
          this.pagedData = response;
          console.log('csdata', this.pagedData);
          return true;
        })
      );
  }
  DeleteCustomers(selected: string) {

    return this.http.get(this.baseUri + `Customer/DeleteCustomers?cusIds=${selected}`)
  }
  //function used to set active/inactive status of Location
  setCustomerStatus(id: any, status: boolean) {
    return this.http.get(this.baseUri + `Customer/UpdateCustomerStatus?Id=${id}&Status=${status}`)
  }

  //function is used to get detail of  location
  getCustomerDetail(id: any) {
    return this.http.get(this.baseUri + `Customer/GetCustomerDetailById?Id=${id}`)
  }

  //function is used to delte the  location
  deleteCustomer(tableName: any, primaryid: any, moduleName: any) {
    return this.commonService.Delete(tableName, primaryid, moduleName);
  }
  getCusotmerNameDDl() {
    return this.http.get(this.baseUri + `Customer/GetCustomerDDL`).pipe(
      tap(data => console.log('Machine DAta by Id', data)),
    );
  } 
  
}

export class CustomerModel {
  id: string;
  ClinicName: string;
  ContactFirstName: string;
  ContactLastName: string;
  Email: string;
  Customertype: string;
 
  CustomerAdminUserId: string;
  EmpCheck1: boolean;
  isActive: boolean;
  profilePic: string;
  phoneno: string;
  profilePicLink: string;
  nooffreeride:any;
  freespinPM: Number;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  constructor() {
    this.id = "";
    this.ClinicName = "";
    this.ContactFirstName="";
    this.ContactLastName="";
    this.Email="";
    this.Customertype = "";
    this.profilePicLink = "";
   
    this.CustomerAdminUserId="";
    this.EmpCheck1 = false;
    this.isActive = false;
    this.profilePic = "";
    this.phoneno = "";
    this.freespinPM = 0,
      this.nooffreeride = 0
    this.address = "";
    this.state = null;
    this.zipCode = "";
    this.city = "";
    this.latitude = "";
    this.longitude = "";
  }
}


