// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,

  //sessionUrlStage: "http://localhost:8521/",
  //sessionUrlLive: "http://localhost:8521/",

  sessionUrlStage: "http://sessionallcore360.zorbis.com",
  sessionUrlLive: "http://session.allcore360.com",

  //sessionUrlStage: "http://202.164.57.203:8109",
  //sessionUrlLive: "http://202.164.57.203:8109",

  //-----Live server
  WebApiBaseUrl: "/api/",
  //WebApiBaseUrl: "https://app.allcore360.com/api/",
  SignalRBaseUrl: "/hub/",
  WebSiteUrl: "stageallcore360.zorbis.com",
  GoogleApiKey: "AIzaSyAJdx4gmhGKWjPTBBVk5riFgpbE0lGS-xM",
  // WebSiteUrl: "https://app.allcore360.com"


  // WebSiteUrl: "http://202.164.57.203:8110/"

  //WebApiBaseUrl: "https://app.allcore360.com/api/",
  //SignalRBaseUrl: "https://app.allcore360.com/hub/",
  //WebSiteUrl: "https://app.allcore360.com"

  //-------------Stage server  
  //WebApiBaseUrl: "http://stageallcore360.zorbis.com/api/",
  //SignalRBaseUrl: "http://stageallcore360.zorbis.com/hub/",
  //WebSiteUrl: "stageallcore360.zorbis.com",

  //--- Test Server
  //WebApiBaseUrl: "http://202.164.57.203:8110/api/",
  //SignalRBaseUrl: "http://202.164.57.203:8110/hub/",
  //WebSiteUrl: "http://202.164.57.203:8110",       

  ////-----LOCAL
  //WebApiBaseUrl: "/api/",
  //SignalRBaseUrl: "/hub/",
  //WebSiteUrl: "stageallcore360.zorbis.com" 

  //WebApiBaseUrl: "http://localhost:8522/api/",
  //SignalRBaseUrl: "http://localhost:8522/hub/",  
  //WebSiteUrl: "http://localhost:8522"

  //WebApiBaseUrl: "http://202.164.57.203:8111/api/",
  //SignalRBaseUrl: "http://202.164.57.203:8111/hub/",
  //WebSiteUrl: "202.164.57.203:8111",  

  //WebApiBaseUrl: "http://45.35.141.14/api/",
  //SignalRBaseUrl: "http://45.35.141.14/hub/",
  //WebSiteUrl: "45.35.141.14",

  //WebApiBaseUrl: "http://202.164.57.203:8109/api/",
  //SignalRBaseUrl: "http://202.164.57.203:8109/hub/"

  //  WebApiBaseUrl: "http://202.164.57.203:8111/api/",
  // SignalRBaseUrl: "http://202.164.57.203:8111/hub/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

