import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailnotificationService } from './emailnotification.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CommonService, ModuleList } from '../shared/common.service';
import { ConfirmationDialogService } from '../shared/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-emailnotification',
  templateUrl:'./emailnotificationlist.component.html'
/*  styleUrls: ['./emailnotificationlist.compone.scss']*/
})
export class EmailnotificationlistComponent {
 // lstUser: any;
  lstUser: any = {
    pager: {},
    data: []
  };
  @ViewChild('clearDrop') clearDrop: NgSelectComponent;
  @ViewChild('clearDropRider') clearDropRider: NgSelectComponent;
  @ViewChild('Description') notificationModel: ModalDirective;
  loading = false;
  sortDir = 'desc';
  sortColumn = 'CreatedOn';
  modulePermission: ModuleList;
  subject: any = '';
  pagedData: any = {
    pager: {},
    data: []
  };
  listFilter = '';
  idFilter = '';
  isEmailTo = false;
  emailFilter = '';
  isNoData = false;
  searchTxt = '';
  active: number;
  appversion = ''; frmversion = '';
  lstActiveStatus: any;
  selected = [];
  lstPageSize: any
  pageNumber = 0;
  FromEmail: any = '';
  pageSizeValue= 10;
  loginuserid: any;
  activeStatus: any = null;
  conId: any;
  rowdata: any;
  exportExcelTotalRecoards: any;
  usertype: any;
  lstRider: any;
  isdoctor: any;
  pagedDataForImport: any = {
    pager: {},
    data: []
  };
  totalRecords: any;
  NotificationDetails: any;
  constructor(private Emailservices: EmailnotificationService,
    private dialogService: ConfirmationDialogService,
    private commonService: CommonService,
      private toaster: ToastrService,
      private activeRoute: ActivatedRoute,
  ) 
  {
      const moduleCode = this.activeRoute.snapshot.data.moduleCode;
      this.modulePermission = this.commonService.getPermission(moduleCode);
  }


  ngOnInit() {
    this.pageSizeValue = 10;
    this.getPageSizes();
    this.GetEmailNotificationList();
  }
  onSort($event) {
    const sort = $event.sorts[0]
    console.log(1);
    this.sortDir = sort.dir;
    this.sortColumn = sort.prop;
    this.loading = true;
    this.pageNumber = $event.page - 1;
    this.refresh();
  }

  updateFilter(event) {
    this.searchTxt = event.target.value;
    let keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode === 13 || keycode === '13') {
      this.refresh();
    }
  }
  refresh() {
    this.GetEmailNotificationList();
    //this.loading = true;
    //this.Emailservices.GetEmailnotificationList(this.sortColumn, this.sortDir, this.pageNumber, this.pageSizeValue).subscribe((data: any) => {
    //  this.lstUser = data;
    //  this.loading = false;
    //}, error => {
    //  this.loading = false;
    //});
  }
  //onChange($event) {
  //  this.loading = true;
  //  this.Emailservices.GetEmailnotificationList(this.sortColumn, this.sortDir, this.pageNumber, this.pageSizeValue).subscribe((data: any) => {
  //    this.lstUser = data;
  //    this.loading = false;
  //  }, error => {
  //    this.loading = false;
  //  });
  //}
  GetEmailNotificationList() {
    this.loading = true;
    this.selected = [];
    this.Emailservices.GetEmailnotificationList(this.sortColumn, this.sortDir, this.pageNumber, this.pageSizeValue, this.emailFilter).subscribe((data: any) => {
      debugger
      this.lstUser = this.Emailservices.pagedData;
      this.loading = false;
      if (this.lstUser.data != null && this.lstUser.data.length>0) {
        debugger
        this.totalRecords = this.lstUser.data[0].totalRecord;
      }
     
      console.log("Datalist ", this.lstUser);
    })

  }
  reset() {
    this.emailFilter = "";
    this.selected = [];
    this.GetEmailNotificationList();
  }
  setPage($event) {
    this.pageNumber = $event.page - 1;
    this.refresh();
  }
  getPageSizes() {
    this.commonService.getMasterItemsList("PageSize").subscribe((res: any) => {
      this.lstPageSize = this.commonService.masters;
    })
  }
  displayDetail(detail,displayTo) {
    debugger
    if (displayTo != 'emailTo') {
      try {
        this.isEmailTo = false;
        this.NotificationDetails = detail;
        this.notificationModel.show();
      }
      catch (err) {
        console.log("error modal popup window", err);
      }
    }
    else {
      this.isEmailTo = true;
      this.FromEmail = '';
      this.subject = '';
      this.NotificationDetails = '';
      if (detail.parentEmailList != null) {
        //JSON.parse(detail.parentEmailList).forEach((t, index) => {
        console.log("daaEmail", detail.parentEmailList);
        this.subject = detail.parentEmailList[detail.parentEmailList.length - 1].subject;
        this.FromEmail = detail.parentEmailList[detail.parentEmailList.length - 1].commaSepratedEmail;
        this.NotificationDetails = detail.parentEmailList[detail.parentEmailList.length - 1].content;
        this.isNoData = false;
        //});


      }
      else {
        this.isNoData = true;
      }
      this.notificationModel.show();
      
    }
    
  }
  close() {
    this.notificationModel.hide();
  }
}
